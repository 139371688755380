/**
 *
 * EFormBuilder
 *
 */
import React, { useEffect, useState } from "react";
import RGL, { WidthProvider } from "react-grid-layout";
import { SideBar } from "../../../components/EFormComponents/SideBar";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import { v4 as uuid } from "uuid";
import { Wrapper } from "app/components/UI/Layout/Wrapper";
import { EFormToolbox } from "../EFormToolbox";
import {
  DynamicFormIcon,
  SettingsIcon,
  TextFormatIcon,
} from "app/components/icons";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEformSlice } from "../slice";
import { selectAuth } from "app/containers/Auth/slice/selectors";
import { EFormPublish } from "../EFormPublish";
import { EFormBuilderItem } from "app/components/EFormBuilderItem";
import { EformPublishingStatus } from "common";
import { selectEForm } from "../slice/selectors";

const ReactGridLayout = WidthProvider(RGL);
export const ITEMS = [
  {
    id: "Text",
    content: (
      <EFormBuilderItem
        icon={<TextFormatIcon className="w-6 h-6" />}
        title="Text Input"
        subtitle="Single line text input"
      />
    ),
  },
];
interface IRouteParams {
  id: string;
}

export interface IFormTemplate {
  TemplateName: string;
  TemplateDescription: string;
  Template: any;
  PublishedStatus: EformPublishingStatus;
  CreatedDate?: number;
  CreatedBy?: any;
  LastUpdated?: any;
  UpdatedBy?: any;
  id?: string;
}

const ROW_HEIGHT = 95;
const NUMBER_OF_COLS = 6;
export const compareGridElements = (a, b): number => {
  if (a.gridData.y > b.gridData.y) {
    return 1;
  }
  if (a.gridData.y < b.gridData.y) {
    return -1;
  }
  if (a.gridData.y === b.gridData.y) {
    if (a.gridData.x > b.gridData.x) {
      return 1;
    }
    if (a.gridData.x < b.gridData.x) {
      return -1;
    }
  }
  return 0;
};

export const EFormBuilder = (): JSX.Element => {
  const [formBuilderActive, setFormBuilderActive] = useState(true);
  const [open, setOpen] = useState(false);
  let dragging = false;
  const dispatch = useDispatch();
  const { actions: eformActions } = useEformSlice();
  const eformState = useSelector(selectEForm);
  const { formTemplate } = eformState;
  const authState = useSelector(selectAuth);

  const { id } = useParams<IRouteParams>();

  useEffect(() => {
    if (id) {
      dispatch(
        eformActions.getFormTemplateRequest({
          id,
          idToken: authState.session?.idToken,
        })
      );
    } else {
      dispatch(eformActions.initializeFormTemplate());
    }
  }, []);

  const handleDrop = (layout, layoutItem, event) => {
    const data = event.dataTransfer.getData("text");
    const newItem = {
      field: {
        fieldType: data,
        fieldLabel: data,
        fieldIsRequired: false,
        fieldAssistiveText: "",
        fieldDescription: "",
      },
      //grid item props documentation: https://github.com/react-grid-layout/react-grid-layout#grid-item-props
      gridData: {
        ...layoutItem,
        i: uuid(),
      },
    };
    dispatch(eformActions.addElementOnFormTemplate({ newItem }));
  };
  const handleDragStart = (event) => {
    event.dataTransfer.setData("text/plain", event.target.id);
  };

  return (
    <>
      {formBuilderActive ? (
        <div className={"max-h-content h-screen overflow-x-hidden"}>
          <Wrapper>
            <div className={"bg-white max-w-screen-lg w-3/4 mt-16"}>
              <div
                key="list"
                className="h-full min-h-full"
                data-testid="grid-layout"
              >
                <ReactGridLayout
                  className="h-full min-h-80 overflow-y-auto"
                  rowHeight={ROW_HEIGHT}
                  compactType={null}
                  cols={NUMBER_OF_COLS}
                  // DROPS
                  isDroppable={true}
                  droppingItem={{ i: "drop", w: 2, h: 1 }}
                  onDrop={handleDrop}
                  onDrag={() => {
                    dragging = true;
                  }}
                  onDragStop={(layout, oldItem, newItem) => {
                    dispatch(
                      eformActions.updateElementGridDataOnFormTemplate({
                        elementId: oldItem.i,
                        newGridData: newItem,
                        layout,
                      })
                    );
                  }}
                  onResizeStop={(layout, oldItem, newItem) => {
                    dispatch(
                      eformActions.updateElementGridDataOnFormTemplate({
                        elementId: oldItem.i,
                        newGridData: newItem,
                        layout,
                      })
                    );
                  }}
                >
                  {formTemplate?.Template?.length &&
                    formTemplate?.Template?.map(({ gridData, field }) => {
                      return (
                        <div
                          data-grid={gridData}
                          onClick={() => {
                            if (dragging) {
                              dragging = false;
                              return;
                            }
                            setOpen(true);
                            dispatch(
                              eformActions.setSelectedNode({
                                selectedId: gridData.i,
                              })
                            );
                          }}
                          key={gridData.i}
                          className="cursor-pointer flex flex-col gap-1"
                          data-testid="field-item"
                        >
                          <h2 className="pl-2 text-base text-black">
                            {field.fieldDescription}
                          </h2>
                          <div className="bg-white p-3 rounded border border-primaryLight text-secondaryText">
                            {field.fieldLabel}
                            {field.fieldIsRequired && "*"}
                          </div>
                          {field.fieldAssistiveText && (
                            <span className="pl-2 text-xs font-light text-redHighlight">
                              {field.fieldAssistiveText}
                            </span>
                          )}
                        </div>
                      );
                    })}
                </ReactGridLayout>
              </div>
            </div>
            <SideBar items={ITEMS} handleDragStart={handleDragStart} />
            <EFormToolbox open={open} setOpen={setOpen} />
          </Wrapper>
        </div>
      ) : (
        <EFormPublish />
      )}
      <div className="border-l border-primaryLight fixed top-16 bg-white shadow-xl bottom-0 right-0 w-12 flex flex-col">
        <div
          className={`w-full h-12 p-2 hover:bg-primaryLight flex justify-center items-center  ${
            !formBuilderActive ? "bg-primaryLight" : "cursor-pointer"
          }`}
          onClick={() => setFormBuilderActive(false)}
          data-testid="config-form-details"
        >
          <SettingsIcon className="w-6 h-6" />
        </div>
        <div
          data-testid="open-form-builder"
          className={`w-full h-12 p-2 hover:bg-primaryLight flex justify-center items-center  ${
            formBuilderActive ? "bg-primaryLight" : "cursor-pointer"
          }`}
          onClick={() => setFormBuilderActive(true)}
        >
          <DynamicFormIcon className="w-6 h-6" />
        </div>
      </div>
    </>
  );
};
