/**
 *
 * SlimCardsGrid
 *
 */
import React, { memo } from "react";
import SlimCard from "../UI/Layout/SlimCard";
import { Link } from "react-router-dom";
import { ReactElementLike } from "prop-types";

interface Props {
  gridData: IGridData[];
  match: any;
  headTitle: string;
  customStyle?: string;
}

const SlimCardsGrid = memo((props: Props) => {
  const gridData = props.gridData;
  const match = props.match;

  return (
    <div
      className={
        props.customStyle
          ? props.customStyle
          : `grid grid-cols-1 grid-flow-row md:grid-flow-row md:grid-cols-2 gap-4 mb-4 py-8 xl:px-0`
      }
    >
      {props.headTitle && (
        <h1 className="md:col-span-2 text-xl md:text-2xl font-semibold tracking-wider mb-4">
          {props.headTitle}
        </h1>
      )}
      {gridData.map((data) => {
        return (
          <Link key={data.linkTo} to={`${match.url + data.linkTo}`}>
            <SlimCard
              cardTitle={data.slimCardData.cardTitle}
              cardSubtitle={data.slimCardData.cardSubtitle}
              textColor={data.slimCardData.textColor}
              arrowColor={data.slimCardData.arrowColor}
            >
              {data.slimCardData.LeftIcon}
            </SlimCard>
          </Link>
        );
      })}
    </div>
  );
});
export default SlimCardsGrid;
export interface ISlimCardData {
  cardTitle: string;
  cardSubtitle: string;
  LeftIcon: ReactElementLike;
  textColor?: string;
  arrowColor?: string;
}

export interface IGridData {
  linkTo: string;
  slimCardData: ISlimCardData;
}
