export const STEP_TYPES = {
  fillForm: {
    name: "Fill Form",
    description: "",
  },
  submitForm: {
    name: "Submit Form",
    description: "Review and submit form",
  },
  makeDecision: {
    name: "Make a decision",
    description: "Finish this workflow and start another workflow",
  },
  connectToCase: {
    name: "Connect to a case",
    description: "Connect the workflow to a core record",
  },
};

export const EFORM_STATUS = {
  PUBLISHED: "published",
  DRAFTED: "drafted",
};
