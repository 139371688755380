/**
 *
 * RegisterCoreRecord
 *
 */

import React, { useEffect, useState } from "react";
import { CustomInput } from "app/components/UI/Forms/Inputs/CustomInput";
import { PhoneInput } from "app/components/UI/Forms/Inputs/PhoneInput";
import { FormFactory, IFormData } from "app/containers/FormFactory";
import { get as getIn } from "lodash";
import {
  ICreateCoreRecordData,
  IPhotoProfile,
  ICreateCoreRecordErrors,
} from "../../slice/types";
import ImageUploading, { ImageListType } from "react-images-uploading";
import { AddPhotoIcon, CloseIcon } from "app/components/icons";
import { useDispatch, useSelector } from "react-redux";
import { selectAuth } from "app/containers/Auth/slice/selectors";
import { useCoreRecordSlice } from "../../slice";
import { CustomSelect } from "app/components/UI/Forms/Inputs/CustomSelect";
import nhsValidator from "nhs-number-validator";
import {
  ETHNICITIES,
  GENDERS,
  PRONOUNS,
  PHONE_TYPES,
  ADDRESS_TYPES,
  EMAIL_TYPES,
  RELIGION,
} from "./constants";
import { Prompt, useHistory, useParams } from "react-router-dom";
import { selectCoreRecord } from "../../slice/selectors";

interface IRouteParams {
  id: string;
}

export function RegisterCoreRecord() {
  const [coreRecordDetails, setCoreRecordDetails] =
    useState<ICreateCoreRecordData>({});
  const [photoValue, setPhotoValue] = useState([] as ImageListType);
  const [hasImage, setHasImage] = useState(false);
  const dispatch = useDispatch();
  const authState = useSelector(selectAuth);
  const coreRecordState = useSelector(selectCoreRecord);
  const { actions: coreRecordActions } = useCoreRecordSlice();
  const [errors, setErrors] = useState({} as ICreateCoreRecordErrors);
  const history = useHistory();
  const { id } = useParams<IRouteParams>();
  const [isBlocking, setIsBlocking] = useState(false);
  const params = new URLSearchParams(window.location.search);
  const myWorkRedirectUrl = params.get("redirectUrl");
  const validateNHS = (nhsNumber): boolean => {
    if (nhsNumber.length === 10) {
      if (!nhsValidator.validate(nhsNumber)) {
        setErrors({ ...errors, nhsNumber: "Please insert a valid NHS Number" });
        return false;
      } else {
        setErrors({
          ...errors,
          nhsNumber: "",
        });
        return true;
      }
    } else {
      setErrors({
        ...errors,
        nhsNumber: "NHS Number should be 10 digits long",
      });
      return false;
    }
  };

  //edit user mode if id is in url
  useEffect(() => {
    if (id) {
      dispatch(
        coreRecordActions.getCoreRecordRequest({
          partitionKey: id,
          idToken: authState.session?.idToken,
        })
      );
    }
    return () => {
      dispatch(coreRecordActions.resetCoreRecordState());
    };
  }, [id]);

  useEffect(() => {
    if (coreRecordState.coreRecordDetails) {
      setCoreRecordDetails({ ...coreRecordState.coreRecordDetails });
    }
    const signedImageURL = getIn(
      coreRecordState,
      "coreRecordDetails.SignedImageData.url"
    );
    if (signedImageURL) {
      setHasImage(true);
    }
  }, [coreRecordState.coreRecordDetails]);

  const handleChanges = (propKey, value) => {
    setIsBlocking(true);
    setCoreRecordDetails((prevState) => ({ ...prevState, [propKey]: value }));
  };

  const createNewCoreRecord = async (event) => {
    event.preventDefault();
    setIsBlocking(false);
    const { target } = event;
    const photoData: IPhotoProfile = {};
    const nhsNumber = getIn(target, "nhsNumber.value", "");

    if (!validateNHS(nhsNumber)) {
      return;
    }

    if (photoValue[0] && photoValue[0].file) {
      photoData.data = getIn(photoValue[0], "dataURL", "");
      photoData.name = getIn(photoValue[0], "file.name", "");
      photoData.size = getIn(photoValue[0], "file.size", 0);
      setHasImage(true);
    }

    const preferredGender =
      coreRecordDetails?.Gender === "Others"
        ? "getIn(target, otherGender.value)"
        : coreRecordDetails?.Gender;
    const data = {
      createdDate: coreRecordDetails?.CreatedDate,
      createdById: coreRecordDetails?.CreatedById,
      pk: coreRecordDetails?.PK,
      sk: coreRecordDetails?.SK,
      title: getIn(target, "title.value", ""),
      photoUpload: photoData,
      firstName: getIn(target, "firstName.value", ""),
      familyName: getIn(target, "familyName.value", ""),
      nameSuffix: getIn(target, "nameSuffix.value", ""),
      preferredName: getIn(target, "preferredName.value", ""),
      dateOfBirth: getIn(target, "dateOfBirth.value", ""),
      sex: coreRecordDetails?.Sex || "",
      preferredGender: preferredGender || "",
      gender: coreRecordDetails?.Gender || "",
      ethnicity: coreRecordDetails?.Ethnicity || "",
      addressLine1: getIn(target, "addressLine1.value", ""),
      addressLine2: getIn(target, "addressLine2.value", ""),
      addressLine3: getIn(target, "addressLine3.value", ""),
      addressLine4: getIn(target, "addressLine4.value", ""),
      city: getIn(target, "city.value", ""),
      postCode: getIn(target, "postCode.value", ""),
      county: getIn(target, "county.value", ""),
      addressType: coreRecordDetails?.AddressType || "",
      telephoneNumber: coreRecordDetails?.PhoneNumber || "",
      email: getIn(target, "email.value", ""),
      nhsNumber,
      communityHealthIndex: getIn(target, "communityHealthIndex.value", ""),
      healthAndCareNumber: getIn(target, "healthAndCareNumber.value", ""),
      emailType: coreRecordDetails?.EmailType || "",
      telephoneNumberType: coreRecordDetails?.PhoneNumberType || "",
      religion: coreRecordDetails?.Religion || "",
      preferredPronoun: coreRecordDetails?.PreferredPronoun || "",
    };
    data.email = data?.email?.toLowerCase();
    if (id) {
      dispatch(
        coreRecordActions.updateCoreRecordRequest({
          idToken: authState.session?.idToken,
          data: { ...data, id },
          history,
        })
      );
    } else {
      dispatch(
        coreRecordActions.registerCoreRecordRequest({
          idToken: authState.session?.idToken,
          data,
          history,
          myWorkRedirectUrl,
        })
      );
    }
  };

  const getBackgroundImageURL = () => {
    let url;
    if (photoValue[0]?.dataURL) {
      url = photoValue[0]?.dataURL;
    } else if (hasImage) {
      url = coreRecordDetails.SignedImageData?.url;
    }
    return url;
  };

  const formData: IFormData = {
    headTitle: "Personal Information",
    submitButtonLabel: id ? "Save Changes" : "Add a person",
    formHandler: createNewCoreRecord,
    profilePicUpload: (
      <ImageUploading
        key="photoUpload"
        value={photoValue}
        onChange={(data) => {
          setPhotoValue(data);
          setHasImage(true);
        }}
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          isDragging,
          dragProps,
        }) => (
          <div className="flex">
            {
              <div
                className={`flex bg-cover bg-center cursor-pointer justify-center items-center  border-4 border-primary text-white p-10 rounded-full ${
                  isDragging && "bg-secondaryText "
                }`}
                {...dragProps}
                onClick={onImageUpload}
                data-testid="profile-image"
                style={{
                  backgroundImage: `url(${getBackgroundImageURL()})`,
                }}
              >
                {!hasImage ? (
                  <AddPhotoIcon className="w-8 h-8 md:w-10 md:h-10" />
                ) : (
                  <div className="w-8 h-8 md:w-10 md:h-10" />
                )}
              </div>
            }
            &nbsp;
            {(hasImage || imageList.length > 0) && (
              <button
                onClick={() => {
                  onImageRemoveAll();
                  setHasImage(false);
                }}
                className="flex justify-center items-center"
              >
                <CloseIcon className="w-8 h-8 md:w-10 md:h-10 md:mr-6" />
              </button>
            )}
          </div>
        )}
      </ImageUploading>
    ),
    formSections: [
      {
        sectionTitle: "Basic Information",
        sectionInputs: [
          <CustomInput
            key="title"
            inputId="title"
            inputName="title"
            inputLabel="Title"
            inputValue={coreRecordDetails?.Title}
            onChange={(value) => handleChanges("Title", value)}
          />,
          <CustomInput
            key="firstName"
            inputId="firstName"
            inputName="firstName"
            inputLabel="First Name"
            isRequired={true}
            inputValue={coreRecordDetails?.FirstName}
            onChange={(value) => handleChanges("FirstName", value)}
          />,
          <CustomInput
            key="familyName"
            inputId="familyName"
            inputName="familyName"
            inputLabel="Family Name"
            isRequired={true}
            inputValue={coreRecordDetails?.FamilyName}
            onChange={(value) => handleChanges("FamilyName", value)}
          />,
          <CustomInput
            key="nameSuffix"
            inputId="nameSuffix"
            inputName="nameSuffix"
            inputLabel="Name Suffix"
            inputValue={coreRecordDetails?.NameSuffix}
            onChange={(value) => handleChanges("NameSuffix", value)}
          />,
          <CustomInput
            key="preferredName"
            inputId="preferredName"
            inputName="preferredName"
            inputLabel="Preferred Name"
            inputValue={coreRecordDetails?.PreferredName}
            onChange={(value) => handleChanges("PreferredName", value)}
          />,
          <CustomInput
            key="dateOfBirth"
            inputId="dateOfBirth"
            inputName="dateOfBirth"
            inputLabel="Date of Birth"
            inputType="date"
            inputValue={coreRecordDetails?.DateOfBirth}
            onChange={(value) => handleChanges("DateOfBirth", value)}
          />,
          <CustomSelect
            key="sex"
            inputID="sex"
            name="sex"
            label="Sex"
            options={[
              { value: "M", label: "Male" },
              { value: "F", label: "Female" },
            ]}
            selectValue={coreRecordDetails?.Sex || ""}
            setSelectValue={(value) => handleChanges("Sex", value)}
          />,
          <CustomSelect
            key="preferredPronoun"
            inputID="preferredPronoun"
            name="preferredPronoun"
            label="Preferred Pronoun"
            options={PRONOUNS.map((pronoun) => ({
              value: pronoun,
              label: pronoun,
            }))}
            selectValue={coreRecordDetails?.PreferredPronoun || ""}
            setSelectValue={(value) => handleChanges("PreferredPronoun", value)}
          />,
          <CustomSelect
            key="gender"
            inputID="gender"
            name="gender"
            label="Gender"
            selectValue={coreRecordDetails?.Gender || ""}
            setSelectValue={(value) => handleChanges("Gender", value)}
            options={GENDERS.map((gender) => ({
              value: gender,
              label: gender,
            }))}
          />,

          <CustomSelect
            selectValue={coreRecordDetails?.Religion || ""}
            setSelectValue={(value) => handleChanges("Religion", value)}
            key="religion"
            inputID="religion"
            name="religion"
            label="Religion"
            options={RELIGION}
          />,

          <CustomSelect
            selectValue={coreRecordDetails?.Ethnicity || ""}
            setSelectValue={(value) => handleChanges("Ethnicity", value)}
            key="ethnicity"
            inputID="ethnicity"
            name="ethnicity"
            label="Ethnicity"
            options={ETHNICITIES.map((ethnicity) => ({
              value: ethnicity,
              label: ethnicity,
            }))}
          />,
        ],
      },
      {
        sectionTitle: "Contact Information",
        sectionInputs: [
          <CustomInput
            key="addressLine1"
            inputId="addressLine1"
            inputName="addressLine1"
            inputLabel="Address Line 1"
            inputValue={coreRecordDetails?.Address}
            onChange={(value) => handleChanges("Address", value)}
          />,
          <CustomInput
            key="addressLine2"
            inputId="addressLine2"
            inputName="addressLine2"
            inputLabel="Address Line 2"
            inputValue={coreRecordDetails?.Address2}
            onChange={(value) => handleChanges("Address2", value)}
          />,
          <CustomInput
            key="addressLine3"
            inputId="addressLine3"
            inputName="addressLine3"
            inputLabel="Address Line 3"
            inputValue={coreRecordDetails?.Address3}
            onChange={(value) => handleChanges("Address3", value)}
          />,
          <CustomInput
            key="addressLine4"
            inputId="addressLine4"
            inputName="addressLine4"
            inputLabel="Address Line 4"
            inputValue={coreRecordDetails?.Address4}
            onChange={(value) => handleChanges("Address4", value)}
          />,
          <CustomInput
            key="city"
            inputId="city"
            inputName="city"
            inputLabel="Town/City"
            inputValue={coreRecordDetails?.City}
            onChange={(value) => handleChanges("City", value)}
          />,
          <CustomInput
            key="postCode"
            inputId="postCode"
            inputName="postCode"
            inputLabel="Post Code"
            inputValue={coreRecordDetails?.PostCode}
            onChange={(value) => handleChanges("PostCode", value)}
          />,
          <CustomInput
            key="county"
            inputId="county"
            inputName="county"
            inputLabel="County"
            inputValue={coreRecordDetails?.County}
            onChange={(value) => handleChanges("County", value)}
          />,
          <CustomSelect
            key="addressType"
            inputID="addressType"
            name="addressType"
            label="Address Type"
            selectValue={coreRecordDetails?.AddressType || ""}
            setSelectValue={(value) => handleChanges("AddressType", value)}
            options={ADDRESS_TYPES.sort((address1, address2) =>
              address1.localeCompare(address2)
            ).map((address) => ({ value: address, label: address }))}
          />,
          <div key="seperator1" className="hidden md:block"></div>,
          <PhoneInput
            key="telephoneNumber"
            inputLabel="Telephone Number"
            phoneValue={coreRecordDetails?.PhoneNumber || ""}
            setPhoneValue={(value) => handleChanges("PhoneNumber", value)}
          />,
          <CustomSelect
            key="telephoneNumberType"
            inputID="telephoneNumberType"
            name="telephoneNumberType"
            label="Telephone Number Type"
            selectValue={coreRecordDetails?.PhoneNumberType || ""}
            setSelectValue={(value) => handleChanges("PhoneNumberType", value)}
            options={PHONE_TYPES.map((phoneType) => ({
              value: phoneType,
              label: phoneType,
            }))}
          />,
          <div key="seperator2" className="hidden md:block"></div>,
          <CustomInput
            key="email"
            inputId="email"
            inputName="email"
            inputLabel="Email"
            inputType="email"
            inputValue={coreRecordDetails?.Email}
            onChange={(value) => handleChanges("Email", value)}
          />,
          <CustomSelect
            key="emailType"
            inputID="emailType"
            name="emailType"
            label="Email Type"
            selectValue={coreRecordDetails?.EmailType || ""}
            setSelectValue={(value) => handleChanges("EmailType", value)}
            options={EMAIL_TYPES.map((phoneType) => ({
              value: phoneType,
              label: phoneType,
            }))}
          />,
        ],
      },
      {
        sectionTitle: "Healthcare Information",
        sectionInputs: [
          <CustomInput
            key="nhsNumber"
            inputId="nhsNumber"
            inputName="nhsNumber"
            inputLabel="NHS Number"
            inputType="number"
            inputError={errors.nhsNumber}
            isRequired={true}
            onChange={(value) => {
              validateNHS(value);
              handleChanges("Nhs", value);
            }}
            inputValue={coreRecordDetails?.Nhs}
          />,
          <CustomInput
            key="communityHealthIndex"
            inputId="communityHealthIndex"
            inputName="communityHealthIndex"
            inputLabel="Community health index (Scotland)"
            inputValue={coreRecordDetails?.CommunityHealthIndex}
            onChange={(value) => handleChanges("CommunityHealthIndex", value)}
          />,
          <CustomInput
            key="healthAndCareNumber"
            inputId="healthAndCareNumber"
            inputName="healthAndCareNumber"
            inputLabel="Health And Care Number (Ireland)"
            inputValue={coreRecordDetails?.HealthAndCareNumber}
            onChange={(value) => handleChanges("HealthAndCareNumber", value)}
          />,
        ],
      },
    ],
  };

  return (
    <>
      <Prompt when={isBlocking} message="" />
      <FormFactory {...formData} />
    </>
  );
}
