/**
 *
 * EFormDesignerPage
 *
 */
import {
  AccountTreeIcon,
  ArrowForwardIcon,
  DynamicFormIcon,
} from "app/components/icons";
import React from "react";
import { Link, useRouteMatch } from "react-router-dom";

export function EFormDesignerPage() {
  const match = useRouteMatch();

  return (
    <div className="w-full min-h-screen flex justify-center items-center">
      <div className="w-3/4 -mt-24 flex gap-4">
        <Link
          className="flex flex-col w-full bg-white min-h-96 p-8 rounded"
          to={`${match.path}/form`}
        >
          <div className="flex justify-end">
            <ArrowForwardIcon className="w-6 h-6" />
          </div>
          <DynamicFormIcon className="mt-16 w-10 h-10" />
          <h1 className="text-3xl mt-5">E-Form Designer</h1>
          <p className="text-sm text-secondaryText font-light pr-20 my-5 text-justify">
            Here you can create any type of form needed for Adult Social Care,
            set rules and timescales for completion, and enable sharing and
            reporting.
          </p>
        </Link>
        <Link
          className="flex flex-col w-full bg-white min-h-96 p-8 rounded"
          to={`${match.path}/workflow`}
        >
          <div className="flex justify-end">
            <ArrowForwardIcon className="w-6 h-6" />
          </div>
          <AccountTreeIcon className="mt-16 w-10 h-10" />
          <h1 className="text-3xl mt-5">Workflow Builder</h1>
          <p className="text-sm text-secondaryText font-light pr-20 my-5 text-justify">
            Here you can create a workflow to accomplish a process using forms
            and rules. This includes approvals, revisions, and new processes
            triggered by decisions.
          </p>
        </Link>
      </div>
    </div>
  );
}
