/* eslint-disable react/prop-types */
/**
 *
 * ManageCoreRecordsTable
 *
 */
import { selectAuth } from "app/containers/Auth/slice/selectors";
import React, { ReactElement, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AvatarCell, Table } from "../../../../../components/Table";
import { useCoreRecordSlice } from "../../../slice";
import { selectCoreRecord } from "../../../slice/selectors";
import { get as getIn } from "lodash";
import { isMobile } from "react-device-detect";
import { ArrowForwardIcon } from "../../../../../components/icons";
import { Link } from "react-router-dom";

export default function ManageCoreRecordsTable(): ReactElement {
  const dispatch = useDispatch();
  const { actions: coreRecordActions } = useCoreRecordSlice();
  const coreRecords = useSelector(selectCoreRecord);
  const records = getIn(coreRecords, "coreRecords", []);
  const authState = useSelector(selectAuth);
  let columns;
  const searchSettings = {
    showSearchField: true,
    placeHolder: "Search by name, NHS number, email address...",
  };
  useEffect(() => {
    dispatch(
      coreRecordActions.getCoreRecordsRequest({
        idToken: authState?.session?.idToken,
      })
    );
  }, []);

  if (isMobile) {
    columns = React.useMemo(
      () => [
        {
          Header: "Name",
          accessor: "Name",
          Cell: AvatarCell,
          imgAccessor: "ImageURL",
          idAccessor: "id",
        },
      ],
      []
    );
  } else {
    columns = React.useMemo(
      () => [
        {
          Header: "Name",
          accessor: "Name",
          Cell: AvatarCell,
          imgAccessor: "ImageURL",
          idAccessor: "id",
        },
        {
          Header: "Preferred Name",
          accessor: "PreferredName",
        },
        {
          Header: "Date of Birth",
          accessor: "DateOfBirth",
        },
        {
          Header: "NHS Number",
          accessor: "Nhs",
        },
        {
          Header: "Phone Number",
          accessor: "PhoneNumber",
        },
        {
          Header: "Email Address",
          accessor: "Email",
        },
        {
          Header: () => null,
          id: "arrowLink",
          Cell: ({ row }) => {
            const rowId = getIn(row, "original.id");
            return (
              <Link key={rowId} to={`manage/${rowId}`}>
                <ArrowForwardIcon className="w-6 h-6 md:w-7 md:h-7" />
              </Link>
            );
          },
        },
      ],
      []
    );
  }
  return (
    <Table columns={columns} data={records} searchSettings={searchSettings} />
  );
}
