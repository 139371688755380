import { call, put, takeLatest } from "@redux-saga/core/effects";
import { adminActions } from ".";
import { toast } from "react-toastify";
import { setLoadingState } from "app/containers/shared";

export interface IUserRegister {
  history: any;
  idToken: any;
  data: {
    email: string;
    phone_number: string;
    first_name: string;
    last_name: string;
    team: string;
    role: string;
    manager: string;
  };
}

const userUrl = process.env.REACT_APP_COGNITO_USERS_ENDPOINT;
const caseUrl = process.env.REACT_APP_COGNITO_CASE_ENDPOINT;
const createUser = process.env.REACT_APP_COGNITO_CREATE_USER_ENDPOINT;

function* registerUser({ payload }: { payload: IUserRegister }) {
  const createUserURL = `${createUser}/user/create`;
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${payload.idToken?.token}`,
    },
    body: JSON.stringify(payload.data),
  };

  yield call(setLoadingState, true);

  try {
    const { Item: data } = yield fetch(createUserURL, requestOptions)
      .then((response) => response.json())
      .catch((error) => {
        console.error("There was an error!", error);
        throw error;
      });

    yield put({
      type: adminActions.registerUserSuccess.type as any,
      payload: { data },
    });
    toast.success("Successfully added new user", {
      position: "bottom-center",
    });

    payload.history.push("/admin/users");
  } catch (error) {
    yield put({
      type: adminActions.registerUserError.type as any,
      payload: { error },
    });
  }

  yield call(setLoadingState, false);
}

function* getUsers({ payload }: { payload }) {
  const getUsersURL = `${userUrl}/users`;

  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${payload.idToken?.token}`,
    },
  };

  yield call(setLoadingState, true);

  try {
    const data = yield fetch(getUsersURL, requestOptions)
      .then((response) => response.json())
      .catch((error) => {
        console.error("There was an error!", error);
        throw error;
      });

    yield put({
      type: adminActions.getUsersSuccess.type as any,
      payload: { data },
    });
  } catch (error) {
    yield put({
      type: adminActions.getUsersError.type as any,
      payload: { error },
    });
  }

  yield call(setLoadingState, false);
}

function* getUser({ payload }: { payload }) {
  yield call(setLoadingState, true);
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${payload.idToken?.token}`,
    },
  };
  const getUserURL = (id) => `${userUrl}/user/${id}`;

  try {
    const user = yield fetch(getUserURL(payload.id), requestOptions)
      .then((response) => response.json())
      .catch((error) => {
        console.error("There was an error!", error);
        throw error;
      });
    const { Teams, ...userRest } = user;
    const mappedTeams = Teams.map((team, index) => {
      team.key = `team${index}`;
      return team;
    });
    const userWithMappedTeams = { ...userRest, Teams: mappedTeams };
    yield put({
      type: adminActions.getUserSuccess.type as any,
      payload: { user: userWithMappedTeams },
    });
    toast.success(`Successfully loaded ${user.FirstName}'s data`, {
      position: "bottom-center",
    });
  } catch (error) {
    yield put({
      type: adminActions.getUserError.type as any,
      payload: { error },
    });
  }

  yield call(setLoadingState, false);
}

function* getManagers({ payload }: { payload }) {
  const getManagersURL = `${userUrl}/users/managers`;

  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${payload.idToken?.token}`,
    },
  };

  try {
    const managerData = yield fetch(getManagersURL, requestOptions)
      .then((response) => response.json())
      .catch((error) => {
        console.error("There was an error!", error);
        throw error;
      });
    const labeledManagerData = managerData
      .map((manager) => ({
        ...manager,
        label: `${manager.FirstName} ${manager.LastName}`,
        value: manager.PK,
      }))
      .sort((manager1, manager2) =>
        manager1.label.localeCompare(manager2.label)
      );

    yield put({
      type: adminActions.getManagersSuccess.type as any,
      payload: { managerData: labeledManagerData },
    });
  } catch (error) {
    yield put({
      type: adminActions.getManagersError.type as any,
      payload: { error },
    });
  }
}

function* getTeams({ payload }: { payload }) {
  const getTeamsUrl = `${userUrl}/users/teams`;

  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${payload.idToken?.token}`,
    },
  };

  try {
    const teamsData = yield fetch(getTeamsUrl, requestOptions)
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.error("There was an error!", error);
        throw error;
      });
    //Adding label and value for the react-select2
    const labeledTeamsData = teamsData
      .sort((team1, team2) => team1.Value.localeCompare(team2.Value))
      .map((team) => ({
        ...team,
        label: team.Value,
        value: team.Value,
      }));
    yield put({
      type: adminActions.getTeamsSuccess.type as any,
      payload: { teamsData: labeledTeamsData },
    });
  } catch (error) {
    yield put({
      type: adminActions.getTeamsError.type as any,
      payload: { error },
    });
  }
}

function* getRoles({ payload }: { payload }) {
  const getRolesUrl = `${userUrl}/users/roles`;

  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${payload.idToken?.token}`,
    },
  };

  try {
    const rolesData = yield fetch(getRolesUrl, requestOptions)
      .then((response) => response.json())
      .catch((error) => {
        console.error("There was an error!", error);
        throw error;
      });
    const labeledRolesData = rolesData
      .sort((role1, role2) => role1.Value.localeCompare(role2.Value))
      .map((role) => ({
        ...role,
        label: role.Value,
        value: role.Value,
      }));
    yield put({
      type: adminActions.getRolesRequestSuccess.type as any,
      payload: { rolesData: labeledRolesData },
    });
  } catch (error) {
    yield put({
      type: adminActions.getRolesRequestError.type as any,
      payload: { error },
    });
  }
}

function* getOrganizationLocations({ payload }: { payload }) {
  const getLocationsUrl = `${userUrl}/users/organization-locations`;

  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${payload.idToken?.token}`,
    },
  };

  try {
    const locationsData = yield fetch(getLocationsUrl, requestOptions)
      .then((response) => response.json())
      .catch((error) => {
        console.error("There was an error!", error);
        throw error;
      });

    yield put({
      type: adminActions.getOrganizationLocationsRequestSuccess.type as any,
      payload: { locationsData: locationsData },
    });
  } catch (error) {
    yield put({
      type: adminActions.getLocationsRequestError.type as any,
      payload: { error },
    });
  }
}

function* updateUser({ payload }: { payload }) {
  const updateUserURL = `${createUser}/user/update`;

  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${payload.idToken.token}`,
    },
    body: JSON.stringify(payload.data),
  };

  yield call(setLoadingState, true);

  try {
    const data = yield fetch(updateUserURL, requestOptions)
      .then((response) => response.json())
      .catch((error) => {
        console.error("There was an error!", error);
        throw error;
      });

    const { body } = JSON.parse(data);

    yield put({
      type: adminActions.updateUserSuccess.type as any,
      payload: body,
    });

    if (body.FirstName) {
      toast.success(`Successfully updated ${body.FirstName} details`, {
        position: "bottom-center",
      });
      yield payload.setIsBlocking(false);
    }
  } catch (error) {
    yield put({
      type: adminActions.updateUserError.type as any,
      payload: { error },
    });
    toast.error(`Oops something went wrong!`, { position: "bottom-center" });
  }

  yield call(setLoadingState, false);
}

function* deleteUser({ payload }: { payload }) {
  const deleteUserURL = `${userUrl}/user`;

  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${payload.idToken.token}`,
    },
    body: JSON.stringify(payload.user),
  };

  yield call(setLoadingState, true);

  try {
    const data = yield fetch(deleteUserURL, requestOptions)
      .then((response) => response.json())
      .catch((error) => {
        console.error("There was an error!", error);
        throw error;
      });

    yield put({
      type: adminActions.deleteUserSuccess.type as any,
      payload: { data },
    });
    toast.success("Successfully deleted user", {
      position: "bottom-center",
    });
    payload.history.push("/admin/users");
  } catch (error) {
    yield put({
      type: adminActions.deleteUserError.type as any,
      payload: { error },
    });
  }

  yield call(setLoadingState, false);
}

function* getActiveCases({ payload }: { payload }) {
  const { idToken } = payload;
  const casesURL = `${caseUrl}/case`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${idToken.token}`,
    },
  };

  yield call(setLoadingState, true);

  try {
    const data = yield fetch(casesURL, requestOptions)
      .then((response) => response.json())
      .catch((error) => {
        console.error("There was an error!", error);
        throw error;
      });
    yield put({
      type: adminActions.getActiveCasesSuccess.type as any,
      payload: { data },
    });
  } catch (error) {
    yield put({
      type: adminActions.getActiveCasesError.type as any,
      payload: { error },
    });
  }

  yield call(setLoadingState, false);
}
function* adminAllocateCase({ payload }: { payload }) {
  const { crId, data, idToken, allocateToUser } = payload;
  yield call(setLoadingState, true);
  const sendCaseForAllocation = `${caseUrl}/coreRecord/${crId}/case/allocate`;

  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${idToken.token}`,
    },
    body: JSON.stringify({ caseId: data.id, newOwnerDetails: allocateToUser }),
  };
  try {
    const data = yield fetch(sendCaseForAllocation, requestOptions)
      .then((response) => response.json())
      .catch((error) => {
        console.error("There was an error!", error);
        throw error;
      });

    yield put({
      type: adminActions.adminAllocateCaseSuccess.type,
      payload: { data },
    });
  } catch (error) {
    yield put({
      type: adminActions.adminAllocateCaseError.type,
      payload: { error },
    });
  }

  yield call(setLoadingState, false);
}

export function* adminSaga() {
  yield takeLatest(adminActions.registerUserRequest.type as any, registerUser);
  yield takeLatest(adminActions.getUsersRequest.type as any, getUsers);
  yield takeLatest(adminActions.getUserRequest.type as any, getUser);
  yield takeLatest(adminActions.getManagersRequest.type as any, getManagers);
  yield takeLatest(adminActions.getTeamsRequest.type as any, getTeams);
  yield takeLatest(adminActions.getRolesRequest.type as any, getRoles);
  yield takeLatest(
    adminActions.getOrganizationLocationsRequest.type as any,
    getOrganizationLocations
  );
  yield takeLatest(adminActions.updateUserRequest.type as any, updateUser);
  yield takeLatest(adminActions.deleteUserRequest.type as any, deleteUser);
  yield takeLatest(
    adminActions.getActiveCasesRequest.type as any,
    getActiveCases
  );
  yield takeLatest(
    adminActions.adminAllocateCaseRequest.type as any,
    adminAllocateCase
  );
}
