/**
 *
 * WorkflowPage
 *
 */
import React from "react";
import SlimCardsGrid, { IGridData } from "app/components/SlimCardsGrid";
import { CreateNewFolder } from "app/components/icons";
import { Wrapper } from "app/components/UI/Layout/Wrapper";
import { useRouteMatch } from "react-router-dom";
import { WorkflowTable } from "../../containers/Workflow/WorkflowTable";

export const WorkflowPage = (): JSX.Element => {
  const match = useRouteMatch();
  return (
    <Wrapper>
      <SlimCardsGrid
        gridData={gridData}
        headTitle="Workflow builder"
        match={match}
      />
      <h1 className="my-4 mb-8 text-xl">
        Workflow templates created by your organization
      </h1>
      <WorkflowTable />
    </Wrapper>
  );
};

/**
 *
 * Array for generating slim cards on the grid
 *
 * @requires:
 *  @linkTo - A path to where it's heading
 *  @slimCardData - Which takes the title, subtitle and a ICON element
 *
 *
 * */
const gridData: IGridData[] = [
  {
    linkTo: "/builder",
    slimCardData: {
      cardTitle: "Build a workflow from scratch",
      cardSubtitle: "Create a workflow using the workflow builder",
      LeftIcon: <CreateNewFolder className="w-8 h-8 md:w-10 md:h-10 md:mr-6" />,
    },
  },
];
