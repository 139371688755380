/**
 *
 * EFormBuilderItem
 *
 */
import React, { memo } from "react";
import { ReactNodeLike } from "prop-types";

interface Props {
  icon: ReactNodeLike;
  title: string;
  subtitle: string;
}

export const EFormBuilderItem = memo((props: Props) => {
  return (
    <div className="flex items-center gap-2">
      <div className="p-3 bg-gray-200 rounded-md">{props.icon}</div>
      <div className="flex-1 flex flex-col">
        <p className="text-base font-semibold">{props.title}</p>
        <span className="text-sm text-secondaryText font-light">
          {props.subtitle}
        </span>
      </div>
    </div>
  );
});
