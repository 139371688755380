/**
 *
 * EFormToolbox
 *
 */
import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ArrowBackIcon } from "app/components/icons";
import { CustomButton } from "app/components/UI/Forms/Buttons/CustomButton";
import { CustomInput } from "app/components/UI/Forms/Inputs/CustomInput";
import { CustomToggle } from "app/components/UI/Forms/Inputs/CustomToggle";
import { CustomTextarea } from "app/components/UI/Forms/Inputs/CustomTextarea";
import { useDispatch, useSelector } from "react-redux";
import { selectEForm } from "../slice/selectors";
import { useEformSlice } from "../slice";

export function EFormToolbox({ open, setOpen }: any): JSX.Element {
  const dispatch = useDispatch();
  const eformState = useSelector(selectEForm);
  const { actions: eformActions } = useEformSlice();
  const { selectedField } = eformState;

  const handleChanges = (value, propKey) => {
    const newFieldData = {
      ...selectedField.field,
      [propKey]: value,
    };
    dispatch(
      eformActions.updateElementDataOnFormTemplate({
        newData: newFieldData,
        elementId: selectedField.gridData.i,
      })
    );
  };

  const handleDeleteFromForm = (deletedId) => {
    dispatch(eformActions.removeElementOnFormTemplate({ deletedId }));
    setOpen(false);
  };

  if (selectedField) {
    const { field } = selectedField;
    const {
      fieldLabel,
      fieldIsRequired,
      fieldAssistiveText,
      fieldDescription,
    } = field;
    return (
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed top-0 bottom-0 right-0 overflow-hidden"
          onClose={setOpen}
        >
          <div className="absolute top-0 pt-16 bg-white shadow-xl bottom-0 right-0 flex">
            <div className="fixed top-16 bottom-0 right-12 pl-10 flex">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <div className="w-screen max-w-sm">
                  <div className="h-full flex flex-col py-6 bg-white shadow-xl ">
                    <div className="px-4 sm:px-6">
                      <div className="flex items-center justify-between">
                        <ArrowBackIcon
                          className="w-6 h-6"
                          onClick={() => {
                            setOpen(false);
                          }}
                          data-testid="toolbox-back-button"
                        />
                        <Dialog.Title className="flex-1 text-center text-lg text-primary font-medium">
                          Text
                        </Dialog.Title>
                      </div>
                    </div>
                    <div className="flex flex-col mt-6 relative flex-1 px-4 sm:px-6 overflow-y-auto">
                      <div className="flex-1">
                        <div className="border-t py-4 px-2">
                          <p className="text-secondaryText pb-2">
                            This text will be dispayed on top of the input in
                            order to support
                          </p>
                          <CustomTextarea
                            inputId="fieldDescription"
                            inputName="fieldDescription"
                            inputLabel={"Field Description"}
                            inputValue={fieldDescription}
                            onChange={(value) => {
                              handleChanges(value, "fieldDescription");
                            }}
                          />
                        </div>
                        <div className="border-t py-4 px-2">
                          <p className="text-secondaryText pb-2">
                            This text will be displayed as placeholder and label
                            of the field
                          </p>
                          <CustomInput
                            inputId="fieldLabel"
                            inputName="fieldLabel"
                            inputLabel={"Field Label"}
                            inputValue={fieldLabel}
                            onChange={(value) => {
                              handleChanges(value, "fieldLabel");
                            }}
                          />
                        </div>
                        <div className="border-t py-4 px-2">
                          <p className="text-secondaryText pb-2">
                            Field is required
                          </p>
                          <CustomToggle
                            enabled={fieldIsRequired}
                            setEnabled={(value) =>
                              handleChanges(value, "fieldIsRequired")
                            }
                          />
                        </div>
                        <div className="border-t py-4 px-2">
                          <p className="text-secondaryText pb-2">
                            This text will be displayed as assitive text
                          </p>
                          <CustomInput
                            inputId="fieldAssistiveText"
                            inputName="fieldAssistiveText"
                            inputLabel={"Field Assistive Text"}
                            inputValue={fieldAssistiveText}
                            onChange={(value) => {
                              handleChanges(value, "fieldAssistiveText");
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="w-full px-2">
                      <CustomButton
                        isLight={true}
                        type="button"
                        label="DELETE FROM FORM"
                        handleClick={() =>
                          handleDeleteFromForm(selectedField.gridData.i)
                        }
                      />
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  } else {
    return <div></div>;
  }
}
