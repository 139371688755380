/**
 *
 * PhoneInput
 *
 */
import React from "react";
import { default as PhoneNumberInput } from "react-phone-number-input";
import "react-phone-number-input/style.css";

interface Props {
  inputLabel: string;
  phoneValue: string;
  setPhoneValue(value: any): void;
  inputId?: string;
  inputRequired?: boolean;
  testId?: string;
}

export function PhoneInput(props: Props) {
  const {
    phoneValue,
    setPhoneValue,
    inputLabel,
    inputId,
    inputRequired,
    testId,
  } = props;

  return (
    <div className="flex items-center outline relative md:h-full h-12">
      <PhoneNumberInput
        value={phoneValue}
        onChange={setPhoneValue}
        defaultCountry="GB"
        id={inputId ? inputId : "phone-input"}
        data-testid={testId}
      />
      <label
        htmlFor={inputId ? inputId : "phone-input"}
        className={`absolute pl-16 text-base text-secondaryText duration-300 ${
          phoneValue ? "has-value" : ""
        }`}
      >
        {inputLabel}
        {inputRequired && "*"}
      </label>
    </div>
  );
}
