/**
 *
 * CoreRecordsPage
 *
 */
import React from "react";
import SlimCardsGrid, { IGridData } from "app/components/SlimCardsGrid";
import { CreateNewFolder, ViewList } from "app/components/icons";
import { Wrapper } from "app/components/UI/Layout/Wrapper";
import { useRouteMatch } from "react-router-dom";

export function CoreRecordsPage() {
  const match = useRouteMatch();
  return (
    <Wrapper>
      <SlimCardsGrid
        gridData={gridData}
        headTitle="Person Records"
        match={match}
      />
    </Wrapper>
  );
}

/**
 *
 * Array for generating slim cards on the grid
 *
 * @requires:
 *  @linkTo - A path to where it's heading
 *  @slimCardData - Which takes the title, subtitle and a ICON element
 *
 *
 * */
const gridData: IGridData[] = [
  {
    linkTo: "/register",
    slimCardData: {
      cardTitle: "Registration",
      cardSubtitle: "Register a new person record",
      LeftIcon: <CreateNewFolder className="w-8 h-8 md:w-10 md:h-10 md:mr-6" />,
    },
  },
  {
    linkTo: "/manage",
    slimCardData: {
      cardTitle: "My People",
      cardSubtitle: "Access your list of assigned records here",
      LeftIcon: <ViewList className="w-8 h-8 md:w-10 md:h-10 md:mr-6" />,
    },
  },
];
