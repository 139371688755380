/**
 *
 * BreadCrumb
 *
 */
import React from "react";
import withBreadcrumbs from "react-router-breadcrumbs-hoc";
import { useSelector } from "react-redux";
import { selectAdmin } from "app/containers/Admin/slice/selectors";
import { selectCoreRecord } from "app/containers/CoreRecords/slice/selectors";
import { Link, useHistory } from "react-router-dom";
import { selectMyWork } from "app/containers/MyWork/slice/selectors";
import { isMobile } from "react-device-detect";
import { ArrowBackIcon } from "../icons";

const DynamicCoreRecordBreadcrumb = () => {
  const coreRecordState = useSelector(selectCoreRecord);
  return (
    <span className="text-secondaryText">
      {coreRecordState.coreRecordDetails?.FirstName}{" "}
      {coreRecordState.coreRecordDetails?.FamilyName}
    </span>
  );
};

const DynamicConnectionBreadcrumb = () => {
  const coreRecordState = useSelector(selectCoreRecord);
  return (
    <span className="text-secondaryText">
      {coreRecordState.connectionDetails?.FirstName}{" "}
      {coreRecordState.connectionDetails?.LastName}
    </span>
  );
};
const DynamicUserBreadcrumb = () => {
  const adminState = useSelector(selectAdmin);

  return (
    <span className="text-secondaryText">
      {adminState.userDetails?.FirstName} {adminState.userDetails?.LastName}
    </span>
  );
};
const DynamicMyWorkBreadcrumb = () => {
  const myWorkState = useSelector(selectMyWork);

  return (
    <span className="text-secondaryText">
      {myWorkState.myWorkDetails?.WorkflowTitle}
    </span>
  );
};

// define custom breadcrumbs for certain routes.
// breadcumbs can be components or strings.
const routes = [
  {
    path: "/admin",
    breadcrumb: "Admin Dashboard",
  },
  { path: "/admin/users", breadcrumb: "Manage Users" },
  { path: "/admin/register", breadcrumb: "Register Users" },
  { path: "/admin/users/:id", breadcrumb: DynamicUserBreadcrumb },
  { path: "/eform", breadcrumb: "E-Form Designer" },
  { path: "/eform/form", breadcrumb: "E-Form Dashboard" },
  { path: "/eform/form/builder", breadcrumb: "E-Form Builder" },
  { path: "/eform/form/:id", breadcrumb: "E-Form Builder" },
  { path: "/core-record", breadcrumb: "My People Dashboard" },
  { path: "/core-record/register", breadcrumb: "Register a Person" },
  { path: "/core-record/manage", breadcrumb: "Manage People" },
  {
    path: "/core-record/manage/:id",
    breadcrumb: DynamicCoreRecordBreadcrumb,
  },
  { path: "/core-record/manage/:id/about", breadcrumb: "About Me" },
  { path: "/core-record/manage/:id/case-files", breadcrumb: "Case Files" },
  { path: "/core-record/manage/:id/case-notes", breadcrumb: "Case Notes" },
  {
    path: "/core-record/manage/:id/case-notes/care-planning",
    breadcrumb: "Care Planning",
  },
  {
    path: "/core-record/manage/:id/case-notes/online-meeting",
    breadcrumb: "Online Meeting",
  },
  { path: "/core-record/manage/:id/connections", breadcrumb: "Connections" },
  {
    path: "/core-record/manage/:id/connections/professional/:conId",
    breadcrumb: DynamicConnectionBreadcrumb,
  },
  {
    path: "/core-record/manage/:id/connections/personal/:conId",
    breadcrumb: DynamicConnectionBreadcrumb,
  },
  { path: "/mywork", breadcrumb: "My Work" },
  { path: "/mywork/new", breadcrumb: "New Work" },
  { path: "/mywork/:id", breadcrumb: DynamicMyWorkBreadcrumb },
];

const BREADCRUMBS_TO_DISPLAY = 3;

const BreadCrumb = ({ breadcrumbs }: any) => {
  let breadcrumbsToDisplay;
  if (isMobile && breadcrumbs.length >= BREADCRUMBS_TO_DISPLAY) {
    const sliceFrom = breadcrumbs.length - BREADCRUMBS_TO_DISPLAY;
    breadcrumbsToDisplay = breadcrumbs.slice(sliceFrom);
  } else {
    breadcrumbsToDisplay = breadcrumbs;
  }

  const history = useHistory();

  return (
    <div className="pl-10 md:pl-0 lg:pl-0">
      {/* No render on the homepage */}
      {breadcrumbsToDisplay.length > 1 &&
        ((isMobile && (
          <button
            onClick={() => history.goBack()}
            className="flex flex-row font-bold items-center my-6 mx-0 px-0"
          >
            <ArrowBackIcon className="h-10 w-10 text-3xl" />
            BACK
          </button>
        )) || (
          <nav className="flex items-center" aria-label="Breadcrumb">
            {breadcrumbsToDisplay.map((crumb, index) => {
              const { breadcrumb } = crumb;

              return (
                <div key={index}>
                  <div className="flex items-center my-6">
                    {index !== 0 && <span className="mx-1">{">>"}</span>}
                    {index == breadcrumbsToDisplay.length - 1 ? (
                      <div className="text-secondaryText">
                        {breadcrumb.props.children
                          ? breadcrumb.props.children
                          : breadcrumb}
                      </div>
                    ) : (
                      <Link className="font-normal underline" to={crumb.key}>
                        {breadcrumb}
                      </Link>
                    )}
                  </div>
                </div>
              );
            })}
          </nav>
        ))}
    </div>
  );
};
export default withBreadcrumbs(routes)(BreadCrumb);
