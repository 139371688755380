/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import { selectGlobal } from "./containers/slice/selectors";
import { useGlobalSlice } from "./containers/slice";
import { selectAuth } from "./containers/Auth/slice/selectors";
import { useAuthSlice } from "./containers/Auth/slice";
import { useTranslation } from "react-i18next";
import { Navbar } from "./components/Navbar/Loadable";
import BreadCrumb from "./components/BreadCrumb";
import "./index.css";
import { LoadingSpinner } from "./components/UI/General/LoadingSpinner";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Routes from "./Routes";
import RouterPrompt from "app/containers/RouterPrompt";

export function App(): JSX.Element {
  const { i18n } = useTranslation();
  const { actions: globalActions } = useGlobalSlice();
  const { actions: authActions } = useAuthSlice();
  const globalState = useSelector(selectGlobal);
  const authState = useSelector(selectAuth);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(globalActions.setLoading({ isLoading: false }));
    dispatch(authActions.getSession());
  }, []);
  const [confirmOpen, setConfirmOpen] = useState(true);
  return (
    <BrowserRouter
      getUserConfirmation={(message, callback) => {
        return RouterPrompt(message, callback, confirmOpen, setConfirmOpen);
      }}
    >
      <Helmet
        titleTemplate="iESE"
        defaultTitle="iESE"
        htmlAttributes={{ lang: i18n.language }}
      >
        <meta
          name="description"
          content="A social service system that enables to manage cases."
        />
      </Helmet>
      <div className="min-w-full min-h-screen h-full justify-center bg-primaryBG">
        {authState.isLogged && <Navbar />}

        <div className="flex justify-center">
          <div className="max-w-screen-xl w-full">
            <BreadCrumb />
            <Routes />
          </div>
        </div>

        <ToastContainer
          position="bottom-center"
          autoClose={2000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
      {globalState.isLoading && (
        <div className="fixed flex justify-center items-center top-0 left-0 w-screen h-screen text-white text-2xl bg-secondaryText bg-opacity-60 z-50">
          <LoadingSpinner />
          {globalState.LoadingText}
        </div>
      )}
    </BrowserRouter>
  );
}
