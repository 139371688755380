/**
 *
 * CustomInput
 *
 */

import React from "react";

interface Props {
  inputId: string;
  inputLabel: string;
  inputName: string;
  inputType?: string;
  inputValue?: string;
  placeholder?: string;
  isRequired?: boolean;
  isDisabled?: boolean;
  inputError?: string;
  minDate?: string;
  maxLength?: number;
  onChange?(event): void;
  disableAutoComplete?: boolean;
  tabIndex?: number;
  errorComponent?: any;
}

export const CustomInput = (props: Props) => {
  return (
    <div
      className={`w-full flex flex-wrap items-center outline relative rounded-md border-2 border-gray-200 bg-white focus-within:border-2 focus-within:border-blue-500 ${
        props.isDisabled && "bg-gray-200 border-none"
      }`}
    >
      <input
        className={`w-full block pl-4 pt-4 text-base text-black border-none rounded-md focus:outline-none leading-8 pb-0 ${
          props.isDisabled && "bg-gray-200"
        }`}
        id={props.inputId}
        name={props.inputName}
        type={props.inputType ? props.inputType : "text"}
        required={props.isRequired}
        disabled={props.isDisabled}
        placeholder=" "
        onChange={(event) =>
          props.onChange ? props.onChange(event.target.value) : undefined
        }
        min={props.minDate}
        value={props.inputValue}
        maxLength={props.maxLength}
        autoComplete={props.disableAutoComplete ? "off" : "on"}
        tabIndex={props.tabIndex}
      />
      <label
        htmlFor={props.inputId}
        className="absolute pl-4 text-base text-secondaryText duration-300"
      >
        {/* Include the required mark  */}
        {props.isRequired ? `${props.inputLabel}*` : props.inputLabel}
      </label>
      {props.inputError &&
        (props.errorComponent || <span>{props.inputError}</span>)}
    </div>
  );
};
