/**
 *
 * AddProfessionalConnection
 *
 */
import React, { memo, useEffect, useRef, useState } from "react";
import { Wrapper } from "app/components/UI/Layout/Wrapper";
import { CustomInput } from "app/components/UI/Forms/Inputs/CustomInput";
import { CustomSelect } from "app/components/UI/Forms/Inputs/CustomSelect";
import { RadioGroup } from "@headlessui/react";
import { CustomRadioButton } from "app/components/UI/Forms/Inputs/CustomRadioButton";
import CustomRichText from "app/components/UI/Forms/Inputs/CustomRichText";
import { CustomButton } from "app/components/UI/Forms/Buttons/CustomButton";
import { Prompt, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectCoreRecord } from "../../slice/selectors";
import { get as getIn, isEmpty } from "lodash";
import { useCoreRecordSlice } from "../../slice";
import { selectAuth } from "app/containers/Auth/slice/selectors";
import { SPECIALITY_TYPES } from "../../CoreRecord/RegisterCoreRecord/constants";
import { CoreRecoridInfoDetails } from "app/components/CoreRecoridInfoDetails";
import { ArrowBackIcon } from "app/components/icons";
import { WarningBanner } from "app/components/UI/Banners/WarningBanner";
import { PhoneInput } from "app/components/UI/Forms/Inputs/PhoneInput";
import { useAdminSlice } from "app/containers/Admin/slice";
import { selectAdmin } from "app/containers/Admin/slice/selectors";

interface IRouteParams {
  id: string;
}

export const AddProfessionalConnection = memo(() => {
  const dispatch = useDispatch();
  const authState = useSelector(selectAuth);
  const { actions: coreRecordActions } = useCoreRecordSlice();
  const coreRecordState = useSelector(selectCoreRecord);
  const { actions: adminActions } = useAdminSlice();
  const adminState = useSelector(selectAdmin);
  const coreRecordDetails = getIn(coreRecordState, "coreRecordDetails");
  const teamsData = getIn(adminState, "teamsData");
  const rolesData = getIn(adminState, "rolesData");
  const organisationLocationData = getIn(adminState, "locationsData", []);
  const labeledOrganisationLocations = organisationLocationData.map(
    (location) => ({
      label: location.Name,
      value: location.Code,
      Town: location.Town,
      FirstAddres: location["Address Line 1"],
      SecondAddress: location["Address Line 2"],
    })
  );

  const isFirstRender = useRef(true);
  const [isBlocking, setIsBlocking] = useState(false);
  const [isKeyWorker, setIsKeyWorker] = useState("no");
  const [roleValue, setRoleValue] = useState("");
  const [specialityValue, setSpecialityValue] = useState("");
  const [teamValue, setTeamValue] = useState("");
  const [locationCodeValue, setLocationCodeValue] = useState("");
  const [organisation, setOrganization] = useState("");
  const [phoneValue, setPhoneValue] = useState("");
  const [additionalCommentValue, setAdditionalCommentValue] = useState("");

  const { id } = useParams<IRouteParams>();

  const handleCancelButton = () => {
    history.back();
  };

  const handleFormChanges = (event) => {
    event.preventDefault();
    setIsBlocking(true);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    if (!phoneValue) {
      return;
    }

    const { target } = event;
    const data = {
      FirstName: getIn(target, "firstName.value", ""),
      LastName: getIn(target, "lastName.value", ""),
      Role: roleValue,
      Speciality: specialityValue,
      Team: teamValue,
      LocationCodeValueSelect: locationCodeValue,
      LocationCodeValueText: getIn(target, "locationCodeValueInput.value", ""),
      StartDate: getIn(target, "startDate.value", ""),
      EndDate: getIn(target, "endDate.value", ""),
      PhoneNumber: phoneValue,
      Email: getIn(target, "email.value"),
      Organisation: getIn(target, "organisation.value"),
      ConnectionType: "professional",
      IsKeyWorker: isKeyWorker,
    };

    dispatch(
      coreRecordActions.addConnectionRequest({
        idToken: authState.session?.idToken,
        data,
        crId: id,
        setIsBlocking,
      })
    );
  };

  const handleSetOrganization = (org) => {
    setLocationCodeValue(org.value ? org.value : "");
    setOrganization(org);
  };

  useEffect(() => {
    if (!coreRecordDetails) {
      dispatch(
        coreRecordActions.getCoreRecordRequest({
          partitionKey: id,
          idToken: authState.session?.idToken,
        })
      );
    }
    if (!teamsData) {
      dispatch(
        adminActions.getTeamsRequest({
          idToken: authState.session?.idToken,
          loading: true,
        })
      );
    }
    if (!rolesData) {
      dispatch(
        adminActions.getRolesRequest({
          idToken: authState.session?.idToken,
          loading: true,
        })
      );
      if (isEmpty(organisationLocationData)) {
        dispatch(
          adminActions.getOrganizationLocationsRequest({
            idToken: authState.session?.idToken,
            loading: true,
          })
        );
      }
    }
  }, []);

  useEffect(() => {
    if (!isFirstRender.current) {
      setIsBlocking(true);
    } else {
      isFirstRender.current = false;
    }
  }, [
    isKeyWorker,
    roleValue,
    specialityValue,
    teamValue,
    locationCodeValue,
    phoneValue,
    additionalCommentValue,
  ]);

  const formatOptionLabel = ({ label, Town, FirstAddres, SecondAddress }) => (
    <div className="m-0 gap-0 flex-col">
      <div className="font-semibold text-base py-1 m-0 truncate">{label}</div>
      <div className="p-0 m-0 truncate text-xs text-gray-400">
        {Town} - {FirstAddres}
        {SecondAddress && ", " + SecondAddress}
      </div>
    </div>
  );

  return (
    <>
      <Prompt when={isBlocking} message="" />
      <Wrapper>
        <div className="hidden w-full lg:block">
          <CoreRecoridInfoDetails {...coreRecordDetails} />
        </div>
        <div className="flex flex-col lg:items-center w-full gap-4 mt-6 lg:flex-row">
          <div
            className="flex items-center gap-4"
            onClick={() => history.back()}
          >
            <ArrowBackIcon
              className="w-10 h-10 cursor-pointer"
              data-testid="draft-back-button"
            />
            <span className="text-xl font-semibold lg:hidden">BACK</span>
          </div>
          <h1 className=" tracking-wider w-full text-xl md:text-2xl font-semibold">
            Add a Professional Connection
          </h1>
        </div>

        <form
          className="w-full grid grid-cols-1 grid-flow-row gap-4 py-10 2xl:px-0 md:grid-cols-2 lg:grid-cols-3"
          onChange={handleFormChanges}
          onSubmit={handleFormSubmit}
        >
          <h1 className="md:col-span-full text-xl font-semibold tracking-wider py-2">
            Basic Information
          </h1>
          <CustomInput
            inputId={"firstName"}
            inputLabel={"First Name"}
            inputName={"firstName"}
            isRequired={true}
          />
          <CustomInput
            inputId={"lastName"}
            inputLabel={"Last Name"}
            inputName={"lastName"}
            isRequired={true}
          />
          <CustomSelect
            selectValue={roleValue}
            setSelectValue={setRoleValue}
            inputID={"role"}
            options={rolesData}
            name={"role"}
            label={"Role"}
          />
          <CustomSelect
            selectValue={teamValue}
            setSelectValue={setTeamValue}
            inputID={"team"}
            options={teamsData}
            name={"team"}
            label={"Team"}
          />
          <CustomSelect
            selectValue={specialityValue}
            setSelectValue={setSpecialityValue}
            inputID={"speciality"}
            options={SPECIALITY_TYPES}
            name={"speciality"}
            label={"Speciality"}
          />
          <CustomSelect
            selectValue={organisation}
            setSelectValue={handleSetOrganization}
            inputID={"organisation"}
            options={labeledOrganisationLocations}
            formatOptionLabel={formatOptionLabel}
            name={"organisation"}
            label={"Organisation"}
            customOptionClass={{
              borderBottom: "2px solid lightgray",
            }}
          />
          <h1 className="md:col-span-full text-xl font-semibold tracking-wider py-2">
            This person is the key worker for {coreRecordDetails?.FirstName}{" "}
            {coreRecordDetails?.FamilyName}
          </h1>
          <RadioGroup
            value={isKeyWorker}
            onChange={(value) => setIsKeyWorker(value)}
            className={
              "col-span-full flex p-4 pb-8 gap-10 text-xl text-black lg:col-span-1 lg:justify-between"
            }
          >
            <RadioGroup.Option value="yes">
              {({ checked, active }) => (
                <CustomRadioButton
                  checked={checked}
                  active={active}
                  title="Yes"
                />
              )}
            </RadioGroup.Option>
            <RadioGroup.Option value="no">
              {({ checked, active }) => (
                <CustomRadioButton
                  checked={checked}
                  active={active}
                  title="No"
                />
              )}
            </RadioGroup.Option>
          </RadioGroup>
          {isKeyWorker === "yes" && coreRecordDetails.KeyWorkerId && (
            <div className="col-span-full">
              <WarningBanner
                warningMessage={`${coreRecordDetails.KeyWorkerDetails.FirstName} ${coreRecordDetails.KeyWorkerDetails.LastName} is already assigned as the key worker for this case. This selection will override that decision`}
              />
            </div>
          )}
          <h1 className="md:col-span-full text-xl font-semibold tracking-wider py-2">
            Contact Details
          </h1>
          <PhoneInput
            inputLabel={"Phone Number"}
            phoneValue={phoneValue}
            setPhoneValue={setPhoneValue}
            inputRequired={true}
            testId="phone-number"
          />
          <CustomInput
            inputId={"email"}
            inputLabel={"Email"}
            inputName={"email"}
            isRequired={true}
            inputType="email"
          />
          <CustomInput
            inputId={"locationCodeValueInput"}
            inputLabel={"Location Code Value"}
            inputName={"locationCodeValueInput"}
            inputValue={locationCodeValue}
            isDisabled
          />
          <h1 className="md:col-span-full text-xl font-semibold tracking-wider py-2">
            Engagement period
          </h1>
          <CustomInput
            inputId={"startDate"}
            inputLabel={"Start date"}
            inputName={"startDate"}
            inputType="date"
          />
          <CustomInput
            inputId={"endDate"}
            inputLabel={"End date"}
            inputName={"endDate"}
            inputType="date"
          />
          <h1 className="md:col-span-full text-xl font-semibold tracking-wider py-2">
            Additional comment
          </h1>
          <div className="col-span-full">
            <CustomRichText
              value={additionalCommentValue}
              setValue={setAdditionalCommentValue}
              name={"additionalCommentValue"}
            />
          </div>
          <div className="col-span-full flex flex-col-reverse gap-4 mt-8 md:col-span-2 md:flex-row">
            <CustomButton
              type={"button"}
              label={"CANCEL"}
              isLight={true}
              handleClick={handleCancelButton}
            />
            <CustomButton type={"submit"} label={"ADD CONNECTION"} />
          </div>
        </form>
      </Wrapper>
    </>
  );
});
