/**
 *
 * InfoBanner
 *
 */
import React from "react";

interface Props {
  icon?: any;
  text?: string;
  buttonName?: string;
  match?: any;
  handleClick?(event): void;
}

export function InfoBanner(props: Props) {
  return (
    <div className="mt-5 bg-primaryLight md:p-5 rounded items-center flex">
      <div className="flex gap-5 ml-3 flex-1 items-center">
        <span>{props.icon}</span>
        <span>
          <h1 className="text-black">{props.text}</h1>
        </span>
      </div>
      <div className="flex mr-3 underline">
        <button onClick={props.handleClick}>{props.buttonName}</button>
      </div>
    </div>
  );
}
