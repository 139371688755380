/**
 *
 * CustomButton
 *
 */
import React, { memo } from "react";

interface Props {
  type: "button" | "submit" | "reset" | undefined;
  label: string;
  handleClick?(event): void;
  isLight?: boolean;
  testId?: string;
  isDisabled?: boolean;
  customClass?: string;
}

export const CustomButton = memo((props: Props) => {
  return (
    <button
      className={
        props.customClass
          ? props.customClass
          : `py-3 px-4 font-bold rounded-md w-full ${
              props.isDisabled
                ? "bg-gray-100 text-gray-500 border"
                : props.isLight
                ? "bg-white text-primary border border-primary hover:bg-primaryLight hover:border-opacity-0"
                : "bg-primary text-white hover:shadow-xl"
            }`
      }
      type={props.type}
      onClick={props.handleClick}
      data-testid={props.testId}
      disabled={props.isDisabled}
    >
      {props.label}
    </button>
  );
});
