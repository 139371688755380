/**
 *
 * EFormTable
 *
 */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectEForm } from "app/containers/EForm/slice/selectors";
import { useEformSlice } from "app/containers/EForm/slice";
import { selectAuth } from "app/containers/Auth/slice/selectors";
import { Link } from "react-router-dom";
import { ArrowForwardIcon } from "app/components/icons";
import { get as getIn } from "lodash";
import { Table } from "app/components/Table";
import dayjs from "dayjs";

export function EFormTable() {
  const { actions: eformActions } = useEformSlice();
  const authState = useSelector(selectAuth);
  const eformState = useSelector(selectEForm);
  const formTemplates = getIn(eformState, "formTemplates", []);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      eformActions.getFormTemplatesRequest({
        idToken: authState.session?.idToken,
      })
    );
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: "Title",
        accessor: "TemplateName",
        idAccessor: "id",
      },
      {
        Header: "Created Date",
        accessor: "CreatedDate",
        sortType: (a, b) =>
          new Date(b.values.CreatedDate).getTime() -
          new Date(a.values.CreatedDate).getTime(),
        Cell: ({ row }: any) => {
          const createdDate = getIn(row, "original.CreatedDate");
          return (
            <span className="text-black text-sm font-light">
              {dayjs(createdDate).format("DD.MM.YYYY HH:mm")}
            </span>
          );
        },
      },
      {
        Header: "Created By",
        Cell: ({ row }: any) => {
          const createdBy = getIn(row, "original.CreatedBy");
          return (
            <span className="text-black text-sm font-light">
              {createdBy?.FirstName} {createdBy?.LastName}
            </span>
          );
        },
      },
      {
        Header: "Status",
        accessor: "PublishedStatus",
      },

      {
        Header: () => null,
        id: "arrowLink",
        Cell: ({ row }: any) => {
          const id = getIn(row, "original.id");
          return (
            <Link key={id} to={`form/${id}`}>
              <ArrowForwardIcon className="w-6 h-6 md:w-7 md:h-7" />
            </Link>
          );
        },
      },
    ],
    []
  );

  return <Table columns={columns} data={formTemplates} />;
}
