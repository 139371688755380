export const WORKFLOW_TYPE_LABELS = {
  Referral: "Request/Referral",
  Assesment: "Assesment",
  Service: "Service",
  Review: "Review",
};

export const WORKFLOW_TYPE_OPTIONS = [
  { label: "Request/Referral", value: "Referral" },
  { label: "Assesment", value: "Assesment" },
  { label: "Service", value: "Service" },
  { label: "Review", value: "Review" },
];
