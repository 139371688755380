import { setLoadingState } from "app/containers/shared";
import { toast } from "react-toastify";
import { call, put, takeLatest } from "redux-saga/effects";
import { eformActions as actions } from ".";

const url = process.env.REACT_APP_COGNITO_USERS_ENDPOINT;

function* registerFormTemplate({ payload }: { payload }) {
  const registerFormTemplateURL = `${url}/eform`;

  const { data, idToken, currentUser } = payload;
  const formTemplate = {
    ...data,
    SK: currentUser.PK,
    CreatedBy: {
      FirstName: currentUser.FirstName,
      LastName: currentUser.LastName,
    },
  };
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${idToken.token}`,
    },
    body: JSON.stringify(formTemplate),
  };

  yield call(setLoadingState, true);

  try {
    yield fetch(registerFormTemplateURL, requestOptions)
      .then((response) => response.json())
      .catch((error) => {
        console.error("There was an error!", error);
        throw error;
      });
    yield put({ type: actions.registerFormTemplateSuccess });
    toast.success("Successfully added form template", {
      position: "bottom-center",
    });
  } catch (error) {
    yield put({ type: actions.registerFormTemplateError, payload: { error } });
  }
  history.back();
  yield call(setLoadingState, false);
}

function* updateFormTemplate({ payload }: { payload }) {
  const updateFormTemplateURL = `${url}/eform`;
  const { data, idToken, currentUser } = payload;
  const formTemplate = {
    ...data,
    UpdatedBy: {
      PK: currentUser.PK,
      FirstName: currentUser.FirstName,
      LastName: currentUser.LastName,
    },
    LastUpdated: Date.now(),
  };

  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${idToken.token}`,
    },
    body: JSON.stringify(formTemplate),
  };

  yield call(setLoadingState, true);

  try {
    yield fetch(updateFormTemplateURL, requestOptions)
      .then((response) => response.json())
      .catch((error) => {
        console.error("There was an error!", error);
        throw error;
      });
    yield put({ type: actions.updateFormTemplateSuccess });
    toast.success("Successfully updated form template", {
      position: "bottom-center",
    });
  } catch (error) {
    yield put({ type: actions.updateFormTemplateError, payload: { error } });
  }

  yield call(setLoadingState, false);
}

function* getFormTemplates({ payload }: { payload }) {
  const getFormTemplatesURL = `${url}/eform`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${payload.idToken.token}`,
    },
  };

  yield call(setLoadingState, true);

  try {
    const data = yield fetch(getFormTemplatesURL, requestOptions)
      .then((response) => response.json())
      .catch((error) => {
        console.error("There was an error!", error);
        throw error;
      });
    yield put({
      type: actions.getFormTemplatesSuccess.type,
      payload: { data },
    });
    toast.success("Successfully loaded form templates", {
      position: "bottom-center",
    });
  } catch (error) {
    yield put({
      type: actions.getFormTemplatesError.type as any,
      payload: { error },
    });
  }

  yield call(setLoadingState, false);
}

function* getPublishedFormTemplates({ payload }: { payload }) {
  const getFormTemplatesURL = `${url}/eform?published=true`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${payload.idToken.token}`,
    },
  };

  yield call(setLoadingState, true);

  try {
    const data = yield fetch(getFormTemplatesURL, requestOptions)
      .then((response) => response.json())
      .catch((error) => {
        console.error("There was an error!", error);
        throw error;
      });
    yield put({
      type: actions.getPublishedFormTemplatesSuccess.type,
      payload: { data },
    });
  } catch (error) {
    yield put({
      type: actions.getPublishedFormTemplatesError.type as any,
      payload: { error },
    });
  }

  yield call(setLoadingState, false);
}

function* getFormTemplate({ payload }: { payload }) {
  const { id, idToken } = payload;
  const getFormTemplateURL = `${url}/eform/${id}`;

  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${idToken.token}`,
    },
  };

  yield call(setLoadingState, true);

  try {
    const data = yield fetch(getFormTemplateURL, requestOptions)
      .then((response) => response.json())
      .catch((error) => {
        console.error("There was an error!", error);
        throw error;
      });
    yield put({
      type: actions.getFormTemplateSuccess.type,
      payload: { data },
    });
    toast.success("Successfully loaded form template", {
      position: "bottom-center",
    });
  } catch (error) {
    yield put({
      type: actions.getFormTemplateError.type as any,
      payload: { error },
    });
  }

  yield call(setLoadingState, false);
}

function* deleteFormTemplate({ payload }: { payload }) {
  const { id, idToken } = payload;
  const getFormTemplateURL = `${url}/eform/${id}`;

  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${idToken.token}`,
    },
  };

  yield call(setLoadingState, true);

  try {
    const data = yield fetch(getFormTemplateURL, requestOptions)
      .then((response) => response.json())
      .catch((error) => {
        console.error("There was an error!", error);
        throw error;
      });
    history.back();
    yield put({
      type: actions.deleteFormTemplateSuccess.type,
      payload: { data },
    });
    toast.success("Successfully deleted form template", {
      position: "bottom-center",
    });
  } catch (error) {
    yield put({
      type: actions.deleteFormTemplateError.type as any,
      payload: { error },
    });
  }

  yield call(setLoadingState, false);
}

export function* eformSaga() {
  yield takeLatest(
    actions.registerFormTemplateRequest.type as any,
    registerFormTemplate
  );
  yield takeLatest(
    actions.getFormTemplatesRequest.type as any,
    getFormTemplates
  );
  yield takeLatest(actions.getFormTemplateRequest.type as any, getFormTemplate);
  yield takeLatest(
    actions.updateFormTemplateRequest.type as any,
    updateFormTemplate
  );
  yield takeLatest(
    actions.deleteFormTemplateRequest.type as any,
    deleteFormTemplate
  );
  yield takeLatest(
    actions.getPublishedFormTemplatesRequest.type as any,
    getPublishedFormTemplates
  );
}
