export const EVENTOUTCOME_OPTIONS = [
  { value: 0, label: "Progress to Reablement/ST-Max" },
  { value: 1, label: "Progress to Assessment / Unplanned Review" },
  { value: 2, label: "Admitted to hospital" },
  { value: 3, label: "Progress to Re-assessment" },
  { value: 4, label: "Progress to Support Planning / Services" },
  { value: 5, label: "Progress to End of Life Care" },
  { value: 6, label: "No change in package" },
  { value: 7, label: "Service ended as planned" },
  { value: 8, label: "NFA - Moved to another LA" },
  { value: 9, label: "NFA - 100% NHS funded care" },
  { value: 10, label: "NFA - Self-funded client (inc 12wk disregard)" },
  { value: 11, label: "NFA - Support declined" },
  { value: 12, label: "NFA - Information & Advice / Signposting only" },
  { value: 13, label: "NFA - Deceased" },
  { value: 14, label: "NFA - No services offered: Other reason" },
  { value: 15, label: "NFA - Support ended: Other reason" },
];

export const MYWORK_STATUS = {
  ONGOING: "ONGOING",
  PENDING_APPROVAL: "PENDING_APPROVAL",
  APPROVED: "APPROVED",
  COMPLETED: "COMPLETED",
  REJECTED: "REJECTED",
};

export const MYWORK_STATUS_LABEL = {
  ONGOING: "Ongoing",
  PENDING_APPROVAL: "Waiting for approval",
  APPROVED: "Approved",
  COMPLETED: "Completed",
  REJECTED: "Rejected",
};
