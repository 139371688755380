/**
 *
 * UserEdit
 *
 */
import React, { useEffect, useState } from "react";

import { CustomInput } from "../../../components/UI/Forms/Inputs/CustomInput";
import { CustomButton } from "../../../components/UI/Forms/Buttons/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { useAdminSlice } from "app/containers/Admin/slice";
import { selectAuth } from "app/containers/Auth/slice/selectors";
import { selectAdmin } from "app/containers/Admin/slice/selectors";
import { useParams, useHistory, Prompt } from "react-router-dom";
import { CustomSelect } from "app/components/UI/Forms/Inputs/CustomSelect";
import { CustomModal, IModalProps } from "app/components/CustomModal";
import SlimCard from "app/components/UI/Layout/SlimCard";
import { ArrowBackIcon } from "app/components/icons";

export interface ITeamDetails {
  key: string;
  team: string;
  role: any;
  isManagerialRole?: boolean;
  manager?: any;
  accessStartDate?: string;
  accessEndDate?: string;
}

interface RouteParams {
  id: string;
}

export function UserEdit() {
  const [isBlocking, setIsBlocking] = useState(false);
  const [triggerModal, setTriggerModal] = useState(false);
  const [selectedTeamKey, setSelectedTeamKey] = useState("");
  const [modalProps, setModalProps] = useState({} as IModalProps);
  const { actions: adminActions } = useAdminSlice();
  const authState = useSelector(selectAuth);
  const adminState = useSelector(selectAdmin);
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams<RouteParams>();

  useEffect(() => {
    dispatch(
      adminActions.getUserRequest({
        id,
        users: adminState.userData,
        idToken: authState.session?.idToken,
      })
    );
    dispatch(
      adminActions.getManagersRequest({
        idToken: authState.session?.idToken,
        loading: true,
      })
    );
    dispatch(
      adminActions.getTeamsRequest({
        idToken: authState.session?.idToken,
        loading: true,
      })
    );
    dispatch(
      adminActions.getRolesRequest({
        idToken: authState.session?.idToken,
        loading: true,
      })
    );
  }, []);

  const addTeam = (team) => {
    setIsBlocking(true);
    dispatch(adminActions.addTeamToUser({ team }));
  };

  const removeTeam = (team) => {
    setIsBlocking(true);
    dispatch(adminActions.removeTeamFromUser({ team }));
    setSelectedTeamKey("");
  };

  const updateTeam = (key, value, dataKey?) => {
    setIsBlocking(true);
    dispatch(adminActions.updateTeamFromUser({ key, value, dataKey }));
  };

  const handleInputChanges = (event) => {
    setIsBlocking(true);
    if (
      event.target.name !== "accessStartDate" ||
      event.target.name !== "accessEndDate"
    )
      dispatch(
        adminActions.updateUserDetails({
          key: event.target.name,
          value: event.target.value,
        })
      );
  };

  const handleUserUpdate = () => {
    dispatch(
      adminActions.updateUserRequest({
        idToken: authState.session?.idToken,
        data: adminState.userDetails,
        setIsBlocking: setIsBlocking,
      })
    );
  };

  const updateUserConfirmationModal = (event) => {
    event.preventDefault();
    setModalProps({
      title: `Are you sure you want to make changes to user ${adminState.userDetails.FirstName} ${adminState.userDetails.LastName}`,
      message: "",
      positiveButtonText: "CONFIRM CHANGES",
      handlePositiveClick: handleUserUpdate,
    });
    setTriggerModal(true);
  };
  const handleUserDelete = (user) => {
    dispatch(
      adminActions.deleteUserRequest({
        idToken: authState.session?.idToken,
        user,
        history,
      })
    );
  };
  const deleteUserConfirmationModal = (user: any) => {
    setModalProps({
      title: `Are you sure you want to delete user ${adminState.userDetails.FirstName} ${adminState.userDetails.LastName}`,
      message: `This action is irreversible. ${adminState.userDetails.FirstName} will lose access to the system, and his record will be permanently deleted.`,
      positiveButtonText: "YES, DELETE",
      handlePositiveClick: () => handleUserDelete(user),
    });
    setTriggerModal(true);
  };

  const deleteRoleAccessConfirmationModal = (team) => {
    setModalProps({
      title: "Are you sure you want to delete role access?",
      message: `You are about to revoke role access to ${adminState.userDetails.FirstName} ${adminState.userDetails.LastName}.`,
      positiveButtonText: "YES, DELETE",
      handlePositiveClick: () => removeTeam(team),
    });
    setTriggerModal(true);
  };

  return (
    <>
      {selectedTeamKey ? (
        adminState.userDetails?.Teams?.map((team) => {
          if (team.key === selectedTeamKey)
            return (
              <div
                key={team.key}
                className="col-span-full grid-cols-1 gap-4 p-8 md:grid-cols-3  rounded grid"
                data-testid="teams-mobile-view"
              >
                <h2
                  className="flex py-6 text-2xl"
                  onClick={() => setSelectedTeamKey("")}
                  data-testid="back-button"
                >
                  <ArrowBackIcon className="w-8 h-8 md:w-10 md:h-10" /> BACK
                </h2>
                <CustomSelect
                  inputID={`${team.key}team`}
                  name="team"
                  label="Team"
                  setSelectValue={(value) =>
                    updateTeam(team.key, value, "team")
                  }
                  options={adminState.teamsData}
                  selectValue={
                    team.Team
                      ? adminState.teamsData?.filter(
                          (teamData) =>
                            teamData.Value === team.Team ||
                            teamData.Value === team.Team.value
                        )
                      : ""
                  }
                />
                <CustomSelect
                  inputID={`${team.key}role`}
                  name="role"
                  label="Role"
                  setSelectValue={(value) =>
                    updateTeam(team.key, value, "role")
                  }
                  options={adminState.rolesData}
                  selectValue={
                    team.Role
                      ? adminState.rolesData?.filter(
                          (role) =>
                            role.Value === team.Role ||
                            role.Value === team.Role.Value
                        )
                      : ""
                  }
                />
                <CustomSelect
                  inputID={`${team.key}manager`}
                  name="manager"
                  label="Manager"
                  setSelectValue={(value) =>
                    updateTeam(team.key, value, "manager")
                  }
                  options={adminState.managerData}
                  selectValue={
                    team.Manager
                      ? adminState.managerData?.filter((manager) => {
                          if (manager.PK === team.Manager.PK) {
                            return manager;
                          }
                        })
                      : ""
                  }
                />
                <CustomInput
                  inputId={`${team.key}accessStartDate`}
                  inputName="accessStartDate"
                  inputLabel="Access Start Date"
                  inputType="date"
                  onChange={(value) => updateTeam(team.key, value, "StartDate")}
                  inputValue={team.accessStartDate}
                />
                <CustomInput
                  inputId={`${team.key}accessEndDate`}
                  inputName="accessEndDate"
                  inputLabel="Access End Date"
                  inputType="date"
                  onChange={(value) => updateTeam(team.key, value, "EndDate")}
                  inputValue={team.accessEndDate}
                />
                <div></div>
                <CustomButton
                  label="SAVE CHANGES"
                  type="button"
                  handleClick={() => setSelectedTeamKey("")}
                />
                <CustomButton
                  label="DELETE ROLE ACCESS"
                  type="button"
                  handleClick={() => deleteRoleAccessConfirmationModal(team)}
                  isLight={true}
                />
              </div>
            );
        })
      ) : (
        <>
          <Prompt when={isBlocking} message="" />
          <form
            className="w-full grid grid-cols-1 grid-flow-row lg:gap-4 py-10 px-10 xl:px-0 "
            onSubmit={updateUserConfirmationModal}
            onChange={handleInputChanges}
          >
            <div className="grid grid-cols-1 gap-4 md:grid-cols-3 ">
              <h1 className="md:col-span-full text-3xl tracking-widest">
                {adminState.userDetails?.FirstName}{" "}
                {adminState.userDetails?.LastName}
              </h1>
              <h2 className="col-span-full text-xl">Personal Information </h2>

              <CustomInput
                inputId="FirstName"
                inputName="FirstName"
                inputLabel="First Name"
                inputType="text"
                isRequired={true}
                inputValue={adminState.userDetails?.FirstName}
              />
              <CustomInput
                inputId="LastName"
                inputName="LastName"
                inputLabel="Last Name"
                inputType="text"
                isRequired={true}
                inputValue={adminState.userDetails?.LastName}
              />
              <CustomInput
                inputId="EmailAddress"
                inputName="EmailAddress"
                inputLabel="Email"
                inputType="email"
                isRequired={true}
                inputValue={adminState.userDetails?.EmailAddress}
              />
              <CustomInput
                inputId="PhoneNumber"
                inputName="PhoneNumber"
                inputLabel="Phone Number"
                inputType="tel"
                isRequired={true}
                inputValue={adminState.userDetails?.PhoneNumber}
              />
            </div>

            <div className="grid grid-cols-1 gap-4 md:grid-cols-3 ">
              <h2 className="col-span-full text-xl">Employment Information </h2>
              <CustomInput
                inputId="EmployeeIdentificationNumber"
                inputName="EmployeeIdentificationNumber"
                inputLabel="Employee ID"
                inputType="text"
                isRequired={true}
                inputValue={
                  adminState.userDetails?.EmployeeIdentificationNumber
                }
              />
            </div>
            <div className="grid grid-cols-1 lg:gap-4 md:grid-cols-3 ">
              <h2 className="col-span-2 text-xl">Team Information </h2>
              {adminState?.userDetails?.Teams?.length > 0 ? (
                <>
                  <h2
                    className="text-xl cursor-pointer text-center hidden lg:block lg:text-right"
                    onClick={() =>
                      addTeam({
                        key: `team${adminState.userDetails.Teams.length}`,
                      } as ITeamDetails)
                    }
                  >
                    +<span className="underline">ADD ROLE ACCESS</span>{" "}
                  </h2>
                  {adminState?.userDetails?.Teams?.map((team) => {
                    return (
                      <>
                        <div
                          key={team.key}
                          className="col-span-full grid-cols-1 gap-4 p-8 md:grid-cols-3 bg-white rounded hidden lg:grid"
                        >
                          <CustomSelect
                            inputID={`${team.key}team`}
                            name="team"
                            label="Team"
                            setSelectValue={(value) =>
                              updateTeam(team.key, value, "team")
                            }
                            options={adminState.teamsData}
                            selectValue={
                              team.Team
                                ? adminState.teamsData?.filter(
                                    (teamData) =>
                                      teamData.Value === team.Team ||
                                      teamData.Value === team.Team.value
                                  )
                                : ""
                            }
                          />
                          <CustomSelect
                            inputID={`${team.key}role`}
                            name="role"
                            label="Role"
                            setSelectValue={(value) =>
                              updateTeam(team.key, value, "role")
                            }
                            options={adminState.rolesData}
                            selectValue={
                              team.Role
                                ? adminState.rolesData?.filter((role) => {
                                    if (role.Value === team.Role) {
                                      return role;
                                    } else if (role.Value === team.Role.Value) {
                                      return role;
                                    }
                                  })
                                : ""
                            }
                          />
                          <CustomSelect
                            inputID={`${team.key}manager`}
                            name="manager"
                            label="Manager"
                            setSelectValue={(value) =>
                              updateTeam(team.key, value, "manager")
                            }
                            options={adminState.managerData}
                            selectValue={
                              team.Manager
                                ? adminState.managerData?.filter((manager) => {
                                    if (manager.PK === team.Manager.PK) {
                                      return manager;
                                    }
                                  })
                                : ""
                            }
                          />
                          <CustomInput
                            inputId={`${team.key}accessStartDate`}
                            inputName="accessStartDate"
                            inputLabel="Access Start Date"
                            inputType="date"
                            onChange={(value) =>
                              updateTeam(team.key, value, "StartDate")
                            }
                            inputValue={team.StartDate}
                          />
                          <CustomInput
                            inputId={`${team.key}accessEndDate`}
                            inputName="accessEndDate"
                            inputLabel="Access End Date"
                            inputType="date"
                            onChange={(value) =>
                              updateTeam(team.key, value, "EndDate")
                            }
                            inputValue={team.EndDate}
                          />
                          <div></div>
                          <h2
                            className="text-xl cursor-pointer mt-4"
                            onClick={() =>
                              deleteRoleAccessConfirmationModal(team)
                            }
                          >
                            <span className="underline">
                              Delete Role Access
                            </span>{" "}
                          </h2>
                        </div>

                        <div
                          key={`mobile${team.key}`}
                          className="col-span-full grid-cols-1 py-4 md:grid-cols-3 grid lg:hidden"
                          onClick={() => {
                            setSelectedTeamKey(team.key);
                          }}
                        >
                          <SlimCard
                            cardTitle={team.Team?.value || team.Team}
                            cardSubtitle={`Access expiry date: ${team?.EndDate}`}
                          />
                        </div>
                      </>
                    );
                  })}
                  <div className="col-span-full flex lg:hidden justify-center items-center my-6 rounded">
                    <h2
                      className="text-xl cursor-pointer"
                      onClick={() => {
                        addTeam({
                          key: `team${adminState.userDetails.Teams.length}`,
                        } as ITeamDetails);
                        setSelectedTeamKey(
                          `team${adminState.userDetails.Teams.length}`
                        );
                      }}
                    >
                      +<span className="underline">ADD ROLE ACCESS</span>
                    </h2>
                  </div>
                </>
              ) : (
                <>
                  <div />
                  <div className="col-span-full hidden lg:flex justify-center items-center py-24  bg-white rounded">
                    <h2
                      className="text-xl cursor-pointer"
                      onClick={() =>
                        addTeam({
                          key: `team${adminState.userDetails.Teams.length}`,
                        } as ITeamDetails)
                      }
                    >
                      +<span className="underline">ADD ROLE ACCESS</span>
                    </h2>
                  </div>
                  <div className="col-span-full flex lg:hidden justify-center items-center py-10  bg-white rounded">
                    <h2
                      className="text-xl cursor-pointer"
                      onClick={() => {
                        addTeam({
                          key: `team${adminState.userDetails.Teams.length}`,
                        } as ITeamDetails);
                        setSelectedTeamKey(
                          `team${adminState.userDetails.Teams.length}`
                        );
                      }}
                    >
                      +<span className="underline">ADD ROLE ACCESS</span>
                    </h2>
                  </div>
                </>
              )}
            </div>

            <div className="grid grid-cols-1 gap-4 mt-3 md:grid-cols-3">
              <CustomButton
                type="submit"
                label="SAVE CHANGES"
                testId="save-user-changes"
              />
            </div>
            {authState.cognitoGroups.includes("Administrator") && (
              <div className="grid grid-cols-1 gap-4 mt-3 md:grid-cols-3">
                <CustomButton
                  type="button"
                  label="DELETE USER"
                  isLight={true}
                  handleClick={() => {
                    deleteUserConfirmationModal({
                      id: adminState.userDetails?.id,
                    });
                  }}
                  testId="delete-user-button"
                />
              </div>
            )}
          </form>
        </>
      )}
      <CustomModal
        open={triggerModal}
        setOpen={setTriggerModal}
        {...modalProps}
      />
    </>
  );
}
