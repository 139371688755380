/**
 *
 * MobileRowCard
 *
 */

import React from "react";
import { ArrowForwardIcon } from "app/components/icons";
import { Link } from "react-router-dom";
import { ReactElementLike } from "prop-types";

interface IParams {
  title: string | any;
  linkTo: string;
  leftIcon?: ReactElementLike;
  children?;
}

function MobileRowCard(props: IParams) {
  return (
    <Link to={props.linkTo} className="flex flex-col p-2 gap-6">
      <div className="flex justify-between">
        <p className="flex gap-2 items-center font-light text-sm tracking-wide text-black">
          {props.leftIcon && props.leftIcon}
          <div
            className="text-sm text-black"
            style={{
              width: "20ch",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {props.title}
          </div>
        </p>
        <ArrowForwardIcon className="w-6 h-6" />
      </div>
      {props.children && props.children}
    </Link>
  );
}

export default MobileRowCard;
