/**
 *
 * CustomModal
 *
 */
import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useRef } from "react";
import { CustomButton } from "../UI/Forms/Buttons/CustomButton";

interface Props {
  title: string;
  open: boolean;
  setOpen: any;
  message?: string;
  handlePositiveClick?: any;
  positiveButtonText?: string;
  handleCancelClick?: any;
  cancelButtonText?: string;
  handleCrossClick?: any;
}
export interface IModalProps {
  title: string;
  message?: string;
  handlePositiveClick?: any;
  positiveButtonText?: string;
  handleCancelClick?: any;
  cancelButtonText?: string;
  handleCrossClick?: any;
}

export function CustomModal(props: Props): JSX.Element {
  const {
    open,
    setOpen,
    handlePositiveClick,
    handleCancelClick,
    handleCrossClick,
  } = props;

  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
        data-testid="modal"
      >
        <div className="flex items-center justify-center min-h-screen sm:pt-4 sm:px-4 text-center sm:bg-none sm:items-end sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="flex flex-col justify-between items-center sm:inline-block align-bottom bg-white rounded w-full h-screen p-4 text-left overflow-hidden shadow-xl transform transition-all sm:h-full sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-10">
              <div
                className="flex w-full justify-end items-end text-right sm:hidden"
                onClick={() => {
                  setOpen(false);
                  if (handleCrossClick) {
                    handleCrossClick();
                  }
                }}
                data-testid="cross-button"
              >
                <i className="material-icons-round text-3xl">close</i>
              </div>
              <div className="w-full flex items-end">
                <div className="mt-3 text-left sm:mt-5">
                  <Dialog.Title
                    as="h3"
                    className="text-2xl leading-6 font-medium text-primary"
                  >
                    {props.title}
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-md text-black">{props.message}</p>
                  </div>
                </div>
              </div>
              <div className="flex flex-col-reverse w-full mt-5 sm:flex-row">
                <CustomButton
                  type="button"
                  label={
                    props.cancelButtonText ? props.cancelButtonText : "Cancel"
                  }
                  handleClick={() => {
                    setOpen(false);
                    if (handleCancelClick) {
                      handleCancelClick();
                    }
                  }}
                  isLight={true}
                  testId="modal-cancel-button"
                />
                <div className="py-2 px-2" />
                <CustomButton
                  type="button"
                  label={
                    props.positiveButtonText ? props.positiveButtonText : "OK"
                  }
                  handleClick={() => {
                    setOpen(false);
                    if (handlePositiveClick) {
                      handlePositiveClick();
                    }
                  }}
                  testId="modal-confirm-button"
                />
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
