export const RAG_COLORS = [
  {
    class: "bg-redHighlight rounded-l",
    name: "Red",
    title:
      "This event or situation is high risk and requires immediate action. ",
  },
  {
    class: "bg-yellowHighlight",
    name: "Yellow",
    title:
      "The care user's event or situation is deteriorating but no urgent intervention or action is required. ",
  },
  {
    class: "bg-greenHighlight",
    name: "Green",
    title:
      "The care user's event or situation is low risk but support is required, and if not provided may lead to poor outcomes or an impact on the care user's wellbeing. ",
  },
  {
    class: "bg-primaryLight",
    name: "Gray",
    title:
      "A piece of work that pertains to social care processes or statutory requirements but doesn't describe an event or situation in the care user's life. ",
  },
  {
    class: "bg-black rounded-r",
    name: "Black",
    title:
      "A significant event or situation in the care user's life that has safeguarding implications for other people. ",
  },
];
export const RAG_COLORS_MAP = {
  Red: "bg-redHighlight",
  Yellow: "bg-yellowHighlight",
  Green: "bg-greenHighlight",
  Gray: "bg-primaryLight",
  Black: "bg-black",
};
