/**
 *
 * EditGeneralNote
 *
 */
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { selectAuth } from "app/containers/Auth/slice/selectors";
import { useSelector, useDispatch } from "react-redux";
import { Prompt, useHistory, useParams } from "react-router-dom";
import { useCoreRecordSlice } from "../../slice";
import { selectCoreRecord } from "../../slice/selectors";
import { ArrowBackIcon } from "app/components/icons";
import { CoreRecoridInfoDetails } from "app/components/CoreRecoridInfoDetails/Loadable";
import { CustomButton } from "app/components/UI/Forms/Buttons/CustomButton/Loadable";
import { CustomInput } from "app/components/UI/Forms/Inputs/CustomInput/Loadable";
import { CustomRichText } from "app/components/UI/Forms/Inputs/CustomRichText/Loadable";
import { RagRating } from "../../../../components/RAGRating";
import { get as getIn } from "lodash";
import dayjs from "dayjs";
import { CustomFileUploadInput } from "app/components/CustomFileUploadInput/Loadable";
import { UploadedFileComponentWithDelete } from "app/components/UploadedFileComponentWithDelete/Loadable";
import { CASE_NOTES_TYPES_MAP } from "../constants";
import { IRouteParams } from "../types";

export function EditGeneralCaseNote(): JSX.Element {
  const { actions: coreRecordActions } = useCoreRecordSlice();
  const coreRecordState = useSelector(selectCoreRecord);
  const authState = useSelector(selectAuth);
  const dispatch = useDispatch();
  const currentUser = getIn(authState, "currentUser", {});
  const { canGoBack } = coreRecordState;
  const { id, caseNoteId } = useParams<IRouteParams>();
  const isFirstRender = useRef(true);
  const [files, setFiles] = useState([] as any);
  const [isBlocking, setIsBlocking] = useState(false);
  const [headline, setHeadLine] = useState("");
  const [filesToUpload, setFilesToUpload] = useState([] as any);
  const [ragRating, setRagRating] = useState("");
  const [regardingFiles, setRegardingFiles] = useState("");
  const [noteText, setNoteText] = useState("");
  const { coreRecordDetails } = coreRecordState;
  const editorName = `${currentUser.FirstName} ${currentUser.LastName}`;
  const { handleSubmit } = useForm();
  const history = useHistory();
  const caseNoteDetails = getIn(coreRecordState, "caseNoteDetails", {});

  useEffect(() => {
    dispatch(
      coreRecordActions.getCoreRecordRequest({
        partitionKey: id,
        idToken: authState.session?.idToken,
      })
    );
    dispatch(
      coreRecordActions.getSingleCaseFileRequest({
        crId: id,
        caseFileId: caseNoteId,
        idToken: authState.session?.idToken,
      })
    );
    return () => {
      dispatch(coreRecordActions.resetCaseNoteState());
    };
  }, []);
  useEffect(() => {
    if (!isFirstRender.current) {
      setFiles(caseNoteDetails.files);
      setHeadLine(caseNoteDetails.HeadLine);
      setRegardingFiles(caseNoteDetails.RegardingFiles);
      setNoteText(caseNoteDetails.NoteText || "");
      setRagRating(caseNoteDetails.RagRating);
    } else {
      isFirstRender.current = false;
    }
  }, [caseNoteDetails]);

  const colorRaitingSelect = (event) => {
    setRagRating(event.name);
  };
  const submitHandler = () => {
    const caseNote = {
      HeadLine: headline,
      RegardingFiles: regardingFiles,
      NoteText: noteText,
      RagRating: ragRating,
      EditorName: editorName,
      PK: caseNoteDetails.PK,
      SK: caseNoteDetails.SK,
      files: files || undefined,
    };
    dispatch(
      coreRecordActions.updateCaseNoteRequest({
        idToken: authState.session?.idToken,
        data: caseNote,
        filesToUpload,
        files,
      })
    );
    setIsBlocking(false);
  };
  const handleCancelClick = () => {
    history.goBack();
  };
  const handleDeleteClick = (index, isNewFile = true) => {
    !isNewFile
      ? setFiles((prevArr) =>
          prevArr?.filter((file, removeIndex) => index !== removeIndex && file)
        )
      : setFilesToUpload((prevArr) =>
          prevArr?.filter((file, removeIndex) => index !== removeIndex && file)
        );
  };

  useEffect(() => {
    if (canGoBack) {
      history.goBack();
    }
    return () => {
      dispatch(coreRecordActions.setCanGoBack({ goBack: false }));
    };
  }, [canGoBack]);

  return (
    <>
      <div className="hidden w-full lg:block">
        <CoreRecoridInfoDetails {...coreRecordDetails} />
      </div>
      <div
        className={`flex flex-col items-start w-full lg:w-3/4 mt-12 px-10 xl:px-0`}
      >
        <div className="flex flex-col mb-4 w-full gap-4 mt-6 md:mb-10 lg:flex-row lg:items-center">
          <div className="flex items-center gap-4" onClick={handleCancelClick}>
            <ArrowBackIcon
              className="w-10 h-10 cursor-pointer"
              data-testid="draft-back-button"
            />
            <span className="text-xl font-semibold lg:hidden">BACK</span>
          </div>
          <h1 className=" tracking-wider w-full text-xl md:text-2xl font-semibold">
            {caseNoteDetails.HeadLine}
          </h1>
        </div>
        <div className="mb-6 text-sm">
          <div className={"inline-flex gap-5 flex-1"}>
            <div>
              <span className="text-secondaryText">Note type:</span>{" "}
              <span>
                {CASE_NOTES_TYPES_MAP[caseNoteDetails.DocumentType]?.title}
              </span>
            </div>
            <div>
              <span className="text-secondaryText">Created Date:</span>{" "}
              <span>
                {dayjs(caseNoteDetails.CreatedDate).format("DD.MM.YYYY HH:MM")}
              </span>
            </div>
            <div>
              <span className="text-secondaryText">Created By:</span>{" "}
              <span>{caseNoteDetails.CreatorName}</span>
            </div>
          </div>
          {caseNoteDetails.EditorName && (
            <div className="flex gap-5 flex-1 mt-2">
              <div>
                <span className="text-secondaryText">Updated:</span>{" "}
                <span>
                  {dayjs(caseNoteDetails.LastUpdated).format(
                    "DD.MM.YYYY HH:MM"
                  )}
                </span>
              </div>
              <div>
                <span className="text-secondaryText">Updated By:</span>{" "}
                <span>{caseNoteDetails.EditorName}</span>
              </div>
            </div>
          )}
        </div>
        <form
          className={`w-full grid grid-cols-1 grid-flow-row gap-4 mb-10 mr-10 2xl:mx-0 `}
          onSubmit={handleSubmit(submitHandler)}
          onChange={() => setIsBlocking(true)}
          data-testid="form"
        >
          <div className="grid grid-cols-1 gap-4 md:grid-cols-1 lg:grid-cols-1">
            <span>
              <CustomInput
                inputId="headline"
                inputName="headline"
                inputLabel="Headline"
                inputType="text"
                isRequired={false}
                onChange={(value) => setHeadLine(value)}
                inputValue={headline}
                maxLength={200}
              />
              <span className="text-sm text-secondaryText">
                Give the note a quick and meaningful description of the purpose
                or main content of what you want to record (200 characters)
              </span>
            </span>
          </div>
          <div className={" grid grid-cols-1 gap-4 md:grid-cols-2"}>
            <CustomInput
              inputId="regardingFiles"
              inputName="regardingFiles"
              inputLabel="Regarding Files"
              inputType="text"
              isRequired={false}
              onChange={(value) => setRegardingFiles(value)}
              inputValue={regardingFiles}
            />

            <CustomFileUploadInput
              filesToUpload={filesToUpload}
              setFiles={(file) =>
                setFilesToUpload((prevArr) => [...prevArr, file])
              }
              inputName="fileUploader"
              isMultiple={true}
            />
          </div>
          <div
            className={" grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3"}
            style={{
              gridTemplateColumns: "repeat(3, minmax(0, auto))",
            }}
          >
            {filesToUpload &&
              filesToUpload.map((file, index) => {
                return (
                  <UploadedFileComponentWithDelete
                    key={`${file.name}-${index}`}
                    file={file}
                    handleDeleteIconClick={() => handleDeleteClick(index)}
                  />
                );
              })}
            {files &&
              files.map((file, index) => {
                return (
                  <UploadedFileComponentWithDelete
                    key={`${file.name}-${index}`}
                    file={file}
                    handleDeleteIconClick={() =>
                      handleDeleteClick(index, false)
                    }
                  />
                );
              })}
          </div>
          <div className="grid grid-cols-1 gap-4 md:grid-cols-1 lg:grid-cols-1">
            <CustomRichText
              value={noteText}
              setValue={setNoteText}
              name={"noteText"}
              testId={`noteText-rich-text`}
            />
          </div>
          <div className={`w-full flex flex-col`}>
            <span>Significance of this event in life of the person:</span>
            <RagRating
              handleClick={colorRaitingSelect}
              preselected={caseNoteDetails?.RagRating}
            />
          </div>
          <div
            className={
              "flex gap-4 flex-col-reverse md:flex-row md:items-center md:w-2/3"
            }
          >
            <CustomButton
              type="button"
              label={"CANCEL"}
              handleClick={handleCancelClick}
              isLight={true}
              testId="modal-cancel-button"
            />
            <CustomButton type="submit" label={"SAVE NOTE"} />
          </div>
        </form>
      </div>
      <Prompt when={isBlocking} message={""} />
    </>
  );
}
