/**
 *
 * CaseFiles
 *
 */
import React, { useEffect, useRef, useState } from "react";
import { Wrapper } from "app/components/UI/Layout/Wrapper";
import { useDispatch, useSelector } from "react-redux";
import { selectCoreRecord } from "../slice/selectors";
import { CustomModal, IModalProps } from "../../../components/CustomModal";
import { CustomButton } from "../../../components/UI/Forms/Buttons/CustomButton";
import { get as getIn } from "lodash";
import { useCoreRecordSlice } from "../slice";
import { selectAuth } from "../../Auth/slice/selectors";
import { Link, useHistory, useParams } from "react-router-dom";
import { ArrowBackIcon, ArrowForwardIcon } from "../../../components/icons";
import { isMobile } from "react-device-detect";
import { CoreRecoridInfoDetails } from "../../../components/CoreRecoridInfoDetails/Loadable";
import { Table } from "../../../components/Table/Loadable";
import { RAG_COLORS_MAP } from "../../../components/RAGRating/constants";
import {
  CASE_FILE_TYPE_LABELS,
  CASE_NOTES_TYPES_MAP,
} from "../CaseNotes/constants";
import dayjs from "dayjs";
import { AllocateModalProps, OpenCaseModal } from "./OpenCaseModal";

interface IRouteParams {
  id: string;
}

export function CaseFiles() {
  const coreRecordState = useSelector(selectCoreRecord);
  const { actions: coreRecordActions } = useCoreRecordSlice();
  const dispatch = useDispatch();
  const authState = useSelector(selectAuth);
  const currentUser = getIn(authState, "currentUser", {});
  const [modalProps, setModalProps] = useState({} as IModalProps);
  const [allocateModalProps, setAllocateModalProps] = useState(
    {} as AllocateModalProps
  );
  const [triggerModal, setTriggerModal] = useState(false);
  const [triggerAllocateModal, setTriggerAllocateModal] = useState(false);
  const coreRecordDetails = getIn(coreRecordState, "coreRecordDetails", []);
  const activeCaseFile = getIn(coreRecordDetails, "ActiveCaseDetails", {});
  const caseFiles = getIn(coreRecordState, "caseFiles", []);
  const { id } = useParams<IRouteParams>();
  const allocationDetails = useRef({});
  const history = useHistory();

  useEffect(() => {
    dispatch(
      coreRecordActions.getCoreRecordRequest({
        partitionKey: id,
        idToken: authState.session?.idToken,
      })
    );
    dispatch(
      coreRecordActions.getCaseFilesRequest({
        id,
        idToken: authState.session?.idToken,
      })
    );
    return () => {
      dispatch(coreRecordActions.resetCoreRecordState());
    };
  }, []);
  const openCase = () => {
    setAllocateModalProps({
      title: `Open a case file`,
      message: `You are about to open a case file for ${coreRecordDetails.FirstName}, please choose from the following options`,
      leftButtonNote: `(The case file will be allocated to You for handling)`,
      rightButtonNote: `(The case file will be sent to a Manager for allocation.)`,
      positiveButtonText: "CREATE A CASE FILE",
      handleLeftClick: () => handleCaseAllocation(true),
      handleRightClick: () => handleCaseAllocation(false),
      handlePositiveClick: () => handleOpenCase(),
    });
    handleCaseAllocation(true);
    setTriggerAllocateModal(true);
  };

  const closeCase = () => {
    setModalProps({
      title: `Close a case file`,
      message: `Are you sure you want to close active case for ${coreRecordDetails.FirstName} ${coreRecordDetails.FamilyName}`,
      positiveButtonText: "YES, CLOSE CASE",
      cancelButtonText: "CANCEL",
      handlePositiveClick: () => handleCloseCase(),
    });
    setTriggerModal(true);
  };
  const handleOpenCase = () => {
    const data = {
      ...allocationDetails.current,
      responsible: `${currentUser.FirstName} ${currentUser.LastName}`,
      caseNumber: coreRecordDetails.CaseNumber || 0,
    };
    dispatch(
      coreRecordActions.createCaseRequest({
        idToken: authState.session?.idToken,
        data,
        crId: coreRecordDetails.id,
      })
    );
  };
  const handleCaseAllocation = (allocateToMe) => {
    let allocationData = {};
    const { id, FirstName, LastName, EmailAddress, PhoneNumber } = currentUser;

    if (allocateToMe) {
      allocationData = {
        OwnerId: id,
        OwnerDetails: { id, FirstName, LastName, EmailAddress, PhoneNumber },
        Status: "Allocated",
        IsAllocated: "1",
        PendingAllocation: undefined,
      };
    } else {
      allocationData = {
        Status: "PendingAllocation",
        IsAllocated: "0",
        PendingAllocation: true,
      };
    }
    allocationDetails.current = allocationData;
  };
  const handleCloseCase = () => {
    dispatch(
      coreRecordActions.closeCaseRequest({
        idToken: authState.session?.idToken,
        data: activeCaseFile,
        crId: coreRecordDetails.id,
      })
    );
  };
  const columns = React.useMemo(
    () => [
      {
        Header: "",
        accessor: "RagRating",
        disableSortBy: true,
        style: {
          width: "10px",
          padding: 0,
        },
        Cell: ({ row }: any) => {
          const backgroundCol = getIn(row, "original.RagRating");
          return (
            <div className={`${RAG_COLORS_MAP[backgroundCol]} w-2 h-full`} />
          );
        },
      },
      {
        Header: "Document Type",
        accessor: "DocumentType",
        Cell: ({ row }: any) => {
          const documentType = getIn(row, "original.DocumentType");
          return (
            <div className="rounded-3xl bg-primaryLight table-cell px-2 text-center cursor-pointer">
              <span className="text-sm">
                {CASE_FILE_TYPE_LABELS[documentType]}
              </span>
            </div>
          );
        },
      },
      {
        Header: "Document Title",
        accessor: "HeadLine",
        Cell: ({ row }: any) => {
          const headLine = getIn(row, "original.HeadLine");
          return (
            <div
              className="text-sm text-black"
              style={{
                width: "20ch",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              {headLine}
            </div>
          );
        },
      },
      {
        Header: "Last Update",
        accessor: "LastUpdated",
        Cell: ({ row }: any) => {
          const lastUpdatedDate = getIn(row, "original.LastUpdated");
          return (
            <span className="text-black text-sm font-light">
              {dayjs(lastUpdatedDate).format("DD.MM.YYYY HH:mm")}
            </span>
          );
        },
      },
      {
        Header: "Case File",
        accessor: "CaseNumber",
        Cell: ({ row }: any) => {
          const caseNumber = getIn(row, "original.CaseNumber");
          return (
            <div className="rounded-3xl bg-primaryLight table-cell px-2 text-center cursor-pointer">
              <span className="text-sm">{caseNumber}</span>
            </div>
          );
        },
      },
      {
        Header: "Status",
        accessor: "Status",
        Cell: ({ row }: any) => {
          const caseId = getIn(row, "original.CaseId");
          const activeCase = getIn(coreRecordDetails, "ActiveCaseDetails");
          const caseStatus = activeCase?.id === caseId ? "Active" : "Closed";

          return (
            <span className="text-black text-sm font-light">{caseStatus}</span>
          );
        },
      },
      {
        Header: () => null,
        id: "arrowLink",
        disableSortBy: true,
        accessor: "id",
        Cell: ({ row }: any) => {
          const caseFileId = getIn(row, "original.id");
          const workflowId = getIn(row, "original.WorkflowId");
          const caseFileType = getIn(row, "original.DocumentType");
          const viewLink = workflowId
            ? `/mywork/${workflowId}`
            : `case-notes/${CASE_NOTES_TYPES_MAP[caseFileType]?.value}/${caseFileId}`;
          const location = {
            pathname: viewLink,
            state: {
              fromCaseFile: true,
              from: history.location.pathname,
            },
          };

          return (
            <Link to={location}>
              <div data-testid="table-arrow-forward">
                <ArrowForwardIcon className="w-6 h-6 md:w-7 md:h-7" />
              </div>
            </Link>
          );
        },
      },
    ],
    []
  );
  const rowFilters = ["DocumentType", "CaseNumber"];
  return (
    coreRecordState && (
      <Wrapper>
        {isMobile ? (
          <div className="flex pt-10 items-center gap-4 mb-10">
            <Link to={`/core-record/manage/${coreRecordDetails.id}`}>
              <ArrowBackIcon className="h-8 w-8 text-l" />
            </Link>
            <h1 className="text-l font-semibold">BACK</h1>
          </div>
        ) : (
          <div>
            <CoreRecoridInfoDetails {...coreRecordDetails} />
          </div>
        )}

        <div className="mt-5 xl:px-0 sm:flex sm:flex-row-reverse w-full items-center">
          <span className="sm:flex-1 sm:flex sm:justify-end w-96 sm:ml-64">
            {activeCaseFile?.id ? (
              <span className="w-full">
                <CustomButton
                  handleClick={closeCase}
                  type={"button"}
                  label={"Close active case"}
                />
              </span>
            ) : (
              <span className="w-full">
                <CustomButton
                  handleClick={openCase}
                  type={"button"}
                  label={"Open a case file"}
                />
              </span>
            )}
          </span>
          <span className="w-full sm:flex-1">
            <h1 className="text-2xl font-semibold mt-8 sm:mt-0 w-full">
              My Case Files
            </h1>
          </span>
        </div>
        <CustomModal
          open={triggerModal}
          setOpen={setTriggerModal}
          {...modalProps}
        />
        <OpenCaseModal
          open={triggerAllocateModal}
          setOpen={setTriggerAllocateModal}
          {...allocateModalProps}
        />
        {activeCaseFile?.id && (
          <div className="flex text-sm text-secondaryText">
            <span className="pr-5 text-secondaryText">
              {`Active case: ${activeCaseFile.CaseNumber}`}
            </span>
            <span className="pr-5 text-secondaryText">
              {activeCaseFile.OwnerDetails
                ? activeCaseFile.OwnerDetails.id === currentUser.id
                  ? `Key worker: Me`
                  : `Key worker: ${activeCaseFile.OwnerDetails?.FirstName} ${activeCaseFile.OwnerDetails?.LastName}`
                : "Key worker: Pending to be Allocated"}
            </span>
          </div>
        )}
        <Table columns={columns} data={caseFiles} filterRows={rowFilters} />
      </Wrapper>
    )
  );
}
