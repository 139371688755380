import { createSlice } from "utils/@reduxjs/toolkit";
import { useInjectReducer, useInjectSaga } from "utils/redux-injectors";
import { adminSaga } from "./saga";
import { IAdminState } from "./types";

export const initialState: IAdminState = {
  isLoading: false,
  userDetails: {
    Teams: [],
  },
};

const slice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    registerUserRequest(state, { payload }) {
      state.userData = payload.data;
      state.isLoading = true;
    },
    registerUserSuccess(state, { payload }) {
      state.userData = payload.data;
      state.isLoading = false;
      state.error = null;
    },
    registerUserError(state, { payload }) {
      state.error = payload.error;
      state.isLoading = false;
    },
    getUsersRequest(state, { payload }) {
      state.isLoading = payload.loading;
    },
    getUsersSuccess(state, { payload }) {
      state.userData = payload.data.map((user) => {
        const Name = `${user.FirstName} ${user.LastName}`;
        const Manager = user.ManagerDetails
          ? `${user.ManagerDetails.FirstName} ${user.ManagerDetails.LastName}`
          : "";
        return { ...user, Name, Manager };
      });
      state.isLoading = false;
      state.error = null;
    },
    getUsersError(state, { payload }) {
      state.error = payload.error;
      state.isLoading = false;
    },
    getUserRequest(state, { payload }) {
      state.isLoading = payload.loading;
    },
    getUserSuccess(state, { payload }) {
      state.userDetails = payload.user;
      state.isLoading = false;
      state.error = null;
    },
    getUserError(state, { payload }) {
      state.error = payload.error;
      state.isLoading = false;
    },
    getManagersRequest(state, { payload }) {
      state.isLoading = payload.loading;
    },
    getManagersSuccess(state, { payload }) {
      state.managerData = payload.managerData;
      state.isLoading = false;
      state.error = null;
    },
    getManagersError(state, { payload }) {
      state.error = payload.error;
      state.isLoading = false;
    },
    getTeamsRequest(state, { payload }) {
      state.isLoading = payload.loading;
    },
    getTeamsSuccess(state, { payload }) {
      state.teamsData = payload.teamsData;
      state.isLoading = false;
      state.error = null;
    },
    getTeamsError(state, { payload }) {
      state.error = payload.error;
      state.isLoading = false;
    },
    getRolesRequest(state, { payload }) {
      state.isLoading = payload.loading;
    },
    getRolesRequestSuccess(state, { payload }) {
      state.rolesData = payload.rolesData;
      state.isLoading = false;
      state.error = null;
    },
    getRolesRequestError(state, { payload }) {
      state.error = payload.error;
      state.isLoading = false;
    },

    getOrganizationLocationsRequest(state, { payload }) {
      state.isLoading = payload.loading;
    },
    getOrganizationLocationsRequestSuccess(state, { payload }) {
      state.locationsData = payload.locationsData.sort(
        (location1: { Name: string }, location2: { Name: any }) =>
          location1.Name.localeCompare(location2.Name)
      );
      state.isLoading = false;
      state.error = null;
    },
    getLocationsRequestError(state, { payload }) {
      state.error = payload.error;
      state.isLoading = false;
    },

    updateUserRequest(state, { payload }) {
      payload;
    },
    updateUserSuccess(state, { payload }) {
      payload;
    },
    updateUserError(state, { payload }) {
      payload;
    },
    deleteUserRequest(state, { payload }) {
      payload;
    },
    deleteUserSuccess(state, { payload }) {
      payload;
    },
    deleteUserError(state, { payload }) {
      payload;
    },
    updateUserDetails(state, { payload }) {
      state.userDetails[payload.key] = payload.value;
    },
    addTeamToUser(state, { payload }) {
      state.userDetails.Teams.push(payload.team);
    },
    removeTeamFromUser(state, { payload }) {
      state.userDetails.Teams = state.userDetails.Teams.filter((team) => {
        if (team.key !== payload.team.key) {
          return team;
        }
        if (state.userDetails.deletedTeams) {
          state.userDetails.deletedTeams.push({ SK: team.SK });
        } else {
          state.userDetails.deletedTeams = [];
          state.userDetails.deletedTeams.push({ SK: team.SK });
        }
      });
      // Update teams keys
      state.userDetails.Teams = state.userDetails.Teams.map((team, index) => {
        team.key = `team${index}`;
        return team;
      });
    },
    updateTeamFromUser(state, { payload }) {
      state.userDetails.Teams = state.userDetails.Teams.filter((team) => {
        if (team.key === payload.key) {
          if (payload.dataKey === "team") {
            team.Team = payload.value.Value;
            team.SK = payload.value.SK;
            return team;
          }
          if (payload.dataKey === "role") {
            team.Role = payload.value;
            return team;
          }
          if (payload.dataKey === "manager") {
            team.Manager = payload.value;
            return team;
          }
          if (payload.dataKey) {
            team[payload.dataKey] = payload.value;
          }
        }
        return team;
      });
    },

    getActiveCasesRequest(state, { payload }) {
      state.activeCases = payload.data;
    },
    getActiveCasesSuccess(state, { payload }) {
      state.activeCases = payload?.data;
    },
    getActiveCasesError(state, { payload }) {
      state.error = payload.error;
      payload;
    },
    adminAllocateCaseRequest(state, { payload }) {
      payload;
    },
    adminAllocateCaseSuccess(state, { payload }) {
      const replaceIndex = state.activeCases.findIndex(
        (data) => data.id == payload.data.id
      );
      state.activeCases[replaceIndex] = payload.data;
    },
    adminAllocateCaseError(state, { payload }) {
      state.error = payload.error;
      payload;
    },
  },
});

export const { actions: adminActions, reducer: adminReducers } = slice;

export const useAdminSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({
    key: slice.name,
    saga: adminSaga,
  });
  return { actions: slice.actions };
};
