import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

interface Props {
  value: string;
  ref?: any;
  setValue: any;
  name: string;
  testId?: string;
}

const CustomRichText = ({ value, setValue, name, testId, ref }: Props) => {
  const modules = {
    toolbar: [
      ["bold", "italic", "underline", { list: "ordered" }, { list: "bullet" }],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];

  const handleChange = (content) => {
    if (content === value) return;
    setValue(content, name);
  };

  return (
    <div data-testid={testId}>
      <ReactQuill
        ref={ref}
        theme="snow"
        value={value}
        onChange={handleChange}
        modules={modules}
        formats={formats}
      />
    </div>
  );
};

export default CustomRichText;
