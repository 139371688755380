import * as React from "react";

function SvgFilterMenu(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 15.95v-1.5h6.175v1.5H0zm0-12.4v-1.5h10.325v1.5H0zM7.675 18v-5.625h1.5v2.075H18v1.5H9.175V18h-1.5zm-3-6.2V9.75H0v-1.5h4.675v-2.1h1.5v5.65h-1.5zm3-2.05v-1.5H18v1.5H7.675zm4.15-4.125V0h1.5v2.05H18v1.5h-4.675v2.075h-1.5z"
        fill="#133092"
      />
    </svg>
  );
}

export default SvgFilterMenu;
