/**
 *
 * OpenCaseModal
 *
 */
import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useRef, useState } from "react";
import { CustomButton } from "../../../../components/UI/Forms/Buttons/CustomButton/Loadable";

interface Props {
  title: string;
  open: boolean;
  setOpen: any;
  message?: string;
  infoText?: string;
  handlePositiveClick?: any;
  positiveButtonText?: string;
  rightButtonText?: string;
  leftButtonNote?: string;
  rightButtonNote?: string;
  handleLeftClick?: any;
  handleRightClick?: any;
  leftButtonText?: string;
  handleCrossClick?: any;
}
export interface AllocateModalProps {
  title: string;
  message?: string;
  infoText?: string;
  handlePositiveClick?: any;
  positiveButtonText?: string;
  rightButtonText?: string;
  handleLeftClick?: any;
  handleRightClick?: any;
  leftButtonText?: string;
  handleCrossClick?: any;
  leftButtonNote?: string;
  rightButtonNote?: string;
}

export function OpenCaseModal(props: Props) {
  const {
    open,
    setOpen,
    handlePositiveClick,
    handleLeftClick,
    handleRightClick,
    handleCrossClick,
  } = props;
  const [allocateToMeActive, setAllocateToMeActive] = useState(true);
  const buttonNote = useRef(
    "(The case file will be allocated to You for handling)" as any
  );
  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
        data-testid="modal"
      >
        <div className="flex items-center justify-center min-h-screen sm:pt-4 sm:px-4 text-center sm:bg-none sm:items-end sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="flex flex-col justify-between items-center sm:inline-block align-bottom bg-white rounded w-full h-screen p-4 text-left overflow-hidden shadow-xl transform transition-all sm:h-full sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full sm:p-8">
              <div
                className="flex w-full justify-end items-end text-right"
                onClick={() => {
                  setOpen(false);
                  if (handleCrossClick) {
                    handleCrossClick();
                  }
                }}
                data-testid="cross-button"
              >
                <i className="material-icons-round text-3xl cursor-pointer">
                  close
                </i>
              </div>
              <div className="w-full flex items-center">
                <div className="mt-3 text-center sm:mt-5 w-full">
                  <Dialog.Title
                    as="h3"
                    className="text-2xl leading-6 font-semibold text-primary text-center tracking-wider"
                  >
                    {props.title}
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-md text-black font-light">
                      {props.message}
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex flex-col-reverse w-full mt-12 sm:flex-row justify-center">
                <CustomButton
                  type="button"
                  label={
                    props.leftButtonText
                      ? props.leftButtonText
                      : "Allocate to me"
                  }
                  handleClick={() => {
                    if (handleLeftClick) {
                      handleLeftClick();
                      setAllocateToMeActive(true);
                      buttonNote.current = props.leftButtonNote;
                    }
                  }}
                  isLight={true}
                  customClass={`rounded-r-none py-3 px-4 h-12 rounded-md w-64
                  ${
                    allocateToMeActive
                      ? "text-primary border border-primary font-semibold bg-primaryLight"
                      : "bg-gray-100 text-gray-500 border font-normal"
                  }`}
                  testId="modal-allocate-me-button"
                />
                <div className="py-2" />
                <CustomButton
                  type="button"
                  label={
                    props.rightButtonText
                      ? props.rightButtonText
                      : "Send for Allocation"
                  }
                  isLight={true}
                  customClass={`rounded-l-none py-3 px-4 h-12 rounded-md w-64
                  ${
                    !allocateToMeActive
                      ? "text-primary border border-primary bg-primaryLight font-semibold"
                      : "bg-gray-100 text-gray-400 border font-normal"
                  }`}
                  handleClick={() => {
                    if (handleRightClick) {
                      handleRightClick();
                      setAllocateToMeActive(false);
                      buttonNote.current = props.rightButtonNote;
                    }
                  }}
                  testId="modal-allocate-send-button"
                />
              </div>
              <div className="text-center font-extralight">
                <p className="text-sm pt-2 leading-8">{buttonNote.current}</p>
              </div>
              <div className="w-full mt-16">
                <CustomButton
                  type="button"
                  label={
                    props.positiveButtonText
                      ? props.positiveButtonText
                      : "CREATE A CASE FILE"
                  }
                  handleClick={() => {
                    setOpen(false);
                    if (handlePositiveClick) {
                      handlePositiveClick();
                      setAllocateToMeActive(true);
                    }
                  }}
                  testId="modal-confirm-button"
                />
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
