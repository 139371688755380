/**
 *
 * ManageCoreRecords
 *
 */
import React from "react";
import ManageCoreRecordsTable from "./ManageCoreRecordsTable";

export function ManageCoreRecords() {
  return (
    <div className="max-w-screen-xl pt-10 px-10 xl:px-0">
      <div className="flex justify-between flex-col lg:flex-col">
        <h1 className="py-3 text-3xl tracking-widest pb-5">My People</h1>
        <ManageCoreRecordsTable />
      </div>
    </div>
  );
}
