/**
 *
 * Table
 *
 */
import React, { memo, useEffect, useState } from "react";

import {
  useTable,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  usePagination,
} from "react-table";
import {
  ChevronRightIcon,
  ChevronLeftIcon,
  CloseIcon,
  FilterMenu,
} from "../icons";
import { Button, PageButton, PaginationButton } from "./shared/Button";
import { SortIcon, SortUpIcon, SortDownIcon } from "./shared/Icons";
import { Link } from "react-router-dom";
import DefaultAvatar from "../icons/svgs/person_icon.svg";
import { MobileView, BrowserView } from "react-device-detect";
import { classNames } from "./shared/Utils";
import { get as getIn } from "lodash";
import { CustomSelect } from "../UI/Forms/Inputs/CustomSelect";

interface IGlobalFilter {
  preGlobalFilteredRows: [];
  globalFilter: string;
  isGlobalSelect?: boolean;
  searchSettings?: any;
  selectOptions?: any;
  filterColumn?: any;
  setGlobalFilter(value: any): any;
  setColumnFilter?(column: string, value: any): any;
}
// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  searchSettings,
  setGlobalFilter,
  isGlobalSelect,
  selectOptions,
  filterColumn,
  setColumnFilter,
}: IGlobalFilter) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
    if (setColumnFilter && filterColumn) {
      setColumnFilter(filterColumn.id, value);
    }
  }, 200);
  if (filterColumn) {
    selectOptions = [];
    filterColumn.preFilteredRows.map((row) => {
      if (row.values[filterColumn.id]) {
        const optionData = {
          label: row.values[filterColumn.id],
          value: row.values[filterColumn.id],
        };
        const item = selectOptions.find(
          (res) => res.label === optionData.label
        );
        if (!item) {
          selectOptions.push(optionData);
        }
      }
    });
  }
  if (selectOptions) {
    selectOptions.unshift({ label: "Show All", value: "" });
  }
  return (
    <label className="flex gap-x-2 items-baseline w-full">
      {!isGlobalSelect ? (
        <input
          type="text"
          className="w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          value={value || ""}
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          placeholder={getIn(
            searchSettings,
            "placeHolder",
            `${count} records...`
          )}
        />
      ) : (
        <div className="w-full flex">
          <span className="mr-3 inline-block mt-2.5">
            <FilterMenu className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </span>
          <span className="inline-block	w-72">
            <CustomSelect
              selectValue={value || (selectOptions ? selectOptions[0] : "")}
              onChange={(e) => {
                setValue(e?.value || "");
                onChange(e?.value || "");
              }}
              isClearable={false}
              isSearchable={false}
              setSelectValue={setValue}
              inputID={"selectFilter"}
              options={selectOptions}
              name={"selectFilter"}
              label={""}
              customOptionClass={{
                "border-bottom": "2px solid",
                "border-color": "#D2D6DF",
                height: "20px",
                width: "100%",
                color: "#4D5E80",
                "background-color": "unset",
                "padding-bottom": "25px",
                "padding-top": "10px",
                selected: "opacity: 50%",
              }}
              customMenuClass={{
                "max-height": "280px",
                overflow: "auto",
                display: "inline-block",
                margin: "0 auto",
                "align-items": "center",
                "margin-top": "10px",
                "font-size": "12px",
                "line-height": "16px",
                padding: "0 50px 0 20px",
              }}
              customValueContainerClass={{
                flex: "inherit",
                opacity: "60%",
                "font-size": "12px",
                "line-height": "16px",
                "padding-top": "0.6rem",
              }}
              customIndicatorClass={{
                display: "none",
              }}
              customSingleValueClass={{
                height: "30px",
              }}
            />
          </span>
        </div>
      )}
    </label>
  );
}

interface IAvatarCell {
  value: any;
  column: {
    imgAccessor: string;
    idAccessor: string;
    Name: string;
    disableLink?: boolean;
  };
  row: {
    original: any;
  };
}

export function AvatarCell({ value, column, row }: IAvatarCell): JSX.Element {
  const image = row.original[column.imgAccessor] || DefaultAvatar;
  const showLink = !column.disableLink;
  const CellItem = () => (
    <div className="flex items-center">
      <div className="flex-shrink-0 h-10 w-10">
        <img className="h-10 w-10 rounded-full" src={image} alt="" />
      </div>
      <div className="ml-4">
        <div className="text-sm font-medium text-gray-900">{value}</div>
        <div className="text-sm text-gray-500">{row.original[column.Name]}</div>
      </div>
    </div>
  );

  return showLink ? (
    <Link
      key={row.original[column.idAccessor]}
      to={`manage/${row.original[column.idAccessor]}`}
    >
      <CellItem />
    </Link>
  ) : (
    <CellItem />
  );
}

interface ITableProps {
  columns?: any;
  data?: any;
  hideTableBody?: boolean;
  searchSettings?: {
    globalSelect?: boolean;
    placeHolder?: string;
    showSearchField?: boolean;
    filterBy?: any;
  };
  filterRows?: any;
  tableDataLength?(value: any): any;
}

export const Table = memo(
  ({
    columns,
    data,
    searchSettings,
    filterRows,
    tableDataLength,
    hideTableBody,
  }: ITableProps) => {
    let globalFilter: any;
    if (searchSettings && searchSettings.filterBy) {
      globalFilter = (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[searchSettings.filterBy];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : false;
        });
      };
    }
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      page, // Instead of using 'rows', we'll use page,
      // which has only the rows for the active page

      // The rest of these things are super handy, too ;)
      canPreviousPage,
      canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      state,
      preGlobalFilteredRows,
      setGlobalFilter,
      setFilter: setColumnFilter,
    } = useTable(
      {
        columns,
        data,
        globalFilter,
      },
      useFilters, // useFilters!
      useGlobalFilter,
      useSortBy,
      usePagination // new
    );
    const [activeFilters, setActiveFilters] = useState([] as any);

    function removeFilter(filter) {
      const { setFilter } = filter.rowColumn.column;
      const filters = activeFilters.filter((res) => res.value != filter.value);
      setActiveFilters(filters);
      setFilter("");
    }

    function selectFilter(row, value) {
      let hasFilter = false;
      let filters = activeFilters;
      const rowColumn = row.allCells.find((res) => res.value === value);
      const { setFilter } = rowColumn.column;

      activeFilters.find((res) => {
        if (res.value === value) {
          hasFilter = true;
        }
      });

      if (!hasFilter) {
        setFilter(value);
        filters = [...activeFilters, { rowColumn, value }];
      }
      setActiveFilters(filters);
    }

    const generatePage = (index, currentPage) => {
      return (
        <PaginationButton
          key={index}
          isActive={index === currentPage}
          onClick={() => {
            gotoPage(index);
            setCurrentPage(index);
          }}
        >
          {index + 1}
        </PaginationButton>
      );
    };

    const [pageButtons, setPageButtons] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [filterColumn, setFilterColumn] = useState(undefined);
    const generatePaginationPages = () => {
      const pages: any = [];
      for (let index = 0; index < pageCount; index++) {
        pages.push(generatePage(index, currentPage));
      }
      return pages;
    };

    useEffect(() => {
      const paginationPages = generatePaginationPages();
      setPageButtons(paginationPages);
      setCurrentPage(state.pageIndex);
    }, [pageCount, currentPage, state.pageIndex]);
    useEffect(() => {
      if (tableDataLength) {
        tableDataLength({
          recordNumber: page?.length,
          searchFilter: state.globalFilter,
        });
      }
    }, [page]);
    // Render the UI for your table
    return (
      <>
        <div className="sm:flex sm:gap-x-2">
          {searchSettings && searchSettings.showSearchField && (
            <GlobalFilter
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={state.globalFilter}
              setGlobalFilter={setGlobalFilter}
              searchSettings={searchSettings}
              filterColumn={filterColumn}
              isGlobalSelect={searchSettings.globalSelect || false}
              setColumnFilter={setColumnFilter}
            />
          )}
          {headerGroups.map((headerGroup) =>
            headerGroup.headers.map((column) => {
              if (
                searchSettings &&
                searchSettings.globalSelect &&
                column.selectFilter
              ) {
                const saveFilter = useAsyncDebounce((value) => {
                  setFilterColumn(value || undefined);
                }, 200);
                saveFilter(column);
              } else {
                column.Filter ? (
                  <div className="mt-2 sm:mt-0" key={column.id}>
                    {column.render("Filter")}
                  </div>
                ) : null;
              }
            })
          )}
        </div>
        {/* table */}
        {!hideTableBody && (
          <div className="mt-4 flex flex-col">
            <div className="md:-my-2 overflow-none md:-mx-4 sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div className="md:shadow overflow-hidden md:border-b md:border-gray-200 sm:rounded-lg">
                  <table
                    {...getTableProps()}
                    className="min-w-full divide-y divide-gray-200"
                    style={{
                      height: "1px",
                    }}
                  >
                    <thead className="bg-primaryLight text-primary hidden md:table-header-group">
                      {headerGroups.map((headerGroup, index) => (
                        <tr key={index} {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column) => (
                            // Add the sorting props to control sorting. For this example
                            // we can add them into the header props
                            <th
                              key={column}
                              scope="col"
                              className="group px-6 py-5 text-left text-xs font-seminbold text-gray-500 tracking-wider"
                              {...column.getHeaderProps([
                                {
                                  className: `group px-6 py-5 text-left text-xs font-seminbold text-gray-500 tracking-wider ${column.className}`,
                                  style: column.style,
                                },
                                column.getSortByToggleProps(),
                              ])}
                            >
                              <div className="flex items-center justify-between">
                                {column.render("Header")}
                                {/* Add a sort direction indicator */}
                                <span>
                                  {column.isSorted ? (
                                    column.isSortedDesc ? (
                                      <SortDownIcon className="w-4 h-4 text-gray-400" />
                                    ) : (
                                      <SortUpIcon className="w-4 h-4 text-gray-400" />
                                    )
                                  ) : (
                                    <SortIcon
                                      className={classNames(
                                        "w-4 h-4 text-gray-400 opacity-0 group-hover:opacity-100",
                                        !column.canSort && "hidden"
                                      )}
                                    />
                                  )}
                                </span>
                              </div>
                            </th>
                          ))}
                        </tr>
                      ))}
                      {activeFilters.length > 0 && (
                        <tr>
                          <th colSpan={100} className="text-left bg-gray-100">
                            <div className="inline-flex pl-8 font-normal my-3 text-xs items-center">
                              <span className="font-normal text-black mr-7">
                                Active filters:
                              </span>
                              {activeFilters.map((filter) => {
                                return (
                                  <div
                                    key={filter.value}
                                    className="rounded-3xl bg-primaryLight px-2 mx-2 flex items-center font-normal py-1 gap-2"
                                  >
                                    <span>{filter.value}</span>
                                    <CloseIcon
                                      onClick={() => removeFilter(filter)}
                                      className="rounded-full bg-purpleHighLight h-4 w-4 cursor-pointer"
                                      fill="#C5CCE4"
                                    />
                                  </div>
                                );
                              })}
                            </div>
                          </th>
                        </tr>
                      )}
                    </thead>
                    <tbody
                      {...getTableBodyProps()}
                      className="bg-white divide-y divide-gray-200"
                    >
                      {page.map((row, i) => {
                        // new
                        prepareRow(row);
                        return (
                          <tr key={i} {...row.getRowProps()} className="py-15">
                            {row.cells.map((cell, index) => {
                              return (
                                <td
                                  onClick={
                                    filterRows &&
                                    filterRows.includes(cell.column.id)
                                      ? () => selectFilter(row, cell.value)
                                      : undefined
                                  }
                                  key={index}
                                  {...cell.getCellProps([
                                    {
                                      className: `px-6 py-4 whitespace-nowrap md:border-0 border-b-8 border-primaryBG ${cell.column.className}`,
                                      style: cell.column.style,
                                    },
                                  ])}
                                  role="cell"
                                >
                                  {cell.column.Cell.name ===
                                  "defaultRenderer" ? (
                                    <div className="text-sm text-black tracking-wider font-light">
                                      {cell.render("Cell")}
                                    </div>
                                  ) : (
                                    cell.render("Cell")
                                  )}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
        {!hideTableBody && (
          <div className="py-3 flex items-center justify-between">
            {pageCount > 1 && (
              <MobileView className="flex-1 flex justify-between">
                <Button
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                  className
                >
                  Previous
                </Button>
                <Button
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                  className
                >
                  Next
                </Button>
              </MobileView>
            )}

            <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
              {(page.length > 5 || pageCount > 1) && (
                <div className="flex gap-x-2 items-baseline">
                  <span className="text-sm text-gray-700">
                    Page{" "}
                    <span className="font-medium">{state.pageIndex + 1}</span>{" "}
                    of <span className="font-medium">{pageOptions.length}</span>
                  </span>
                  <label>
                    <span className="sr-only">Items Per Page</span>
                    <select
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                      value={state.pageSize}
                      onChange={(e) => {
                        setPageSize(Number(e.target.value));
                        setCurrentPage(0);
                      }}
                    >
                      {[5, 10, 20].map((pageSize) => (
                        <option key={pageSize} value={pageSize}>
                          Show {pageSize}
                        </option>
                      ))}
                    </select>
                  </label>
                </div>
              )}
              {pageCount > 1 && (
                <BrowserView>
                  <nav
                    className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
                    aria-label="Pagination"
                  >
                    <PageButton
                      onClick={() => previousPage()}
                      disabled={!canPreviousPage}
                    >
                      <span className="sr-only">Previous</span>
                      <ChevronLeftIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </PageButton>

                    {pageButtons}

                    <PageButton
                      onClick={() => nextPage()}
                      disabled={!canNextPage}
                    >
                      <span className="sr-only">Next</span>
                      <ChevronRightIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </PageButton>
                  </nav>
                </BrowserView>
              )}
            </div>
          </div>
        )}
      </>
    );
  }
);
