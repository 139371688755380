/**
 *
 * EditProfessionalConnection
 *
 */
import React, { memo, useEffect, useState } from "react";
import { Wrapper } from "app/components/UI/Layout/Wrapper";
import { CustomInput } from "app/components/UI/Forms/Inputs/CustomInput";
import { CustomSelect } from "app/components/UI/Forms/Inputs/CustomSelect";
import { RadioGroup } from "@headlessui/react";
import { CustomRadioButton } from "app/components/UI/Forms/Inputs/CustomRadioButton";
import CustomRichText from "app/components/UI/Forms/Inputs/CustomRichText";
import { CustomButton } from "app/components/UI/Forms/Buttons/CustomButton";
import { Prompt, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectCoreRecord } from "../../slice/selectors";
import { get as getIn } from "lodash";
import { useCoreRecordSlice } from "../../slice";
import { selectAuth } from "app/containers/Auth/slice/selectors";
import { SPECIALITY_TYPES } from "../../CoreRecord/RegisterCoreRecord/constants";
import { CoreRecoridInfoDetails } from "app/components/CoreRecoridInfoDetails";
import { ArrowBackIcon } from "app/components/icons";
import { WarningBanner } from "app/components/UI/Banners/WarningBanner";
import { PhoneInput } from "app/components/UI/Forms/Inputs/PhoneInput";
import { useAdminSlice } from "app/containers/Admin/slice";
import { selectAdmin } from "app/containers/Admin/slice/selectors";
import { CustomModal, IModalProps } from "app/components/CustomModal";
import { CustomLinkButton } from "app/components/UI/Forms/Buttons/CustomLinkButton";
import { toast } from "react-toastify";
interface IRouteParams {
  id: string;
  conId: string;
}

export const checkValidity = (values, target) => {
  if (!values.FirstName) {
    target["firstName"].focus();
    toast.info("Please fill the highlighted field", {
      position: "bottom-center",
    });
    return false;
  }
  if (!values.LastName) {
    target["lastName"].focus();
    toast.info("Please fill the highlighted field", {
      position: "bottom-center",
    });
    return false;
  }
  if (!values.PhoneNumber) {
    toast.info("Please fill the highlighted field", {
      position: "bottom-center",
    });
    target["phone-input"].focus();
    return false;
  }
  if (!values.Email) {
    toast.info("Please fill the highlighted field", {
      position: "bottom-center",
    });
    target["email"].focus();
    return false;
  }
  return true;
};

export const EditProfessionalConnection = memo(() => {
  const dispatch = useDispatch();
  const authState = useSelector(selectAuth);
  const { actions: coreRecordActions } = useCoreRecordSlice();
  const coreRecordState = useSelector(selectCoreRecord);
  const { actions: adminActions } = useAdminSlice();
  const adminState = useSelector(selectAdmin);
  const coreRecordDetails = getIn(coreRecordState, "coreRecordDetails");
  const connectionDetails = getIn(coreRecordState, "connectionDetails");
  const keyWorkerId = getIn(coreRecordDetails, "KeyWorkerId");
  const isKeyWorker = keyWorkerId === connectionDetails?.id ? "yes" : "no";
  const teamsData = getIn(adminState, "teamsData");
  const rolesData = getIn(adminState, "rolesData");
  const [isBlocking, setIsBlocking] = useState(false);
  const [shouldReassignKeyworker, setShouldReassignKeyworker] =
    useState(isKeyWorker);
  const [triggerModal, setTriggerModal] = useState(false);
  const [modalDetails, setModalDetails] = useState<IModalProps>(
    {} as IModalProps
  );
  // const [errorMessages, setErrorMessages] = useState({});

  const { id, conId } = useParams<IRouteParams>();

  const handleCancelButton = () => {
    history.back();
  };

  const handleFormChanges = (key, value) => {
    setIsBlocking(true);
    dispatch(
      coreRecordActions.updateProfessionalConnectionDetails({
        key,
        value,
      })
    );
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();

    if (checkValidity(connectionDetails, event.target)) {
      dispatch(
        coreRecordActions.updateConnectionRequest({
          idToken: authState.session?.idToken,
          data: connectionDetails,
          crId: id,
          conId,
          shouldReassignKeyworker,
          setIsBlocking,
        })
      );
    }
  };

  const handleDeleteConnection = () => {
    dispatch(
      coreRecordActions.deleteConnectionRequest({
        idToken: authState.session?.idToken,
        crId: id,
        conId,
        setIsBlocking,
        isKeyWorker,
      })
    );
  };

  const handleDeleteClick = () => {
    let message;
    if (isKeyWorker === "yes") {
      message = `You are deleting a key worker from the connections. Are you sure you want to delete ${connectionDetails.FirstName} ${connectionDetails.LastName} from ${coreRecordDetails.FirstName} ${coreRecordDetails.FamilyName}’s connection list?
      Once you delete this connection, data will be permanently lost.`;
    } else {
      message =
        "Once you delete this connection, data will be permanently lost.";
    }
    setTriggerModal(true);
    setModalDetails({
      title: "Are you sure you want to delete this professional connection?",
      message,
      handlePositiveClick: handleDeleteConnection,
      positiveButtonText: "YES, DELETE",
      cancelButtonText: "CANCEL",
    } as IModalProps);
  };

  useEffect(() => {
    window.scrollTo({ top: 0 });
    dispatch(
      coreRecordActions.getConnectionRequest({
        crId: id,
        conId,
        idToken: authState.session?.idToken,
      })
    );
    if (!coreRecordDetails) {
      dispatch(
        coreRecordActions.getCoreRecordRequest({
          partitionKey: id,
          idToken: authState.session?.idToken,
        })
      );
    }
    if (!teamsData) {
      dispatch(
        adminActions.getTeamsRequest({
          idToken: authState.session?.idToken,
          loading: true,
        })
      );
    }
    if (!rolesData) {
      dispatch(
        adminActions.getRolesRequest({
          idToken: authState.session?.idToken,
          loading: true,
        })
      );
    }
    return () => {
      dispatch(
        coreRecordActions.getConnectionSuccess({
          data: undefined,
        })
      );
    };
  }, []);

  useEffect(() => {
    setShouldReassignKeyworker(isKeyWorker);
  }, [isKeyWorker]);

  return (
    <>
      <Prompt when={isBlocking} message="" />
      <Wrapper>
        <div className="hidden w-full lg:block">
          <CoreRecoridInfoDetails {...coreRecordDetails} />
        </div>
        <div className="w-full flex justify-between items-center mt-6">
          <div className="flex flex-col lg:items-center w-full gap-4  lg:flex-row">
            <div
              className="flex items-center gap-4"
              onClick={() => history.back()}
            >
              <ArrowBackIcon
                className="w-10 h-10 cursor-pointer"
                data-testid="draft-back-button"
              />
              <span className="text-xl font-semibold lg:hidden">BACK</span>
            </div>
            <h1 className=" tracking-wider w-full text-xl md:text-2xl font-semibold">
              Edit a Professional Connection
            </h1>
          </div>
          <div className="hidden justify-end lg:flex w-1/3 py-1">
            <CustomLinkButton
              type={"button"}
              label={"Delete connection"}
              handleClick={handleDeleteClick}
            />
          </div>
        </div>

        <form
          className="w-full grid grid-cols-1 grid-flow-row gap-4 py-10 2xl:px-0 md:grid-cols-2 lg:grid-cols-3"
          onSubmit={handleFormSubmit}
        >
          <h1 className="md:col-span-full text-xl font-semibold tracking-wider py-2">
            Basic Information
          </h1>
          <CustomInput
            inputId={"FirstName"}
            inputLabel={"First Name"}
            inputName={"FirstName"}
            inputValue={connectionDetails?.FirstName}
            onChange={(value) => handleFormChanges("FirstName", value)}
            isRequired={true}
          />
          <CustomInput
            inputId={"lastName"}
            inputLabel={"Last Name"}
            inputName={"lastName"}
            inputValue={connectionDetails?.LastName}
            onChange={(value) => handleFormChanges("LastName", value)}
            isRequired={true}
          />
          <CustomSelect
            selectValue={connectionDetails?.Role}
            setSelectValue={(value) => handleFormChanges("Role", value)}
            inputID={"role"}
            options={rolesData}
            name={"role"}
            label={"Role"}
          />
          <CustomSelect
            selectValue={connectionDetails?.Team}
            setSelectValue={(value) => handleFormChanges("Team", value)}
            inputID={"team"}
            options={teamsData}
            name={"team"}
            label={"Team"}
          />
          <CustomSelect
            selectValue={connectionDetails?.Speciality}
            setSelectValue={(value) => handleFormChanges("Speciality", value)}
            inputID={"speciality"}
            options={SPECIALITY_TYPES}
            name={"speciality"}
            label={"Speciality"}
          />
          <CustomInput
            inputId={"organisation"}
            inputLabel={"Organisation"}
            inputName={"organisation"}
            inputValue={connectionDetails?.Organisation}
            onChange={(value) => handleFormChanges("Organisation", value)}
          />
          <h1 className="md:col-span-full text-xl font-semibold tracking-wider py-2">
            This person is the key worker for {coreRecordDetails?.FirstName}{" "}
            {coreRecordDetails?.FamilyName}
          </h1>
          <RadioGroup
            value={shouldReassignKeyworker}
            onChange={(value) => setShouldReassignKeyworker(value)}
            className={
              "col-span-full flex p-4 pb-8 gap-10 text-xl text-black lg:col-span-1 lg:justify-between"
            }
          >
            <RadioGroup.Option value="yes">
              {({ checked, active }) => (
                <CustomRadioButton
                  checked={checked}
                  active={active}
                  title="Yes"
                />
              )}
            </RadioGroup.Option>
            <RadioGroup.Option value="no">
              {({ checked, active }) => (
                <CustomRadioButton
                  checked={checked}
                  active={active}
                  title="No"
                />
              )}
            </RadioGroup.Option>
          </RadioGroup>
          {coreRecordDetails?.KeyWorkerId &&
            shouldReassignKeyworker === "yes" &&
            coreRecordDetails?.KeyWorkerId !== connectionDetails?.id && (
              <div className="col-span-full">
                <WarningBanner
                  warningMessage={`${coreRecordDetails?.KeyWorkerDetails?.FirstName} ${coreRecordDetails?.KeyWorkerDetails?.LastName} is already assigned as the key worker for this case. This selection will override that decision`}
                />
              </div>
            )}
          <h1 className="md:col-span-full text-xl font-semibold tracking-wider py-2">
            Contact Details
          </h1>
          <PhoneInput
            inputLabel={"Phone Number"}
            phoneValue={connectionDetails?.PhoneNumber}
            setPhoneValue={(value) => handleFormChanges("PhoneNumber", value)}
            inputRequired={true}
            testId="phone-number"
          />
          <CustomInput
            inputId={"email"}
            inputLabel={"Email"}
            inputName={"email"}
            isRequired={true}
            inputType="email"
            inputValue={connectionDetails?.Email}
            onChange={(value) => handleFormChanges("Email", value)}
          />
          <CustomSelect
            selectValue={connectionDetails?.LocationCodeValue}
            setSelectValue={(value) =>
              handleFormChanges("LocationCodeValue", value)
            }
            inputID={"locationCodeValueSelect"}
            options={[{ label: "1" }]}
            name={"locationCodeValueSelect"}
            label={"Location Code Value"}
          />
          <CustomInput
            inputId={"locationCodeValueInput"}
            inputLabel={"Location Code Value"}
            inputName={"locationCodeValueInput"}
            inputValue={connectionDetails?.LocationCodeValueText}
            onChange={(value) =>
              handleFormChanges("LocationCodeValueText", value)
            }
          />
          <h1 className="md:col-span-full text-xl font-semibold tracking-wider py-2">
            Engagement period
          </h1>
          <CustomInput
            inputId={"startDate"}
            inputLabel={"Start date"}
            inputName={"startDate"}
            inputType="date"
            inputValue={connectionDetails?.StartDate}
            onChange={(value) => handleFormChanges("StartDate", value)}
          />
          <CustomInput
            inputId={"endDate"}
            inputLabel={"End date"}
            inputName={"endDate"}
            inputType="date"
            inputValue={connectionDetails?.EndDate}
            onChange={(value) => handleFormChanges("EndDate", value)}
          />
          <h1 className="md:col-span-full text-xl font-semibold tracking-wider py-2">
            Additional comment
          </h1>
          <div className="col-span-full">
            <CustomRichText
              value={connectionDetails?.AdditionalComment || ""}
              setValue={(value) =>
                handleFormChanges("AdditionalComment", value)
              }
              name={"additionalCommentValue"}
              testId="additional-comment-richtext"
            />
          </div>
          <div className="col-span-full flex flex-col-reverse gap-4 mt-8 md:col-span-2 md:flex-row">
            <div className="w-full hidden md:flex">
              <CustomButton
                type={"button"}
                label={"CANCEL"}
                isLight={true}
                handleClick={handleCancelButton}
              />
            </div>
            <div className="w-full py-2 flex lg:hidden">
              <CustomButton
                type={"button"}
                label={"DELETE CONNECTION"}
                isLight={true}
                handleClick={handleDeleteClick}
              />
            </div>
            <CustomButton type={"submit"} label={"SAVE CHANGES"} />
          </div>
        </form>
      </Wrapper>
      <CustomModal
        open={triggerModal}
        setOpen={setTriggerModal}
        {...modalDetails}
      />
    </>
  );
});
