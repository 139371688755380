import { setLoadingState } from "app/containers/shared";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { takeLatest, put, call, select } from "redux-saga/effects";
import { myWorkActions as actions } from ".";
import { MYWORK_STATUS } from "../constants";

const url = process.env.REACT_APP_COGNITO_USERS_ENDPOINT;
const eformURL = process.env.REACT_APP_COGNITO_EFORM_ENDPOINT;

const getMyWorkDetails = (state) => state.myWork.myWorkDetails;

function* getPublishedWorkflowTemplates({ payload }: { payload }) {
  const getWorkkflowTemplatesURL = `${url}/workflow?published=true`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${payload.idToken.token}`,
    },
  };

  yield call(setLoadingState, true);

  try {
    const data = yield fetch(getWorkkflowTemplatesURL, requestOptions)
      .then((response) => response.json())
      .catch((error) => {
        console.error("There was an error!", error);
        throw error;
      });
    yield put({
      type: actions.getPublishedWorkflowTemplatesSuccess.type,
      payload: { data },
    });
  } catch (error) {
    yield put({
      type: actions.getPublishedWorkflowTemplatesError.type as any,
      payload: { error },
    });
  }

  yield call(setLoadingState, false);
}

function* getPossibleDecisions({ payload }: { payload }) {
  const getWorkkflowTemplatesURL = `${url}/workflow?published=true`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${payload.idToken.token}`,
    },
  };

  yield call(setLoadingState, true);

  const { currentStep } = payload;

  try {
    let data = yield fetch(getWorkkflowTemplatesURL, requestOptions)
      .then((response) => response.json())
      .catch((error) => {
        console.error("There was an error!", error);
        throw error;
      });
    if (currentStep.SelectedDecision) {
      data = data.map((decision) => {
        decision.id === currentStep.SelectedDecision.id
          ? (decision.isSelected = true)
          : (decision.isSelected = false);
        return decision;
      });
    }
    yield put({
      type: actions.getPossibleDecisionsSuccess.type,
      payload: { data },
    });
  } catch (error) {
    yield put({
      type: actions.getPossibleDecisionsError.type as any,
      payload: { error },
    });
  }

  yield call(setLoadingState, false);
}

function* startNewWorkflow({ payload }: { payload }) {
  const startNewWorkkflowURL = `${eformURL}/mywork`;
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${payload.idToken.token}`,
    },
    body: JSON.stringify(payload.data),
  };

  yield call(setLoadingState, true);

  try {
    const data = yield fetch(startNewWorkkflowURL, requestOptions)
      .then((response) => response.json())
      .catch((error) => {
        console.error("There was an error!", error);
        throw error;
      });
    yield put({
      type: actions.startNewWorkflowSuccess.type,
      payload: { data },
    });
  } catch (error) {
    yield put({
      type: actions.startNewWorkflowError.type,
      payload: { error },
    });
  }

  yield call(setLoadingState, false);
}

function* updateMyWork({ payload }: { payload }) {
  const updateMyWorkURL = `${eformURL}/mywork`;
  const currentDate = dayjs().format();

  const myWorkDetails = yield select(getMyWorkDetails);
  const dataWithLastUpdated = {
    ...myWorkDetails,
    LastUpdated: currentDate,
    LastActiveStep: payload.LastActiveStep,
  };

  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${payload.idToken.token}`,
    },
    body: JSON.stringify(dataWithLastUpdated),
  };

  yield call(setLoadingState, true);

  try {
    const data = yield fetch(updateMyWorkURL, requestOptions)
      .then((response) => response.json())
      .catch((error) => {
        console.error("There was an error!", error);
        throw error;
      });
    yield put({
      type: actions.updateMyWorkSuccess.type,
      payload: { data },
    });
    yield put({
      type: actions.updateCollabWorkRequest.type,
      payload: {
        data: {
          id: data.id,
          updateData: {
            LastUpdated: currentDate,
          },
        },
        idToken: payload.idToken,
      },
    });
  } catch (error) {
    yield put({
      type: actions.updateMyWorkError.type,
      payload: { error },
    });
  }

  yield call(setLoadingState, false);
}

function* submitForApprovalMyWork({ payload }: { payload }) {
  const updateMyWorkURL = `${eformURL}/mywork/approve`;
  const currentDate = dayjs().format();

  const myWorkDetails = yield select(getMyWorkDetails);
  const dataWithLastUpdated = {
    ...myWorkDetails,
    LastUpdated: currentDate,
  };

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${payload.idToken.token}`,
    },
    body: JSON.stringify(dataWithLastUpdated),
  };

  yield call(setLoadingState, true);

  try {
    const data = yield fetch(updateMyWorkURL, requestOptions)
      .then((response) => response.json())
      .catch((error) => {
        console.error("There was an error!", error);
        throw error;
      });
    yield put({
      type: actions.submitForApprovalSuccess.type,
      payload: { data },
    });
    yield put({
      type: actions.updateCollabWorkRequest.type,
      payload: {
        data: {
          id: data.id,
          updateData: {
            LastUpdated: currentDate,
            Status: data.Status,
            CoreRecordId: data.CoreRecordId,
            CoreRecordName: data.CoreRecordName,
          },
        },
        idToken: payload.idToken,
      },
    });
  } catch (error) {
    yield put({
      type: actions.updateMyWorkError.type,
      payload: { error },
    });
  }

  yield call(setLoadingState, false);
}

function* approveMyWork({ payload }: { payload }) {
  const updateMyWorkURL = `${eformURL}/mywork/approve`;
  const currentDate = dayjs().format();

  const myWorkDetails = yield select(getMyWorkDetails);

  const dataWithLastUpdated = {
    ...myWorkDetails,
    LastUpdated: currentDate,
  };

  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${payload.idToken.token}`,
    },
    body: JSON.stringify(dataWithLastUpdated),
  };

  yield call(setLoadingState, true);

  try {
    const data = yield fetch(updateMyWorkURL, requestOptions)
      .then((response) => response.json())
      .catch((error) => {
        console.error("There was an error!", error);
        throw error;
      });
    yield put({
      type: actions.approveMyWorkSuccess.type,
      payload: { data },
    });
    yield put({
      type: actions.updateCollabWorkRequest.type,
      payload: {
        data: {
          id: data.id,
          updateData: {
            LastUpdated: currentDate,
            Status: data.Status,
            CoreRecordId: data.CoreRecordId,
            CoreRecordName: data.CoreRecordName,
          },
        },
        idToken: payload.idToken,
      },
    });
  } catch (error) {
    yield put({
      type: actions.approveMyWorkError.type,
      payload: { error },
    });
  }

  yield call(setLoadingState, false);
}
function* rejectMyWork({ payload }: { payload }) {
  const updateMyWorkURL = `${eformURL}/mywork/reject`;
  const currentDate = dayjs().format();

  const myWorkDetails = yield select(getMyWorkDetails);

  const dataWithLastUpdated = {
    ...myWorkDetails,
    LastUpdated: currentDate,
  };

  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${payload.idToken.token}`,
    },
    body: JSON.stringify(dataWithLastUpdated),
  };

  yield call(setLoadingState, true);

  try {
    const data = yield fetch(updateMyWorkURL, requestOptions)
      .then((response) => response.json())
      .catch((error) => {
        console.error("There was an error!", error);
        throw error;
      });
    yield put({
      type: actions.rejectMyWorkSuccess.type,
      payload: { data },
    });
    yield put({
      type: actions.updateCollabWorkRequest.type,
      payload: {
        data: {
          id: data.id,
          updateData: {
            LastUpdated: currentDate,
            Status: data.Status,
            CoreRecordId: data.CoreRecordId,
            CoreRecordName: data.CoreRecordName,
          },
        },
        idToken: payload.idToken,
      },
    });
  } catch (error) {
    yield put({
      type: actions.rejectMyWorkError.type,
      payload: { error },
    });
  }

  yield call(setLoadingState, false);
}

function* updateCollabWork({ payload }: { payload }) {
  const updateMyWorkURL = `${eformURL}/collaborator`;
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${payload.idToken.token}`,
    },
    body: JSON.stringify(payload.data),
  };

  yield call(setLoadingState, true);

  try {
    const data = yield fetch(updateMyWorkURL, requestOptions)
      .then((response) => response.json())
      .catch((error) => {
        console.error("There was an error!", error);
        throw error;
      });
    yield put({
      type: actions.updateCollabWorkSuccess.type,
      payload: { data },
    });
  } catch (error) {
    yield put({
      type: actions.updateCollabWorkError.type,
      payload: { error },
    });
  }

  yield call(setLoadingState, false);
}

function* getMyWork({ payload }: { payload }) {
  const { id, idToken } = payload;
  const getMyWorkURL = `${eformURL}/mywork/${id}`;

  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${idToken.token}`,
    },
  };

  yield call(setLoadingState, true);

  try {
    const data = yield fetch(getMyWorkURL, requestOptions)
      .then((response) => response.json())
      .catch((error) => {
        console.error("There was an error!", error);
        throw error;
      });
    yield put({
      type: actions.getMyWorkSuccess.type,
      payload: { data },
    });
  } catch (error) {
    yield put({
      type: actions.getMyWorkError.type as any,
      payload: { error },
    });
  }

  yield call(setLoadingState, false);
}

function* getAllMyWork({ payload }: { payload }) {
  const { idToken } = payload;
  const getAllMyWorkURL = `${eformURL}/mywork`;

  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${idToken.token}`,
    },
  };

  yield call(setLoadingState, true);

  try {
    const data = yield fetch(getAllMyWorkURL, requestOptions)
      .then((response) => response.json())
      .catch((error) => {
        console.error("There was an error!", error);
        throw error;
      });
    yield put({
      type: actions.getAllMyWorkSuccess.type,
      payload: { data },
    });
  } catch (error) {
    yield put({
      type: actions.getAllMyWorkError.type as any,
      payload: { error },
    });
  }

  yield call(setLoadingState, false);
}

function* completeMyWork({ payload }: { payload }) {
  const completeMyWorkURL = `${eformURL}/mywork/complete`;
  const myWorkDetails = yield select(getMyWorkDetails);

  const statusCompletedData = {
    ...myWorkDetails,
    Status: MYWORK_STATUS.COMPLETED,
    LastActiveStep: payload.LastActiveStep,
  };
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${payload.idToken.token}`,
    },
    body: JSON.stringify(statusCompletedData),
  };

  yield call(setLoadingState, true);

  try {
    const data = yield fetch(completeMyWorkURL, requestOptions)
      .then((response) => response.json())
      .catch((error) => {
        console.error("There was an error!", error);
        throw error;
      });

    yield put({
      type: actions.updateCollabWorkRequest.type,
      payload: {
        data: {
          updateData: {
            Status: MYWORK_STATUS.COMPLETED,
            LastUpdated: dayjs().format(),
          },
          id: statusCompletedData.id,
        },
        idToken: payload.idToken,
      },
    });
    yield put({
      type: actions.completeMyWorkSuccess.type,
      payload: { data },
    });
    if (data.id !== "NFA") {
      toast.success(`Started a new ${data.WorkflowTitle}`, {
        position: "bottom-center",
      });
    }
  } catch (error) {
    yield put({
      type: actions.completeMyWorkError.type,
      payload: { error },
    });
  }

  yield call(setLoadingState, false);
}

function* getMyWorkCoreRecords({
  payload,
}: {
  payload: { idToken: { token: string; expiry: number } };
}) {
  yield call(setLoadingState, true);
  const getCoreRecordsUrl = `${url}/coreRecords`;

  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${payload.idToken?.token}`,
    },
  };
  try {
    const data = yield fetch(getCoreRecordsUrl, requestOptions)
      .then((response) => response.json())
      .catch((error) => {
        console.error("There was an error!", error);
        throw error;
      });

    yield put({
      type: actions.getMyWorkCoreRecordsSuccess.type,
      payload: { data },
    });
  } catch (error) {
    yield put({
      type: actions.getMyWorkCoreRecordsError.type,
      payload: { error },
    });
  }

  yield call(setLoadingState, false);
}

export function* myWorkSaga() {
  yield takeLatest(
    actions.getPublishedWorkflowTemplatesRequest.type as any,
    getPublishedWorkflowTemplates
  );
  yield takeLatest(
    actions.startNewWorkflowRequest.type as any,
    startNewWorkflow
  );
  yield takeLatest(actions.getMyWorkRequest.type as any, getMyWork);
  yield takeLatest(actions.getAllMyWorkRequest.type as any, getAllMyWork);
  yield takeLatest(
    actions.getMyWorkCoreRecordsRequest.type as any,
    getMyWorkCoreRecords
  );
  yield takeLatest(actions.updateMyWorkRequest.type as any, updateMyWork);
  yield takeLatest(actions.completeMyWorkRequest.type as any, completeMyWork);
  yield takeLatest(
    actions.getPossibleDecisionsRequest.type as any,
    getPossibleDecisions
  );
  yield takeLatest(
    actions.updateCollabWorkRequest.type as any,
    updateCollabWork
  );
  yield takeLatest(
    actions.submitForApprovalRequest.type as any,
    submitForApprovalMyWork
  );
  yield takeLatest(actions.approveMyWorkRequest.type as any, approveMyWork);
  yield takeLatest(actions.rejectMyWorkRequest.type as any, rejectMyWork);
}
