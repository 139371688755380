import { createSlice } from "utils/@reduxjs/toolkit";
import { useInjectReducer } from "utils/redux-injectors";
import { IGlobalState } from "./types";

export const initialState: IGlobalState = {
  isLoading: false,
  LoadingText: "Loading...",
};

const slice = createSlice({
  name: "globalState",
  initialState,
  reducers: {
    setLoading(state, { payload }) {
      state.isLoading = payload.isLoading;
      if (payload.LoadingText) {
        state.LoadingText = payload.LoadingText;
      }
      if (!state.isLoading || !payload.LoadingText) {
        state.LoadingText = "Loading...";
      }
    },
  },
});

export const { actions: globalActions, reducer: globalReducers } = slice;

export const useGlobalSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  return { actions: slice.actions };
};
