/**
 *
 * CustomLinkButton
 *
 */
import React, { memo } from "react";

interface Props {
  type: "button" | "submit" | "reset" | undefined;
  label: string;
  handleClick?(event): void;
  isLight?: boolean;
  testId?: string;
  isDisabled?: boolean;
}

export const CustomLinkButton = memo((props: Props) => {
  return (
    <button
      className={`py-3 font-bold rounded-md underline text-xl`}
      style={{ lineHeight: "2rem" }}
      type={props.type}
      onClick={props.handleClick}
      data-testid={props.testId}
      disabled={props.isDisabled}
    >
      {props.label}
    </button>
  );
});
