import { createSlice } from "utils/@reduxjs/toolkit";
import { useInjectReducer, useInjectSaga } from "utils/redux-injectors";
import { coreRecordSaga } from "./saga";
import { ICoreRecordState } from "./types";

export const initialState: ICoreRecordState = { caseNotes: [] };

const slice = createSlice({
  name: "coreRecord",
  initialState,
  reducers: {
    registerCoreRecordRequest(state, { payload }) {
      state.createCoreRecordDetails = payload.coreRecordDetails;
    },
    registerCoreRecordSuccess(state, { payload }) {
      state.coreRecords?.push(payload.coreRecord);
    },
    registerCoreRecordError(state, { payload }) {
      state.error = payload.error;
      payload;
    },
    updateCoreRecordRequest(state, { payload }) {
      state.coreRecordDetails = payload.coreRecordDetails;
    },
    resetCoreRecordState(state) {
      state.coreRecordDetails = undefined;
      state.caseNotes = undefined;
    },
    updateCoreRecordSuccess(state, { payload }) {
      state.coreRecords?.map((record) => {
        if (record.SK === payload.SK) {
          return payload;
        }
        return record;
      });
    },
    updateCoreRecordError(state, { payload }) {
      state.error = payload.error;
      payload;
    },
    updateAboutMe(state, { payload }) {
      if (state.coreRecordDetails?.AboutMe[payload.keyLabel]) {
        if (
          state.coreRecordDetails.AboutMe[payload.keyLabel].value ===
          payload.value
        )
          return;
        state.coreRecordDetails.AboutMe[payload.keyLabel].value = payload.value;
        state.coreRecordDetails.AboutMe[payload.keyLabel].lastUpdatedTime =
          Date.now();
      }
    },
    updateAboutMeLastUpdatedTime(state, { payload }) {
      const { coreRecordDetails } = state;
      coreRecordDetails.AboutMe[payload.questionKey] = {
        ...coreRecordDetails.AboutMe[payload.questionKey],
        lastUpdatedTime: Date.now(),
      };
    },
    removeAboutMeFile(state, { payload }) {
      state.coreRecordDetails.AboutMe[payload.questionKey].files =
        state.coreRecordDetails.AboutMe[payload.questionKey].files.filter(
          (file) => {
            if (file.fileId !== payload.fileId) return file;
          }
        );
      if (state.coreRecordDetails?.AboutMe[payload.questionKey])
        state.coreRecordDetails.AboutMe[payload.questionKey] = {
          ...state.coreRecordDetails.AboutMe[payload.questionKey],
          lastUpdatedTime: Date.now(),
        };
    },
    registerAboutMeRequest(state, { payload }) {
      payload;
    },
    registerAboutMeSuccess(state, { payload }) {
      state.coreRecordDetails.AboutMe = payload.aboutMe;
    },
    registerAboutMeError(state, { payload }) {
      state.error = payload.error;
      payload;
    },
    uploadAboutMeFilesRequest(state, { payload }) {
      payload;
    },
    uploadAboutMeFilesSuccess(state, { payload }) {
      state.coreRecordDetails.AboutMe = payload.AboutMe;
    },
    uploadAboutMeFilesError(state, { payload }) {
      state.error = payload.error;
      payload;
    },
    getCoreRecordRequest(state, { payload }) {
      state;
      payload;
    },
    getCoreRecordSuccess(state, { payload }) {
      state.coreRecordDetails = payload.data;
      const { FirstName, FamilyName } = payload.data;
      state.coreRecordDetails.CoreRecordName = `${FirstName} ${FamilyName}`;
      if (state.coreRecordDetails?.AboutMe) {
        state.coreRecordDetails = {
          ...state.coreRecordDetails,
          AboutMe: {
            likeAbout: { value: "" },
            mostImportantAbout: { value: "" },
            importantToKnowAbout: { value: "" },
            importantToMe: { value: "" },
            emergencyInfo: { value: "" },
            communicationPassport: { value: "" },
            myWellness: { value: "" },
            doAndDont: { value: "" },
            howToSupportMe: { value: "" },
            worthKnowing: { value: "" },
            myCareHistory: { value: "" },
            PK: state.coreRecordDetails.PK,
            SK: state.coreRecordDetails.SK,
            EntityType: state.coreRecordDetails.EntityType,
            SystemRole: state.coreRecordDetails.SystemRole,
            ...state.coreRecordDetails.AboutMe,
          },
        };
      } else {
        state.coreRecordDetails = {
          ...state.coreRecordDetails,
          AboutMe: {
            likeAbout: { value: "" },
            mostImportantAbout: { value: "" },
            importantToKnowAbout: { value: "" },
            importantToMe: { value: "" },
            emergencyInfo: { value: "" },
            communicationPassport: { value: "" },
            myWellness: { value: "" },
            doAndDont: { value: "" },
            howToSupportMe: { value: "" },
            worthKnowing: { value: "" },
            myCareHistory: { value: "" },
            PK: state.coreRecordDetails.PK,
            SK: state.coreRecordDetails.SK,
            EntityType: state.coreRecordDetails.EntityType,
            SystemRole: state.coreRecordDetails.SystemRole,
          },
        };
      }
    },
    loadS3ImageFilesRequest(state, { payload }) {
      payload;
    },
    loadS3ImageFileSuccess(state, { payload }) {
      const { coreRecordDetails } = state;
      if (coreRecordDetails?.AboutMe) {
        coreRecordDetails.AboutMe[payload.questionKey].files =
          coreRecordDetails.AboutMe[payload.questionKey].files.map((file) => {
            if (file.fileId === payload.fileName) {
              return { ...file, src: payload.src };
            }
            return file;
          });
      }
    },
    loadS3ImageFilesError(state, { payload }) {
      payload;
    },
    getCoreRecordError(state, { payload }) {
      state.error = payload.error;
    },
    getAboutMeRequest(state, { payload }) {
      state;
      payload;
    },
    getAboutMeSuccess(state, { payload }) {
      state.coreRecordDetails.AboutMe = [payload.aboutMe];
    },
    getAboutMeError(state, { payload }) {
      state.error = payload.error;
    },
    getCoreRecordsRequest(state, { payload }) {
      payload;
    },
    getCoreRecordsSuccess(state, { payload }) {
      state.coreRecords = payload.data.map((coreRecord) => {
        const Name = `${coreRecord.FirstName ? coreRecord.FirstName : ""} ${
          coreRecord.FamilyName ? coreRecord.FamilyName : ""
        }`;
        return { ...coreRecord, Name };
      });
    },
    getCoreRecordsError(state, { payload }) {
      state.error = payload.error;
    },
    downloadFileRequest(state, { payload }) {
      payload;
    },
    getRelationshipsData(state, { payload }) {
      payload;
    },
    getRelationshipsDataSuccess(state, { payload }) {
      state.relationshipData = payload.data;
    },
    getRelationshipsDataError(state, { payload }) {
      state.error = payload.error;
    },
    addConnectionRequest(state, { payload }) {
      state.connectionDetails = payload.data;
    },
    addConnectionSuccess(state, { payload }) {
      state.coreRecordConnections?.push(payload.data);
      if (payload.IsKeyWorker === "yes") {
        state.coreRecordDetails = {
          ...state.coreRecordDetails,
          KeyWorkerId: payload.data.id,
          KeyWorkerDetails: {
            FirstName: payload.data.FirstName,
            LastName: payload.data.LastName,
          },
        };
      }
    },
    addConnectionError(state, { payload }) {
      state.error = payload.error;
      payload;
    },
    updateConnectionRequest(state, { payload }) {
      payload;
    },
    updateConnectionSuccess(state, { payload }) {
      state.connectionDetails = payload.data;
      if (payload.shouldReassignKeyworker === "yes") {
        state.coreRecordDetails = {
          ...state.coreRecordDetails,
          KeyWorkerId: payload.data.id,
          KeyWorkerDetails: {
            FirstName: payload.data.FirstName,
            LastName: payload.data.LastName,
          },
        };
      }
    },
    updateConnectionError(state, { payload }) {
      state.error = payload.error;
      payload;
    },
    getConnectionRequest(state, { payload }) {
      payload;
    },
    getConnectionSuccess(state, { payload }) {
      state.connectionDetails = payload.data;
    },
    getConnectionError(state, { payload }) {
      state.error = payload.error;
      payload;
    },
    deleteConnectionRequest(state, { payload }) {
      payload;
    },
    deleteConnectionSuccess(state) {
      state;
    },
    deleteConnectionError(state, { payload }) {
      state.error = payload.error;
      payload;
    },
    getProfessionalConnectionsRequest(state, { payload }) {
      state;
      payload;
    },
    getProfessionalConnectionsSuccess(state, { payload }) {
      state.professionalConnections = payload.data;
    },
    getProfessionalConnectionsError(state, { payload }) {
      state.error = payload.error;
      payload;
    },
    updateProfessionalConnectionDetails(state, { payload }) {
      const { key, value } = payload;
      const { connectionDetails } = state;
      if (connectionDetails)
        state.connectionDetails = {
          ...connectionDetails,
          [key]: value,
        };
    },
    getPersonalConnectionsRequest(state, { payload }) {
      state;
      payload;
    },
    getPersonalConnectionsSuccess(state, { payload }) {
      state.personalConnections = payload.data;
    },
    getPersonalConnectionsError(state, { payload }) {
      state.error = payload.error;
      payload;
    },
    createCaseRequest(state, { payload }) {
      state.createCase = payload.data;
    },
    createCaseSuccess(state, { payload }) {
      state.coreRecordDetails.ActiveCaseDetails = payload?.data;
      state.coreRecordDetails.CaseNumber = payload?.data.ActiveCaseNumber;
    },
    createCaseError(state, { payload }) {
      state.error = payload.error;
      payload;
    },
    sendCaseForAllocationRequest(state, { payload }) {
      payload;
    },
    sendCaseForAllocationSuccess(state, { payload }) {
      state.coreRecordDetails.ActiveCaseDetails = payload?.data;
      state.coreRecordDetails.CaseNumber = payload?.data.ActiveCaseNumber;
    },
    sendCaseForAllocationError(state, { payload }) {
      state.error = payload.error;
      payload;
    },
    allocateCaseRequest(state, { payload }) {
      payload;
    },
    allocateCaseSuccess(state, { payload }) {
      state.coreRecordDetails.ActiveCaseDetails = payload?.data;
      state.coreRecordDetails.CaseNumber = payload?.data.ActiveCaseNumber;
    },
    allocateCaseError(state, { payload }) {
      state.error = payload.error;
      payload;
    },
    createCaseNoteRequest(state, { payload }) {
      state.createCase = payload.data;
    },
    createCaseNoteSuccess(state, { payload }) {
      if (state.caseNotes) {
        state.caseNotes.push(payload.data);
      } else {
        state.caseNotes = [payload.data];
      }
    },
    createCaseNoteError(state, { payload }) {
      state.error = payload.error;
      payload;
    },
    getCaseNotesRequest(state, { payload }) {
      state;
      payload;
    },
    getCaseNotesSuccess(state, { payload }) {
      state.caseNotes = payload?.data;
    },
    getCaseNotesError(state, { payload }) {
      state.error = payload.error;
    },
    getSingleCaseFileRequest(state, { payload }) {
      state;
      payload;
    },
    getSingleCaseFileSuccess(state, { payload }) {
      state.caseNoteDetails = payload?.data;
    },
    getSingleCaseFileError(state, { payload }) {
      state.error = payload.error;
    },

    resetCaseNoteState(state) {
      state.caseNoteDetails = undefined;
    },
    updateCaseNoteRequest(state, { payload }) {
      state;
      payload;
    },
    updateCaseNoteSuccess(state, { payload }) {
      state.caseNoteDetails = payload?.data;
    },
    updateCaseNoteError(state, { payload }) {
      state.error = payload.error;
    },
    closeCaseRequest(state, { payload }) {
      state;
      payload;
    },
    closeCaseSuccess(state) {
      state.coreRecordDetails.ActiveCaseDetails = {};
    },
    closeCaseError(state, { payload }) {
      state.error = payload.error;
    },
    setCanGoBack(state, { payload }) {
      state.canGoBack = payload.goBack;
    },
    getCaseFilesRequest(state, { payload }) {
      state;
      payload;
    },
    getCaseFilesSuccess(state, { payload }) {
      state.caseFiles = payload?.data;
    },
    getCaseFilesError(state, { payload }) {
      state.error = payload.error;
    },
  },
});

export const { actions: coreRecordActions, reducer: coreRecordReducers } =
  slice;

export const useCoreRecordSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: coreRecordSaga });
  return { actions: slice.actions };
};
