/**
 *
 * RAGRating
 *
 */
import React, { useEffect, useState } from "react";
import CircledCheckMark from "../icons/CircledCheckMark";
import { LearnMoreModal } from "./RAGLearnMoreModal/Loadable";
import { RAG_COLORS } from "./constants";

interface Props {
  handleClick(event): void;
  inRow?: boolean;
  preselected?: any;
}
export function RagRating(props: Props) {
  const [selected, setSelected] = useState({ name: "", class: "", title: "" });
  const [triggerModal, setTriggerModal] = useState(false);
  const { inRow, preselected } = props;

  const handleColorSelect = (event) => {
    props.handleClick(event);
    setSelected(event);
  };
  useEffect(() => {
    if (preselected) {
      const selectedRAGRating: any = RAG_COLORS.find(
        (color) => color.name === preselected
      );
      setSelected(selectedRAGRating);
    }
  }, [preselected]);

  return (
    <div>
      <div className="w-full flex gap-0">
        {RAG_COLORS.map((color, index) => {
          return (
            <div
              className={`flex justify-center items-center ${color.class} ${
                inRow ? "h-12 w-14" : "h-12 w-32"
              }`}
              key={index}
              onClick={() => handleColorSelect(color)}
            >
              {selected?.name === color.name && <CircledCheckMark />}
            </div>
          );
        })}
      </div>
      {!inRow && (
        <div className="w-full">
          <span className="text-black">{selected.title}</span>
          <span
            className="underline cursor-pointer text-black"
            onClick={() => setTriggerModal(true)}
          >
            Learn More
          </span>
          <LearnMoreModal open={triggerModal} setOpen={setTriggerModal} />
        </div>
      )}
    </div>
  );
}
