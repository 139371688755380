/**
 *
 * PublishedWorkflowsTable
 *
 */
import React, { memo, useEffect } from "react";

import { get as getIn } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useMyWorkSlice } from "../slice";
import { selectAuth } from "app/containers/Auth/slice/selectors";
import { selectMyWork } from "../slice/selectors";
import { Table } from "app/components/Table/Loadable";
import dayjs from "dayjs";
import { CustomButton } from "app/components/UI/Forms/Buttons/CustomButton";
import { WORKFLOW_TYPE_LABELS } from "app/containers/Workflow/constants";

export const PublishedWorkflowsTable = memo(() => {
  const dispatch = useDispatch();
  const { actions: myWorkActions } = useMyWorkSlice();
  const myWorkState = useSelector(selectMyWork);
  const authState = useSelector(selectAuth);
  const publishedWorkflows = getIn(myWorkState, "workflowTemplates", []);

  useEffect(() => {
    dispatch(
      myWorkActions.getPublishedWorkflowTemplatesRequest({
        idToken: authState.session?.idToken,
      })
    );
  }, []);

  const startNewWorkflow = (id, data) => {
    dispatch(
      myWorkActions.startNewWorkflowRequest({
        id,
        data,
        idToken: authState.session?.idToken,
      })
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Event Type",
        accessor: "EventType",
        Cell: ({ row }: any) => {
          const workflowType = getIn(row, "original.WorkflowType");
          const title = WORKFLOW_TYPE_LABELS[workflowType];
          return (
            <p className="flex justify-center items-center text-primary bg-primaryLight rounded-full py-1">
              <span className="text-primary text-sm font-light px-2">
                {title}
              </span>
            </p>
          );
        },
      },
      {
        Header: "Workflow Name",
        accessor: "WorkflowTitle",
      },
      {
        Header: "Description",
        Cell: ({ row }: any) => {
          const workflowDescription = getIn(
            row,
            "original.WorkflowDescription"
          );
          return (
            <div
              className="text-black text-sm font-light"
              style={{
                width: "30ch",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              {workflowDescription}
            </div>
          );
        },
      },
      {
        Header: "Last Updated",
        Cell: ({ row }: any) => {
          const lastUpdated = getIn(row, "original.LastUpdated");
          const createdDate = getIn(row, "original.CreatedDate");
          return (
            <span className="text-black text-sm font-light">
              {lastUpdated
                ? dayjs(lastUpdated).format("DD.MM.YYYY HH:mm")
                : dayjs(createdDate).format("DD.MM.YYYY HH:mm")}
            </span>
          );
        },
      },
      {
        Header: () => null,
        id: "arrowLink",
        Cell: ({ row }: any) => {
          const id = getIn(row, "original.id");
          const data = getIn(row, "original");
          return (
            <form>
              <CustomButton
                handleClick={() => startNewWorkflow(id, data)}
                type={"button"}
                label={"Start a new process"}
              />
            </form>
          );
        },
      },
    ],
    []
  );

  return <Table columns={columns} data={publishedWorkflows} />;
});
