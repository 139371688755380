/**
 *
 * CustomFileUpload
 *
 */
import React from "react";
import { useDropzone } from "react-dropzone";
import { FileUploadIcon } from "app/components/icons";
import { FILE_SIZE_LIMIT } from "common";
import { toast } from "react-toastify";

interface Props {
  setFiles(files);
  filesToUpload: [];
  inputName: string;
  sizeLimit?: number;
  isMultiple?: boolean;
}
export const onDropAccepted = (acceptedFiles, setFiles) => {
  const files = acceptedFiles.map((acceptedFile) => {
    const reader = new FileReader();
    reader.onabort = () => {
      toast.info("File reading aborted", { position: "bottom-center" });
    };
    reader.onerror = () =>
      toast.info("File reading has failed", { position: "bottom-center" });
    reader.onload = () => {
      acceptedFile.src = reader.result;
      setFiles(files);
    };

    reader.readAsDataURL(acceptedFile);

    return acceptedFile;
  });
};

export const onDropRejected = (rejectedFiles) => {
  rejectedFiles.forEach((rejectedFile) => {
    if (rejectedFile.errors[0].code === "file-too-large") {
      toast.info(
        `${rejectedFile.file.name} was rejected due to size exceeding 25MB.`,
        {
          position: "bottom-center",
        }
      );
      return;
    }
    toast.info(rejectedFile.errors[0].message, { position: "bottom-center" });
  });
};

export function CustomFileUpload({ setFiles, inputName, isMultiple }: Props) {
  const { getRootProps, getInputProps } = useDropzone({
    onDropAccepted: (acceptedFiles) => onDropAccepted(acceptedFiles, setFiles),
    onDropRejected,
    maxSize: FILE_SIZE_LIMIT,
    multiple: isMultiple,
  });

  const inputProps = getInputProps();

  return (
    <div
      {...getRootProps({
        className:
          "dropzone flex justify-center items-center cursor-pointer bg-white py-3 hover:bg-primaryLight hover:border-opacity-0 text-primary border border-primary font-bold py-2 px-4 rounded-md",
      })}
    >
      <input {...inputProps} name={inputName} data-testid="file-uploader" />
      <FileUploadIcon className="w-7 h-7" />
      <span className="pl-2 font-semibold text-base"> ATTACH A FILE</span>
    </div>
  );
}
