/**
 *
 * CaseAllocation
 *
 */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectAuth } from "../../../Auth/slice/selectors";
import { get as getIn } from "lodash";
import { Table } from "../../../../components/Table/Loadable";
import { selectAdmin } from "../../../Admin/slice/selectors";
import { useAdminSlice } from "../../../Admin/slice";
import dayjs from "dayjs";
import { CaseAllocationModal } from "../../CasePermission/CaseAllocationModal/Loadable";
import { toast } from "react-toastify";
import { CustomButton } from "../../../../components/UI/Forms/Buttons/CustomButton/Loadable";
import { EditPenNoFill } from "../../../../components/icons";

export function CaseAllocation() {
  const adminState = useSelector(selectAdmin);
  const authState = useSelector(selectAuth);
  const { actions: adminActions } = useAdminSlice();
  const [triggerAllocateCase, setTriggerAllocateCase] = useState(false);
  const [allocateToUser, setAllocateToUser] = useState();
  const [caseForAllocation, setCaseForAllocation] = useState({
    CoreRecordId: undefined,
  });

  const dispatch = useDispatch();
  const cases = getIn(adminState, "activeCases", []);

  const handleAllocateCase = (caseDetails) => {
    setCaseForAllocation(caseDetails);
    setTriggerAllocateCase(true);
  };
  const allocateCase = () => {
    if (!allocateToUser) {
      toast.info("Select a user to allocate it to.", {
        position: "bottom-center",
      });
      return;
    }
    if (caseForAllocation) {
      dispatch(
        adminActions.adminAllocateCaseRequest({
          idToken: authState.session?.idToken,
          data: caseForAllocation,
          crId: caseForAllocation.CoreRecordId,
          allocateToUser,
        })
      );
    }
  };
  const columns = React.useMemo(
    () => [
      {
        Header: "Case ID",
        accessor: "CaseNumber",
      },
      {
        Header: "Status",
        accessor: "AllocationStatus",
        disableGlobalFilter: true,
        filter: (rows, id, filterValue) => {
          return rows.filter(
            (row) => filterValue === "" || row.values[id] === filterValue
          );
        },
        selectFilter: true,
        Cell: ({ row }: any) => {
          const status = getIn(row, "original.AllocationStatus");
          return (
            <div
              className={`px-4 py-1 rounded-3xl table-cell px-2 text-center ${
                status === "Allocated" ? "bg-greenHighlight" : "bg-redHighlight"
              }`}
            >
              <span className="text-white text-sm uppercase">{status}</span>
            </div>
          );
        },
      },
      {
        Header: "Core Record",
        accessor: "CorRecord",
        Cell: ({ row }: any) => {
          const coreRecordDetails = getIn(row, "original.CoreRecordDetails");
          return (
            <div
              className="text-sm text-black"
              style={{
                width: "20ch",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              {coreRecordDetails
                ? `${coreRecordDetails?.FirstName} ${coreRecordDetails?.FamilyName}`
                : ""}
            </div>
          );
        },
      },

      {
        Header: "Created",
        accessor: "CreatedDate",
        Cell: ({ row }: any) => {
          let role = getIn(row, "original.CreatedDate");
          role = dayjs(role).format("DD.MM.YYYY");
          return (
            <div
              className="text-sm text-black"
              style={{
                width: "20ch",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              {role}
            </div>
          );
        },
      },
      {
        Header: "Key Worker",
        accessor: "KeyWorker",
        Cell: ({ row }: any) => {
          const ownerDetails = getIn(row, "original.OwnerDetails");
          const caseDetails = getIn(row, "original");
          return (
            <div
              className="text-sm text-black"
              style={{
                width: "20ch",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              {ownerDetails ? (
                <div className="flex">
                  <span className="flex-1 text-black text-sm">
                    {" "}
                    {ownerDetails?.FirstName} {ownerDetails?.LastName}
                  </span>
                  <span className="cursor-pointer flex-0">
                    {" "}
                    <EditPenNoFill
                      onClick={() => handleAllocateCase(caseDetails)}
                    />
                  </span>
                </div>
              ) : (
                <CustomButton
                  type={"button"}
                  label={"ALLOCATE"}
                  handleClick={() => handleAllocateCase(caseDetails)}
                />
              )}
            </div>
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    dispatch(
      adminActions.getActiveCasesRequest({
        idToken: authState.session?.idToken,
      })
    );
  }, []);

  return (
    <div className="max-w-screen-xl mb-20 px-10 xl:px-0">
      <div className="m-10 ml-0 text-2xl">Case Allocation</div>
      <Table
        columns={columns}
        data={cases}
        searchSettings={{
          showSearchField: true,
          globalSelect: true,
          filterBy: "AllocationStatus",
        }}
      />
      <CaseAllocationModal
        open={triggerAllocateCase}
        setOpen={setTriggerAllocateCase}
        setValue={setAllocateToUser}
        value={allocateToUser}
        allocateCase={allocateCase}
      />
    </div>
  );
}
