import {
  AuthenticationDetails,
  CognitoUser,
  ICognitoUserSessionData,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoUserSession,
} from "amazon-cognito-identity-js";
import Pool from "../UserPool";
import { takeLatest, put, call } from "redux-saga/effects";
import { authActions } from ".";
import { ForgotPasswrodConstants } from "app/containers/Auth/ForgotPassword/constants";
import { setLoadingState } from "app/containers/shared";
import { toast } from "react-toastify";

import {
  ILoginRequestParams,
  IConfirmNewPasswordRequest,
  IForgotPasswordRequest,
  IResetPasswordRequest,
  ISession,
} from "./types";

const userUrl = process.env.REACT_APP_COGNITO_USERS_ENDPOINT;

function* loginUser({ payload }: { payload: ILoginRequestParams }) {
  yield call(setLoadingState, true);
  try {
    const user = new CognitoUser({ Username: payload.Username, Pool });
    const authDetails = new AuthenticationDetails({
      Username: payload.Username,
      Password: payload.Password,
    });
    const authenticate = () => {
      return new Promise<any>((resolve, reject) => {
        user.authenticateUser(authDetails, {
          onSuccess: (data) => {
            resolve(data);
          },

          onFailure: (error) => {
            reject(error);
          },

          newPasswordRequired: (data) => {
            const newData = { data, newPasswordRequried: true };
            resolve(newData);
          },
        });
      });
    };
    const res = yield call(authenticate);

    if (res.newPasswordRequried) {
      yield put({
        type: authActions.openTempPasswordReset.type,
        payload: {
          email: res.data.email,
          attributes: res.data,
        },
      });

      toast.info("You need to reset your temporary password", {
        position: "bottom-center",
      });
    } else {
      const session = yield call(getSession);
      const formattedSession = formatSession(session);
      yield put({
        type: authActions.loginUserSuccess.type,
        payload: {
          session: formattedSession,
          email: session.idToken.payload.email,
          cognitoGroups: session.idToken.payload["cognito:groups"],
        },
      });

      yield put({
        type: authActions.getCurrentUserRequest.type,
        payload: {
          idToken: formattedSession.idToken,
          email: session.idToken.payload.email,
        },
      });

      toast.success("Welcome back!", {
        position: "bottom-center",
      });
    }
    yield call(setLoadingState, false);
  } catch (error) {
    yield put(authActions.loginUserError(error));
    yield call(setLoadingState, false);
  }
}

function* getCurrentUserDBDetails({ payload }: { payload }) {
  yield call(setLoadingState, true);
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${payload.idToken.token}`,
    },
  };

  const getUserURL = (email) =>
    `${userUrl}/currentUser/${encodeURIComponent(email)}`;

  try {
    const user = yield fetch(getUserURL(payload.email), requestOptions)
      .then((response) => response.json())
      .catch((error) => {
        console.error("There was an error!", error);
        throw error;
      });
    const { Teams, ...userRest } = user;
    const mappedTeams = Teams.map((team, index) => {
      team.key = `team${index}`;
      return team;
    });
    const userWithMappedTeams = { ...userRest, Teams: mappedTeams };
    yield put({
      type: authActions.getCurrentUserSuccess.type as any,
      payload: { currentUser: userWithMappedTeams },
    });
  } catch (error) {
    yield put({
      type: authActions.getCurrentUserError.type as any,
      payload: { error },
    });
  }

  yield call(setLoadingState, false);
}

function* checkLogin() {
  yield call(setLoadingState, true);
  let session: CognitoUserSession = yield call(getSession);
  if (session) {
    const idToken: CognitoIdToken = session.getIdToken();
    const idTokenPayload = idToken.decodePayload();
    const idTokenExpiry = idToken.getExpiration() * 1000; //need to add 3 zeros in order to compare with the Date.now()
    const refreshToken: CognitoRefreshToken = session.getRefreshToken();

    if (idTokenExpiry > Date.now()) {
      const formattedSession = formatSession(session);
      yield put({
        type: authActions.loginUserSuccess.type,
        payload: {
          session: formattedSession,
          email: idTokenPayload.email,
          //Quick fix NEED to create User cognito group and add users into that group
          cognitoGroups: idTokenPayload["cognito:groups"] || ["User"],
        },
      });

      yield put({
        type: authActions.getCurrentUserRequest.type,
        payload: {
          idToken: formattedSession.idToken,
          email: idTokenPayload.email,
        },
      });
      toast.info("Welcome back!", {
        position: "bottom-center",
      });
    } else {
      session = refreshSession(idToken.decodePayload().email, refreshToken);
      if (session) {
        const formattedSession = formatSession(session);

        yield put({
          type: authActions.loginUserSuccess.type,
          payload: {
            session: formattedSession,
            email: idTokenPayload.email,
            cognitoGroups: idTokenPayload["cognito:groups"],
          },
        });
        yield put({
          type: authActions.getCurrentUserRequest.type,
          payload: {
            idToken: formattedSession.idToken,
            email: idTokenPayload.email,
          },
        });
        toast.info("Welcome back!", {
          position: "bottom-center",
        });
      } else {
        toast.info("Your logged in session has expired!", {
          position: "bottom-center",
        });
        yield put({
          type: authActions.logoutUserRequest,
          payload: { payload: { email: idTokenPayload.email } },
        });
      }
    }
  }
  yield call(setLoadingState, false);
}

function* logoutUser({
  payload: { payload },
}: {
  payload: { payload: IForgotPasswordRequest };
}) {
  yield call(setLoadingState, true);
  const user = getUser(payload.email);

  const logout = () => {
    user.signOut();
    Promise.resolve();
  };
  yield call(logout);
  yield put({ type: authActions.logoutUserSuccess.type as any });

  toast.success("You've been logged out successfully", {
    position: "bottom-center",
  });

  yield call(setLoadingState, false);
}

function* forgotPasswordRequest({
  payload,
}: {
  payload: IForgotPasswordRequest;
}) {
  yield call(setLoadingState, true);
  try {
    const sendForgotPasswordRequest = () => {
      return new Promise<any>((resolve, reject) =>
        getUser(payload.email).forgotPassword({
          onSuccess: (data) => {
            resolve(data);
          },
          onFailure: (err) => {
            reject(err);
          },
          inputVerificationCode: (data) => {
            resolve(data);
          },
        })
      );
    };

    yield call(sendForgotPasswordRequest);
    yield put({
      type: authActions.forgotPasswordCodeSent.type,
      payload: {
        forgotPasswordStep: ForgotPasswrodConstants.CODE_SENT,
      },
    });

    toast.success("Code has been sent to your email address!", {
      position: "bottom-center",
    });
  } catch (error) {
    yield put({
      type: authActions.forgotPasswordError.type as any,
      payload: { error: error },
    });
  }
  yield call(setLoadingState, false);
}

function* resetPasswordRequest({
  payload,
}: {
  payload: IResetPasswordRequest;
}) {
  yield call(setLoadingState, true);
  try {
    const resetPassword = () => {
      return new Promise<any>((resolve, reject) =>
        getUser(payload.email).confirmPassword(
          payload.confirmationCode,
          payload.newPassword,
          {
            onSuccess: (data) => {
              resolve(data);
            },
            onFailure: (error) => {
              reject(error);
            },
          }
        )
      );
    };
    yield call(resetPassword);
    yield put({
      type: authActions.resetPasswordSuccess.type as any,
      payload: { forgotPasswordStep: ForgotPasswrodConstants.PASSWORD_CHANGED },
    });

    toast.success("Password has been reset successfully!", {
      position: "bottom-center",
    });
  } catch (error) {
    yield put({
      type: authActions.resetPasswordError.type as any,
      payload: {
        error: error,
        forgotPasswordStep: ForgotPasswrodConstants.CODE_SENT,
      },
    });
  }
  yield call(setLoadingState, false);
}

function* completeNewPasswordRequest({
  payload,
}: {
  payload: IConfirmNewPasswordRequest;
}) {
  yield call(setLoadingState, true);
  const authDetails = new AuthenticationDetails({
    Username: payload.email,
    Password: payload.oldPassword,
  });
  const user: CognitoUser = yield call(getUser, payload.email);
  const authenticate = () => {
    return new Promise<any>((resolve, reject) => {
      user.authenticateUser(authDetails, {
        onSuccess: (data) => {
          resolve(data);
        },

        onFailure: (error) => {
          reject(error);
        },

        newPasswordRequired: () => {
          user.completeNewPasswordChallenge(
            payload.newPassword,
            { "userAttributes.name": payload.email },

            {
              onSuccess: function (result) {
                resolve(result);
              },
              onFailure: function (err) {
                reject(err);
              },
            }
          );
        },
      });
    });
  };

  try {
    yield call(authenticate);

    yield put({
      type: authActions.newPasswordSuccess.type,
    });

    toast.success("Your temporary password has been reset!", {
      position: "bottom-center",
    });
  } catch (error) {
    console.error(error);
    yield put({
      type: authActions.resetPasswordError.type as any,
      payload: { error: error },
    });
  }
  yield call(setLoadingState, false);
}
export default function* authSaga() {
  // if necessary, start multiple sagas at once with `all`
  yield takeLatest(authActions.loginUserRequest.type as any, loginUser);
  yield takeLatest(
    authActions.getCurrentUserRequest.type as any,
    getCurrentUserDBDetails
  );
  yield takeLatest(
    authActions.resetPasswordRequest.type as any,
    resetPasswordRequest
  );
  yield takeLatest(
    authActions.forgotPasswordRequest.type as any,
    forgotPasswordRequest
  );
  yield takeLatest(
    authActions.newPasswordRequest.type as any,
    completeNewPasswordRequest
  );
  yield takeLatest(authActions.getSession.type as any, checkLogin);
  yield takeLatest(authActions.logoutUserRequest.type as any, logoutUser);
}

//Helper Functions
const getSession = () => {
  const user = Pool.getCurrentUser();
  if (user) {
    return new Promise<ICognitoUserSessionData>((resolve, reject) => {
      user.getSession((err, session) => {
        if (err) {
          reject(err);
        } else {
          resolve(session);
        }
      });
    });
  }
  return null;
};

const formatSession = (session): ISession => {
  return {
    accessToken: {
      token: session.accessToken.jwtToken,
      expiryTime: session.accessToken.payload.exp,
    },
    idToken: {
      token: session.idToken.jwtToken,
      expiry: session.idToken.payload.exp,
    },
    refreshToken: session.refreshToken.token,
  };
};

const getUser = (email) => {
  return new CognitoUser({
    Username: email.toLowerCase(),
    Pool,
  });
};
const refreshSession = (email: string, refreshToken: CognitoRefreshToken) => {
  const user = getUser(email);
  if (user) {
    user.refreshSession(refreshToken, (err, session) => {
      if (err) {
        throw err;
      }
      return session;
    });
  }
  return new CognitoUserSession({} as ICognitoUserSessionData);
};
