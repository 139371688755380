import { createSlice } from "utils/@reduxjs/toolkit";
import { useInjectReducer, useInjectSaga } from "utils/redux-injectors";
import { myWorkSaga } from "./saga";
import { IMyWorkState } from "./types";
import { get as getIn } from "lodash";

export const initialState: IMyWorkState = {
  loading: false,
};

const slice = createSlice({
  name: "myWork",
  initialState,
  reducers: {
    getPublishedWorkflowTemplatesRequest(state, { payload }) {
      state.loading = true;
      payload;
    },
    getPublishedWorkflowTemplatesSuccess(state, { payload }) {
      state.loading = false;
      state.workflowTemplates = payload.data;
    },
    getPublishedWorkflowTemplatesError(state, { payload }) {
      state.loading = false;
      payload;
    },
    getPossibleDecisionsRequest(state, { payload }) {
      state.loading = true;
      payload;
    },
    getPossibleDecisionsSuccess(state, { payload }) {
      state.loading = false;
      state.possibleDecisions = [
        { WorkflowTitle: "No further action", id: "NFA" },
        ...payload.data,
      ];
    },
    getPossibleDecisionsError(state, { payload }) {
      state.loading = false;
      payload;
    },
    setSelectedDecision(state, { payload }) {
      const { id } = payload;
      state.possibleDecisions = state.possibleDecisions?.map((decision) => {
        decision.id === id
          ? (decision.isSelected = true)
          : (decision.isSelected = false);
        return decision;
      });
    },
    startNewWorkflowRequest(state, { payload }) {
      state.loading = true;
      payload;
    },
    startNewWorkflowSuccess(state, { payload }) {
      state.loading = false;
      payload;
      state.myWorkDetails = payload.data;
    },
    startNewWorkflowError(state, { payload }) {
      state.loading = false;
      payload;
    },
    updateMyWorkRequest(state, { payload }) {
      state.loading = true;
      state.myWorkDetails.LastActiveStep = payload.LastActiveStep;
      payload;
    },
    updateMyWorkSuccess(state, { payload }) {
      state.loading = false;
      payload;
    },
    updateMyWorkError(state, { payload }) {
      state.loading = false;
      payload;
    },
    approveMyWorkRequest(state, { payload }) {
      state.loading = true;
      payload;
    },
    approveMyWorkSuccess(state, { payload }) {
      state.loading = false;
      state.myWorkDetails = payload.data;
    },
    approveMyWorkError(state, { payload }) {
      state.loading = false;
      payload;
    },
    rejectMyWorkRequest(state, { payload }) {
      state.loading = true;
      payload;
    },
    rejectMyWorkSuccess(state, { payload }) {
      state.loading = false;
      state.myWorkDetails = payload.data;
      payload;
    },
    rejectMyWorkError(state, { payload }) {
      state.loading = false;
      payload;
    },
    submitForApprovalRequest(state, { payload }) {
      state.loading = true;
      payload;
    },
    submitForApprovalSuccess(state, { payload }) {
      state.loading = false;
      state.myWorkDetails = payload.data;
      payload;
    },
    submitForApprovalError(state, { payload }) {
      state.loading = false;
      payload;
    },
    completeMyWorkRequest(state, { payload }) {
      state.loading = true;
      payload;
    },
    completeMyWorkSuccess(state, { payload }) {
      const { data } = payload;
      state.loading = false;
      state.nextWorkflowId = data.id;
      if (data.id !== "NFA") {
        state.myWorkDetails = data;
      }
    },
    completeMyWorkError(state, { payload }) {
      state.loading = false;
      payload;
    },
    unsetNextWorkflowId(state) {
      state.nextWorkflowId = undefined;
    },
    updateCollabWorkRequest(state, { payload }) {
      state.loading = true;
      payload;
    },
    updateCollabWorkSuccess(state, { payload }) {
      state.loading = false;
      payload;
    },
    updateCollabWorkError(state, { payload }) {
      state.loading = false;
      payload;
    },
    getMyWorkRequest(state, { payload }) {
      state.loading = true;
      payload;
    },
    getMyWorkSuccess(state, { payload }) {
      state.loading = false;
      payload;
      state.myWorkDetails = payload.data;
    },
    getMyWorkError(state, { payload }) {
      state.loading = false;
      payload;
    },
    getAllMyWorkRequest(state, { payload }) {
      state.loading = true;
      payload;
    },
    getAllMyWorkSuccess(state, { payload }) {
      state.loading = false;
      payload;
      state.myWork = payload.data;
    },
    getAllMyWorkError(state, { payload }) {
      state.loading = false;
      payload;
    },
    getMyWorkCoreRecordsRequest(state, { payload }) {
      payload;
    },
    getMyWorkCoreRecordsSuccess(state, { payload }) {
      state.myWorkCoreRecords = payload.data.map((coreRecord) => {
        const firstName = getIn(coreRecord, "FirstName", "");
        const familyName = getIn(coreRecord, "FamilyName", "");

        const Name = `${firstName} ${familyName}`;
        return { ...coreRecord, Name };
      });
    },
    getMyWorkCoreRecordsError(state, { payload }) {
      state.error = payload.error;
    },
    unsetMyWorkDetails(state) {
      state.myWorkDetails = undefined;
    },
    updateStep(state, { payload }) {
      const { currentStep, key, value } = payload;

      if (state.myWorkDetails?.Steps)
        state.myWorkDetails.Steps[currentStep] = {
          ...state.myWorkDetails.Steps[currentStep],
          [key]: value,
        };
    },
    updateMyWorkDetails(state, { payload }) {
      const { key, value } = payload;

      if (state.myWorkDetails?.Steps)
        state.myWorkDetails = { ...state.myWorkDetails, [key]: value };
    },
    updateFormValues(state, { payload }) {
      const { currentStep, key, value } = payload;
      state.myWorkDetails.Steps[currentStep].formValues = {
        ...state.myWorkDetails.Steps[currentStep]?.formValues,
        [key]: value,
      };
    },
    updateMyWorkCoreRecord(state, { payload }) {
      const { Name, id } = payload;
      state.myWorkDetails = {
        ...state.myWorkDetails,
        CoreRecordName: Name,
        CoreRecordId: id,
      };
    },
    setConnectedCoreRecord(state, { payload }) {
      const { id } = payload;
      state.myWorkCoreRecords = state.myWorkCoreRecords?.map((coreRecord) => {
        coreRecord.id === id
          ? (coreRecord.isSelected = true)
          : (coreRecord.isSelected = false);
        return coreRecord;
      });
    },
    setMyWorkSelectedDecision(state, { payload }) {
      const { value } = payload;

      if (state.myWorkDetails) {
        state.myWorkDetails.SelectedDecision = value;
      }
    },
  },
});

export const { actions: myWorkActions } = slice;

export const useMyWorkSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: myWorkSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useMyWorkSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
