/**
 *
 * CarePlanningNote
 *
 */
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { selectAuth } from "app/containers/Auth/slice/selectors";
import { useSelector, useDispatch } from "react-redux";
import { Prompt, useHistory, useParams } from "react-router-dom";
import { useCoreRecordSlice } from "../../slice";
import { selectCoreRecord } from "../../slice/selectors";
import { ArrowBackIcon } from "app/components/icons";
import { CoreRecoridInfoDetails } from "app/components/CoreRecoridInfoDetails/Loadable";
import { CustomButton } from "app/components/UI/Forms/Buttons/CustomButton/Loadable";
import { CustomInput } from "app/components/UI/Forms/Inputs/CustomInput/Loadable";
import { CustomRichText } from "app/components/UI/Forms/Inputs/CustomRichText/Loadable";
import { RagRating } from "../../../../components/RAGRating";
import { get as getIn } from "lodash";
import dayjs from "dayjs";
import { CustomFileUploadInput } from "app/components/CustomFileUploadInput/Loadable";
import { UploadedFileComponentWithDelete } from "app/components/UploadedFileComponentWithDelete/Loadable";
import { CARE_PLANING, CASE_NOTES_TYPES_MAP } from "../constants";
import { IRouteParams, NoteTypeProps } from "../types";

export function CarePlanningCaseNote(props: NoteTypeProps): JSX.Element {
  const { actions: coreRecordActions } = useCoreRecordSlice();
  const coreRecordState = useSelector(selectCoreRecord);
  const authState = useSelector(selectAuth);
  const dispatch = useDispatch();
  const currentUser = getIn(authState, "currentUser", {});
  const { canGoBack } = coreRecordState;
  const id = useParams<IRouteParams>().id || props.id;
  const [isBlocking, setIsBlocking] = useState(false);
  const [headline, setHeadLine] = useState("");
  const [ragRating, setRagRating] = useState("");
  const [regardingPlan, setRegardingPlan] = useState("");
  const [noteText, setNoteText] = useState("");
  const [filesToUpload, setFilesToUpload] = useState([] as any);
  const { coreRecordDetails } = coreRecordState;
  const createdBy = `${currentUser.FirstName} ${currentUser.LastName}`;
  const createdDate = dayjs().format("DD.MM.YYYY HH:MM");
  const { handleSubmit } = useForm();
  const activeCaseFile = getIn(coreRecordDetails, "ActiveCaseDetails", {});
  const { shortcutNote } = props;
  const history = useHistory();

  useEffect(() => {
    dispatch(
      coreRecordActions.getCoreRecordRequest({
        partitionKey: id,
        idToken: authState.session?.idToken,
      })
    );

    return () => {
      if (!shortcutNote) {
        dispatch(coreRecordActions.resetCoreRecordState());
      }
    };
  }, []);
  const colorRaitingSelect = (event) => {
    setRagRating(event.name);
  };
  const submitHandler = () => {
    const caseNote = {
      HeadLine: headline,
      CaseNumber: activeCaseFile.CaseNumber,
      RegardingPlan: regardingPlan,
      NoteText: noteText,
      RagRating: ragRating,
      DocumentType: CARE_PLANING,
      CreatorName: createdBy,
      CreatedDate: createdDate,
      CaseId: activeCaseFile.id,
      OwnerId: coreRecordDetails.OwnerId,
      CoreRecordName: coreRecordDetails.CoreRecordName,
    };
    if (caseNote.CaseId) {
      dispatch(
        coreRecordActions.createCaseNoteRequest({
          idToken: authState.session?.idToken,
          data: caseNote,
          crId: coreRecordDetails.id,
          filesToUpload,
        })
      );
    }
    setIsBlocking(false);
  };

  const handleCancelClick = () => {
    shortcutNote ? props.handleCancel(false) : history.goBack();
  };
  const handleDeleteClick = (index) => {
    setFilesToUpload((prevArr) =>
      prevArr?.filter((file, removeIndex) => index !== removeIndex && file)
    );
  };

  useEffect(() => {
    if (canGoBack) {
      if (shortcutNote) {
        props.handleCancel(false);
      } else {
        history.goBack();
      }
    }
    return () => {
      dispatch(coreRecordActions.setCanGoBack({ goBack: false }));
    };
  }, [canGoBack]);

  return (
    <>
      {!shortcutNote && (
        <div className="hidden w-full lg:block">
          <CoreRecoridInfoDetails {...coreRecordDetails} />
        </div>
      )}
      <div
        className={`flex flex-col items-start w-full ${
          !shortcutNote && "lg:w-3/4 mt-12 px-10 xl:px-0"
        }`}
      >
        <div className="flex flex-col mb-4 w-full gap-4 mt-6 md:mb-10 lg:flex-row lg:items-center">
          {!shortcutNote && (
            <div
              className="flex items-center gap-4"
              onClick={handleCancelClick}
            >
              <ArrowBackIcon
                className="w-10 h-10 cursor-pointer"
                data-testid="draft-back-button"
              />
              <span className="text-xl font-semibold lg:hidden">BACK</span>
            </div>
          )}
          <h1 className=" tracking-wider w-full text-xl md:text-2xl font-semibold">
            Add a new care planning note
            {shortcutNote &&
              coreRecordDetails &&
              ` for ${coreRecordDetails.FirstName}`}
          </h1>
        </div>
        {!shortcutNote && (
          <div className="inline-flex gap-5 mb-10 text-sm">
            <div>
              <span className="text-secondaryText">Note type:</span>{" "}
              <span>{CASE_NOTES_TYPES_MAP[CARE_PLANING]?.title}</span>
            </div>
            <div>
              <span className="text-secondaryText">Created Date:</span>{" "}
              <span>{createdDate}</span>
            </div>
            <div>
              <span className="text-secondaryText">Created By:</span>{" "}
              <span>{createdBy}</span>
            </div>
          </div>
        )}
        <form
          className={`w-full grid grid-cols-1 grid-flow-row gap-4 mb-10 mr-10 2xl:mx-0 `}
          onSubmit={handleSubmit(submitHandler)}
          onChange={() => setIsBlocking(true)}
          data-testid="form"
        >
          <div className="grid grid-cols-1 gap-4 md:grid-cols-1 lg:grid-cols-1">
            <span>
              <CustomInput
                inputId="headline"
                inputName="headline"
                inputLabel="Headline"
                inputType="text"
                isRequired={false}
                onChange={(value) => setHeadLine(value)}
                inputValue={headline}
                maxLength={200}
              />
              <span className="text-sm text-secondaryText">
                Give the note a quick and meaningful description of the purpose
                or main content of what you want to record (200 characters)
              </span>
            </span>
          </div>
          <div className={" grid grid-cols-1 gap-4 md:grid-cols-2"}>
            <CustomInput
              inputId="regardingPlan"
              inputName="regardingPlan"
              inputLabel="Regarding Plan"
              inputType="text"
              isRequired={false}
              onChange={(value) => setRegardingPlan(value)}
              inputValue={regardingPlan}
            />
            <CustomFileUploadInput
              filesToUpload={filesToUpload}
              setFiles={(file) =>
                setFilesToUpload((prevArr) => [...prevArr, file])
              }
              inputName="fileUploader"
              isMultiple={true}
            />
          </div>
          <div
            className={" grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3"}
          >
            {filesToUpload &&
              filesToUpload.map((file, index) => {
                return (
                  <UploadedFileComponentWithDelete
                    key={`${file.name}-${index}`}
                    file={file}
                    handleDeleteIconClick={() => handleDeleteClick(index)}
                  />
                );
              })}
          </div>
          <div className="grid grid-cols-1 gap-4 md:grid-cols-1 lg:grid-cols-1">
            <CustomRichText
              value={noteText}
              setValue={setNoteText}
              name={"noteText"}
              testId={`noteText-rich-text`}
            />
          </div>
          <div
            className={`w-full flex  ${
              shortcutNote
                ? "flex-row justify-between items-center"
                : "flex-col"
            }`}
          >
            <span>Significance of this event in life of the person:</span>
            <RagRating handleClick={colorRaitingSelect} inRow={shortcutNote} />
          </div>
          <div
            className={
              "flex gap-4 flex-col-reverse md:flex-row md:items-center md:w-2/3"
            }
          >
            <CustomButton
              type="button"
              label={shortcutNote ? "CLOSE & CANCEL" : "CANCEL"}
              handleClick={handleCancelClick}
              isLight={true}
              testId="modal-cancel-button"
            />
            <CustomButton type="submit" label={"SAVE NOTE"} />
          </div>
        </form>
      </div>
      <Prompt when={isBlocking} message={""} />
    </>
  );
}
