/**
 *
 * CustomSelect
 *
 */
import React from "react";
import Select from "react-select";
interface Props {
  selectValue: any;
  setSelectValue(data);
  inputID: string;
  options: any[];
  name: string;
  label: string;
  isMulti?: boolean;
  inputRef?: any;
  isRequired?: boolean;
  isSearchable?: boolean;
  isClearable?: boolean;
  customOptionClass?: any;
  customMenuClass?: any;
  customSingleValueClass?: any;
  customValueContainerClass?: any;
  customIndicatorClass?: any;
  onChange?(value);
  formatOptionLabel?: any;
}

export function CustomSelect(props: Props) {
  const {
    selectValue,
    setSelectValue,
    isRequired,
    inputRef,
    isMulti,
    inputID,
    label,
    name,
    options,
    customOptionClass,
    customMenuClass,
    customValueContainerClass,
    customIndicatorClass,
    customSingleValueClass,
    isSearchable = true,
    isClearable = true,
    formatOptionLabel,
  } = props;
  function onSelectChange(data) {
    if (props.onChange) {
      props.onChange(data);
    }
    setSelectValue(data ? data : "");
  }
  return (
    <div className="w-full flex items-center outline relative rounded-md border-2 border-gray-200 focus-within:border-2 focus-within:border-blue-500">
      <Select
        ref={inputRef}
        className="w-full rounded-md text-base text-black border-none focus:outline-none focus:border-none pb-0"
        name={name}
        inputId={inputID}
        options={options}
        isSearchable={isSearchable}
        isClearable={isClearable}
        placeholder=" "
        styles={{
          option: (provided, state) => ({
            ...provided,
            ...customOptionClass,
            opacity:
              state.isSelected && customOptionClass?.selected ? "50%" : "0px",
          }),
          menu: (provided) => ({
            ...provided,
            ...customMenuClass,
          }),
          control: (provided) => ({
            ...provided,
            borderRadius: "0.375rem",
            border: "none",
            "border:active": "none",
          }),
          valueContainer: (provided) => ({
            ...provided,
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
            overflow: "visible",
            border: "none",
            paddingTop: "0.9rem",
            paddingLeft: "1rem",
            ...customValueContainerClass,
          }),
          singleValue: (provided) => ({
            ...provided,
            lineHeight: "2",
            maxWidth: "22ch",
            height: "24px",
            ...customSingleValueClass,
          }),
          input: (provided) => ({
            ...provided,
            border: "none",
            outline: "none",
          }),
          multiValue: (styles) => ({
            ...styles,
            backgroundColor: "#C5CCE4",
            borderRadius: "99999px",
            padding: "2px 6px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "0.5rem",
          }),
          multiValueLabel: (styles) => ({
            ...styles,
            color: "#133092",
          }),
          multiValueRemove: (styles) => ({
            ...styles,
            borderRadius: "100%",
            background: "#F1F3F8",
            width: "1.5rem",
            height: "1.5rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            ":hover": {
              background: "white",
            },
          }),
          indicatorSeparator: (styles) => ({
            ...styles,
            ...customIndicatorClass,
          }),
        }}
        onChange={onSelectChange}
        value={selectValue}
        isMulti={isMulti}
        formatOptionLabel={formatOptionLabel}
      />
      <label
        className={`absolute pl-4 text-base text-secondaryText duration-300 ${
          selectValue ? "has-value" : ""
        }`}
        htmlFor={inputID}
      >
        {label}
        {isRequired && "*"}
      </label>
    </div>
  );
}
