/**
 *
 * CustomDropDownMenu
 *
 */
import React, { memo, ReactNode } from "react";
import { Menu, Transition } from "@headlessui/react";
import { MoreVertIcon } from "../icons";

export interface IDropDownItems {
  text: string;
  handleClick();
  icon?: ReactNode;
}

interface Props {
  menuItems: IDropDownItems[];
  menuButtonElement?: any;
}

export const CustomDropDownMenu = memo(
  ({ menuItems, menuButtonElement }: Props) => {
    return (
      <Menu>
        {({ open }) => (
          <div className="relative">
            <Menu.Button data-testid="drop-down-button">
              {menuButtonElement ? (
                menuButtonElement
              ) : (
                <div className="p-2 rounded hover:bg-primaryLight">
                  <MoreVertIcon className="h-6 w-6" />
                </div>
              )}
            </Menu.Button>

            {/* Use the Transition component. */}
            <Transition
              show={open}
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              {/* Mark this component as `static` */}
              <Menu.Items
                className="absolute right-0 w-56 mt-1 p-1 origin-top-right bg-white divide-y divide-gray-100 rounded shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                static
              >
                {menuItems.map((item, index) => (
                  <Menu.Item key={`item_${index}`}>
                    <div
                      className="p-2 rounded hover:bg-primaryLight cursor-pointer"
                      onClick={item.handleClick}
                    >
                      {item.text}
                    </div>
                  </Menu.Item>
                ))}
              </Menu.Items>
            </Transition>
          </div>
        )}
      </Menu>
    );
  }
);
