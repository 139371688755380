/**
 *
 * ViewCoreRecord
 *
 */
import SlimCardsGrid, { IGridData } from "app/components/SlimCardsGrid";
import { Wrapper } from "app/components/UI/Layout/Wrapper";
import { selectAuth } from "app/containers/Auth/slice/selectors";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, useRouteMatch } from "react-router-dom";
import { get as getIn } from "lodash";
import dayjs from "dayjs";
import { isMobile } from "react-device-detect";
import { InfoBanner } from "../../../../components/InfoBanner";
import { selectCoreRecord } from "../../slice/selectors";
import { useCoreRecordSlice } from "../../slice";
import { CustomButtonSelect } from "../../../../components/CustomButtonSelect";
import { ShortcutNotes } from "../../CaseNotes/ShortcutNotes";
import { AllocateModalProps, OpenCaseModal } from "../../Case/OpenCaseModal";

interface IRouteParams {
  id: string;
}
export const selectOptions = [
  { title: "Care Planning Note", value: "care-planning" },
  { title: "Communication Note", value: "communication" },
  { title: "General Note", value: "general" },
  { title: "Online Meeting Note", value: "online-meeting" },
  { title: "Safeguarding Note", value: "safeguarding" },
  { title: "Visit Note", value: "visit" },
];

export function ViewCoreRecord(): JSX.Element {
  const dispatch = useDispatch();
  const authState = useSelector(selectAuth);
  const currentUser = getIn(authState, "currentUser", {});
  const { actions: coreRecordActions } = useCoreRecordSlice();
  const coreRecordState = useSelector(selectCoreRecord);
  const { id } = useParams<IRouteParams>();
  const match = useRouteMatch();
  const coreRecordDetails = getIn(coreRecordState, "coreRecordDetails", []);
  const activeCase = getIn(coreRecordDetails, "ActiveCaseDetails", {});
  const [openNoteShorts, setOpenNoteShorts] = useState(false);
  const [noteType, setNoteType] = useState("");
  const [allocateModalProps, setAllocateModalProps] = useState(
    {} as AllocateModalProps
  );
  const allocationDetails = useRef({});
  const [triggerAllocateModal, setTriggerAllocateModal] = useState(false);

  useEffect(() => {
    dispatch(
      coreRecordActions.getCoreRecordRequest({
        partitionKey: id,
        idToken: authState?.session?.idToken,
      })
    );
    return () => {
      dispatch(coreRecordActions.resetCoreRecordState());
    };
  }, []);

  const openCase = () => {
    setAllocateModalProps({
      title: `Open a case file`,
      message: `You are about to open a case file for ${coreRecordDetails.FirstName}, please choose from the following options`,
      leftButtonNote: `(The case file will be allocated to You for handling)`,
      rightButtonNote: `(The case file will be sent to a Manager for allocation.)`,
      positiveButtonText: "CREATE A CASE FILE",
      handleLeftClick: () => handleCaseAllocation(true),
      handleRightClick: () => handleCaseAllocation(false),
      handlePositiveClick: () => handleOpenCase(),
    });
    handleCaseAllocation(true);
    setTriggerAllocateModal(true);
  };

  const handleCaseAllocation = (allocateToMe) => {
    let allocationData = {};
    const { id, FirstName, LastName, EmailAddress, PhoneNumber } = currentUser;
    if (allocateToMe) {
      allocationData = {
        OwnerId: id,
        OwnerDetails: { id, FirstName, LastName, EmailAddress, PhoneNumber },
        Status: "Allocated",
        IsAllocated: "1",
        PendingAllocation: undefined,
      };
    } else {
      allocationData = {
        Status: "PendingAllocation",
        IsAllocated: "0",
        PendingAllocation: true,
      };
    }
    allocationDetails.current = allocationData;
  };

  const handleOpenCase = () => {
    const data = {
      ...allocationDetails.current,
      responsible: `${currentUser.FirstName} ${currentUser.LastName}`,
      caseNumber: coreRecordDetails.CaseNumber || 0,
    };
    dispatch(
      coreRecordActions.createCaseRequest({
        idToken: authState.session?.idToken,
        data,
        crId: coreRecordDetails.id,
      })
    );
  };

  const selectedOption = (event) => {
    setNoteType(event.value);
    setOpenNoteShorts(true);
  };
  const imageURL = getIn(
    coreRecordState,
    "coreRecordDetails.SignedImageData.url",
    ""
  );
  return (
    <Wrapper>
      {activeCase?.id ? (
        !isMobile && (
          <div className="flex text-sm text-secondaryText">
            <span className="pr-5 text-secondaryText">
              {`Active case: ${activeCase.CaseNumber}`}
            </span>
            <span className="pr-5 text-secondaryText">
              Date Opened:{" "}
              {dayjs(activeCase.CreatedDate).format("DD.MM.YY HH:mm")}
            </span>
            <span className="text-secondaryText">
              Responsible:{" "}
              {activeCase?.OwnerDetails?.FirstName || activeCase.CreatorName}{" "}
              {activeCase?.OwnerDetails?.LastName || ""}
            </span>
          </div>
        )
      ) : (
        <InfoBanner
          match={match}
          icon={infoBannerData.icon}
          text={infoBannerData.text}
          buttonName={infoBannerData.linkName}
          handleClick={openCase}
        />
      )}

      <div className="flex flex-col items-center md:items-start md:flex-row mt-10">
        <div
          className={`md:px-10 flex bg-cover bg-center justify-center items-center  border-4 border-primary text-white md:mr-6 w-28 h-28 rounded-full`}
          style={{
            backgroundImage: `url(${imageURL})`,
          }}
        />
        <div className="md:inline-flex w-full">
          <div className="flex flex-1 flex-col">
            <h1 className="text-center md:text-left md:text-3xl text-2xl tracking-widest md:mb-3 font-semibold">
              {coreRecordState.coreRecordDetails?.FirstName}{" "}
              {coreRecordState.coreRecordDetails?.FamilyName}
              <Link to={`/core-record/manage/${id}/edit`}>
                <i className="material-icons-round text-xl">edit</i>
              </Link>
              {activeCase?.id && isMobile && (
                <div>
                  <span className="pr-5 text-secondaryText text-sm font-normal leading-9">
                    {`Active case: ${activeCase.CaseNumber}`}
                  </span>
                </div>
              )}
            </h1>
            <table className="table-auto text-left hidden md:w-1/2 md:block">
              <tbody>
                <tr>
                  <th className="py-1 text-secondaryText font-normal text-sm">
                    Prefered Name:{" "}
                  </th>
                  <td className="font-bold text-sm pl-2">
                    {coreRecordState.coreRecordDetails?.PreferredName}
                  </td>
                </tr>
                <tr>
                  <th className="py-1.5 text-secondaryText font-normal text-sm">
                    Gender:
                  </th>
                  <td className="font-bold text-sm pl-2">
                    {coreRecordState.coreRecordDetails?.Gender?.label ||
                      coreRecordState.coreRecordDetails?.Gender}
                  </td>
                </tr>
                <tr>
                  <th className="py-1.5 text-secondaryText font-normal text-sm">
                    Ethnicity:{" "}
                  </th>
                  <td className="font-bold text-sm pl-2">
                    {coreRecordState.coreRecordDetails?.Ethnicity?.label ||
                      coreRecordState.coreRecordDetails?.Ethnicity}
                  </td>
                </tr>
                <tr>
                  <th className="py-1.5 text-secondaryText font-normal text-sm">
                    NHS Number:
                  </th>
                  <td className="font-bold text-sm pl-2">
                    {coreRecordState.coreRecordDetails?.Nhs}
                  </td>
                </tr>
                <tr>
                  <th className="py-1.5 text-secondaryText font-normal text-sm">
                    System ID:
                  </th>
                  <td className="font-bold text-sm pl-2">
                    {coreRecordState.coreRecordDetails?.id}
                  </td>
                </tr>
                <tr>
                  <th className="py-1.5 text-secondaryText font-normal text-sm">
                    Birth Date:{" "}
                  </th>
                  <td className="font-bold text-sm pl-2">
                    {coreRecordState.coreRecordDetails?.DateOfBirth}
                  </td>
                </tr>
                <tr>
                  <th className="py-1.5 text-secondaryText font-normal text-sm">
                    Phone Number:{" "}
                  </th>
                  <td className="font-bold text-sm pl-2">
                    {coreRecordState.coreRecordDetails?.PhoneNumber}
                  </td>
                </tr>
                <tr>
                  <th className="py-1.5 text-secondaryText font-normal text-sm">
                    Email Address:{" "}
                  </th>
                  <td className="font-bold text-sm pl-2">
                    {coreRecordState.coreRecordDetails?.Email}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="md:w-96">
            <CustomButtonSelect
              buttonTitle={"Add a case note"}
              selectOptions={selectOptions}
              handleClick={selectedOption}
            />
          </div>
        </div>
      </div>
      <SlimCardsGrid
        gridData={
          activeCase.id ? [...gridData, casePermisionCardData] : gridData
        }
        headTitle={""}
        match={match}
      />
      <OpenCaseModal
        open={triggerAllocateModal}
        setOpen={setTriggerAllocateModal}
        {...allocateModalProps}
      />
      {openNoteShorts && (
        <ShortcutNotes
          open={openNoteShorts}
          setOpen={setOpenNoteShorts}
          noteType={noteType}
        />
      )}
    </Wrapper>
  );
}

const gridData: IGridData[] = [
  {
    linkTo: "/about",
    slimCardData: {
      cardTitle: "About Me",
      cardSubtitle: "Enter information about yourself",
      LeftIcon: (
        <i className="material-icons-outlined text-3xl md:text-5xl">info</i>
      ),
    },
  },
  {
    linkTo: "/case-files",
    slimCardData: {
      cardTitle: "My Case Files",
      cardSubtitle: "Check your case files",
      LeftIcon: (
        <i className="material-icons-outlined text-3xl md:text-5xl">topic</i>
      ),
    },
  },
  {
    linkTo: "/case-notes",
    slimCardData: {
      cardTitle: "My Case notes",
      cardSubtitle: "Check your case notes",
      LeftIcon: (
        <i className="material-icons-outlined text-3xl md:text-5xl">
          text_snippet
        </i>
      ),
    },
  },
  {
    linkTo: "/connections",
    slimCardData: {
      cardTitle: "My connections",
      cardSubtitle: "View my personal and professional connections",
      LeftIcon: (
        <i className="material-icons-outlined text-3xl md:text-5xl">groups</i>
      ),
    },
  },
];

const casePermisionCardData: IGridData = {
  linkTo: "/permission",
  slimCardData: {
    cardTitle: "Case allocation and permission management",
    cardSubtitle: "Manage permissions for the current active case",
    LeftIcon: (
      <i className="material-icons-round text-3xl md:text-5xl text-redHighlight">
        admin_panel_settings
      </i>
    ),
    textColor: "text-redHighlight",
  },
};

const infoBannerData = {
  icon: <i className="material-icons-outlined text-4xl">warning</i>,
  text: "There is currently no active case file for this core record.",
  linkName: "Open a Case File",
  linkPath: "/case-files",
};
