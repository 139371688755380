/**
 *
 * CustomRadioButton
 *
 */
import React, { memo } from "react";

interface Props {
  checked: boolean;
  active?: boolean;
  title?: string;
  subtitle?: string;
  handleClick?();
  testId?: string;
}

export const CustomRadioButton = memo(
  ({ checked, active, title, subtitle, handleClick, testId }: Props) => {
    return (
      <div
        className="flex gap-8 items-center"
        onClick={handleClick}
        data-testid={testId}
      >
        <span
          className={`h-8 w-8 rounded-full border-4 flex items-center justify-center border-primary
${active ? "border-4" : ""}
`}
          aria-hidden="true"
        >
          <span className={`${checked && "rounded-full bg-primary w-4 h-4"}`} />
        </span>
        {title && (
          <div className="flex flex-col">
            <span className="font-semibold">{title}</span>
            {subtitle && (
              <span className="text-sm text-secondaryText">{subtitle}</span>
            )}
          </div>
        )}
      </div>
    );
  }
);
