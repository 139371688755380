/**
 *
 * SideMenu
 *
 */
import React, { memo } from "react";
// import { Item } from "../Item";

interface IProps {
  items: any;
  handleDragStart?(event);
  handleDragEnd?(event);
}

export const SideBar = memo(({ items, handleDragStart }: IProps) => {
  return (
    <div className="border fixed top-16 pt-16 bg-white shadow-xl bottom-0 right-12 flex">
      <div className="w-screen max-w-sm">
        <div className="h-full flex flex-col overflow-hidden">
          <div className="px-4 sm:px-6">
            <div className="flex items-center justify-between">
              <div className="flex-1 text-center text-xl text-primary font-medium">
                Form Builder
              </div>
            </div>
          </div>
          <div
            className="flex flex-col gap-1 mt-6 relative flex-1 px-4 sm:px-6"
            data-testid="toolbox-items"
          >
            {items.map((item, index) => (
              <div
                className="flex rounded-l-md"
                draggable={true}
                key={`draggable${index}`}
                onDragStart={handleDragStart}
                onDragEnd={handleDragStart}
                id={item.id}
              >
                {item.content}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
});
