/* eslint-disable react/prop-types */
import React from "react";
import { classNames } from "../Utils";

export function Button({ children, className, ...rest }) {
  return (
    <button
      type="button"
      className={classNames(
        "relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50",
        className
      )}
      {...rest}
    >
      {children}
    </button>
  );
}

export function PaginationButton({ children, isActive, ...rest }) {
  return (
    <button
      type="button"
      className={classNames(
        "z-10 bg-indigo-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium",
        isActive && "border-indigo-500 text-indigo-600"
      )}
      {...rest}
    >
      {children}
    </button>
  );
}

export function PageButton({ children, className = "", ...rest }) {
  return (
    <button
      type="button"
      className={classNames(
        "relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50",
        className
      )}
      {...rest}
    >
      {children}
    </button>
  );
}
