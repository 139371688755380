/**
 *
 * SlimCard
 *
 */
import React from "react";
import PropTypes from "prop-types";
interface Props {
  cardTitle: string;
  cardSubtitle: string;
  children?: PropTypes.ReactNodeLike;
  textColor?: string;
  arrowColor?: string;
}

const SlimCard = (props: Props) => {
  return (
    <div className="flex text-left gap-2 md:gap-8 px-4 py-4 items-center justify-center rounded-md shadow-md bg-white md:p-8 cursor-pointer hover:shadow-lg">
      {props.children}
      <div className="flex flex-col justify-between flex-1 gap-1">
        <h1
          className={`text-base md:text-base font-semibold tracking-wider ${props.textColor}`}
        >
          {props.cardTitle}
        </h1>
        <h2 className={`text-sm text-secondaryText md:text-l font-light`}>
          {props.cardSubtitle}
        </h2>
      </div>
      <i
        className="material-icons-round text-5xl"
        data-testid="forward-arrow-icon"
      >
        arrow_forward
      </i>
    </div>
  );
};
export default SlimCard;
