/**
 *
 * WarningBanner
 *
 */
import React, { memo } from "react";

interface Props {
  warningMessage: string;
  children?: any;
  customIconClass?: string;
  customMessageClass?: string;
  customBannerClass?: string;
}

export const WarningBanner = memo(
  ({
    warningMessage,
    children,
    customIconClass,
    customMessageClass,
    customBannerClass,
  }: Props) => {
    return (
      <div
        className={`flex items-center gap-4  p-4 md:gap-8 md:p-6 md:pr-8 bg-primaryLight rounded ${
          customBannerClass || ""
        }`}
      >
        <i
          className={`material-icons-outlined ${
            customIconClass ? customIconClass : "text-4xl"
          }`}
        >
          warning
        </i>
        <div className="flex flex-col gap-1">
          <h1
            className={`text-black md:tracking-wider ${
              customMessageClass || ""
            }`}
          >
            {warningMessage}
          </h1>
          {children}
        </div>
      </div>
    );
  }
);
