/**
 *
 * FormBuilderPage
 *
 */
import React, { useEffect } from "react";
import SlimCardsGrid, { IGridData } from "app/components/SlimCardsGrid";
import { CreateNewFolder } from "app/components/icons";
import { Wrapper } from "app/components/UI/Layout/Wrapper";
import { useRouteMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEformSlice } from "app/containers/EForm/slice";
import { selectAuth } from "app/containers/Auth/slice/selectors";
import { EFormTable } from "app/containers/EForm/EFormTable";

export const FormBuilderPage = () => {
  const { actions: eformActions } = useEformSlice();
  const authState = useSelector(selectAuth);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      eformActions.getFormTemplatesRequest({
        idToken: authState.session?.idToken,
      })
    );
  }, []);
  const match = useRouteMatch();
  return (
    <Wrapper>
      <SlimCardsGrid
        gridData={gridData}
        headTitle="E-form builder"
        match={match}
      />
      <h1 className="my-4 mb-8 text-xl">
        Templates created by your organization
      </h1>
      <EFormTable />
    </Wrapper>
  );
};

/**
 *
 * Array for generating slim cards on the grid
 *
 * @requires:
 *  @linkTo - A path to where it's heading
 *  @slimCardData - Which takes the title, subtitle and a ICON element
 *
 *
 * */
const gridData: IGridData[] = [
  {
    linkTo: "/builder",
    slimCardData: {
      cardTitle: "Build a form from scatch",
      cardSubtitle: "Create a new form using the form builder",
      LeftIcon: <CreateNewFolder className="w-8 h-8 md:w-10 md:h-10 md:mr-6" />,
    },
  },
];
