/**
 *
 * UserRegistration
 *
 */
import React, { useState, useEffect } from "react";
import { get as getIn } from "lodash";
import { CustomInput } from "../../../components/UI/Forms/Inputs/CustomInput";
import { CustomButton } from "../../../components/UI/Forms/Buttons/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { useAdminSlice } from "app/containers/Admin/slice";
import { selectAuth } from "app/containers/Auth/slice/selectors";
import { CustomSelect } from "../../../components/UI/Forms/Inputs/CustomSelect";
import { selectAdmin } from "app/containers/Admin/slice/selectors";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

//TODO: sssnake case to camelCAse
interface IUserRegister {
  email: string;
  phone_number: string;
  first_name: string;
  last_name: string;
  team: string;
  role: string;
  manager: any;
  access_date: string;
  employee_id: string;
}

export function UserRegistration() {
  const history = useHistory();

  const [team, setTeam] = useState("");
  const [role, setRole] = useState("");
  const [manager, setManager] = useState("");
  const [teamExpiryDate, setTeamExpiryDate] = useState(
    new Date().toISOString().split("T")[0]
  );

  const { actions: adminActions } = useAdminSlice();
  const authState = useSelector(selectAuth);
  const adminState = useSelector(selectAdmin);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      adminActions.getManagersRequest({
        idToken: authState.session?.idToken,
        loading: true,
      })
    );
    dispatch(
      adminActions.getTeamsRequest({
        idToken: authState.session?.idToken,
        loading: true,
      })
    );
    dispatch(
      adminActions.getRolesRequest({
        idToken: authState.session?.idToken,
        loading: true,
      })
    );
  }, []);

  const handleRegisterUser = (event) => {
    event.preventDefault();
    const target = event.target;
    let email = getIn(target, "email.value", "");
    email = email.toLowerCase();
    const phone_number = getIn(target, "phone_number.value", "");
    const first_name = getIn(target, "first_name.value", "");
    const last_name = getIn(target, "last_name.value", "");
    const access_date = getIn(target, "access_date.value", "");
    const employee_id = getIn(target, "employee_id.value", "");

    const data: IUserRegister = {
      email,
      phone_number,
      first_name,
      last_name,
      team,
      role,
      manager,
      access_date,
      employee_id,
    };

    if (!manager) {
      toast.info("Fill in the Manager details", { position: "bottom-center" });
      return;
    }

    dispatch(
      adminActions.registerUserRequest({
        idToken: authState.session?.idToken,
        data,
        history,
      })
    );
    target.reset();
  };
  return (
    <form
      className="w-full grid grid-cols-1 grid-flow-row gap-4 py-10 px-10 xl:px-0 "
      onSubmit={handleRegisterUser}
    >
      <div className="grid grid-cols-1 gap-4 md:grid-cols-3 ">
        <h1 className="md:col-span-full text-3xl tracking-widest">
          Registration
        </h1>
        <h2 className="col-span-full text-xl">Personal Information </h2>

        <CustomInput
          inputId="first_name"
          inputName="first_name"
          inputLabel="First Name"
          inputType="text"
          isRequired={true}
        />
        <CustomInput
          inputId="last_name"
          inputName="last_name"
          inputLabel="Last Name"
          inputType="text"
          isRequired={true}
        />
        <CustomInput
          inputId="email"
          inputName="email"
          inputLabel="Email"
          inputType="email"
          isRequired={true}
        />
        <CustomInput
          inputId="phone_number"
          inputName="phone_number"
          inputLabel="Phone Number"
          inputType="tel"
          isRequired={true}
        />
      </div>

      <div className="grid grid-cols-1 gap-4 md:grid-cols-3 ">
        <h2 className="col-span-full text-xl">Employment Information </h2>
        <CustomInput
          inputId="employee_id"
          inputName="employee_id"
          inputLabel="Employee ID"
          inputType="text"
          isRequired={true}
        />
      </div>
      <div className="grid grid-cols-1 gap-4 md:grid-cols-3 ">
        <h2 className="col-span-full text-xl">Team Information </h2>

        <CustomSelect
          selectValue={team}
          setSelectValue={setTeam}
          options={adminState.teamsData}
          label="Team"
          name="team"
          inputID="team"
        />
        <CustomSelect
          selectValue={role}
          setSelectValue={setRole}
          options={adminState.rolesData}
          label="Role on Team"
          name="role"
          inputID="role"
        />
        <CustomSelect
          isRequired={true}
          selectValue={manager}
          setSelectValue={setManager}
          options={adminState.managerData}
          label="Manager"
          name="manager"
          inputID="manager"
        />
        <CustomInput
          inputId="access_date"
          inputName="access_date"
          inputLabel="Access Expiry Date"
          inputType="date"
          minDate={new Date().toISOString().split("T")[0]}
          onChange={(value) => setTeamExpiryDate(value)}
          inputValue={teamExpiryDate}
        />
      </div>

      <div className="grid grid-cols-1 gap-4 mt-3 md:grid-cols-3">
        <CustomButton type="submit" label="Register a new user" />
      </div>
    </form>
  );
}
