import { selectAuth } from "app/containers/Auth/slice/selectors";
import React, { ReactElement, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Table } from "../../../components/Table/Loadable";
import { useAdminSlice } from "../slice";
import { selectAdmin } from "../slice/selectors";
import { get as getIn } from "lodash";
import { Link } from "react-router-dom";
import { ArrowForwardIcon } from "app/components/icons";

export default function UsersTable(): ReactElement {
  const adminState = useSelector(selectAdmin);
  const authState = useSelector(selectAuth);
  const { actions: adminActions } = useAdminSlice();
  const dispatch = useDispatch();
  const users = getIn(adminState, "userData", []);

  useEffect(() => {
    dispatch(
      adminActions.getUsersRequest({
        loading: true,
        idToken: authState.session?.idToken,
        limit: 10,
      })
    );
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "Name",
      },
      {
        Header: "Assigned to team",
        accessor: "Team",
        Cell: ({ row }: any) => {
          const team = getIn(row, "original.Team");
          return (
            <div
              className="text-sm text-black"
              style={{
                width: "20ch",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              {team}
            </div>
          );
        },
      },
      {
        Header: "Manager",
        accessor: "Manager",
      },
      {
        Header: "Role on team",
        accessor: "Role",
        Cell: ({ row }: any) => {
          const role = getIn(row, "original.Role");
          return (
            <div
              className="text-sm text-black"
              style={{
                width: "20ch",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              {role}
            </div>
          );
        },
      },
      {
        Header: () => null,
        id: "arrowLink",
        disableSortBy: true,
        accessor: "id",
        // eslint-disable-next-line react/prop-types
        Cell: ({ row }) => {
          const rowId = getIn(row, "original.id");
          return (
            <Link key={rowId} to={`users/${rowId}`}>
              <div data-testid="table-arrow-forward">
                <ArrowForwardIcon className="w-6 h-6 md:w-7 md:h-7" />
              </div>
            </Link>
          );
        },
      },
    ],
    []
  );

  return !users.length ? (
    <div>Loading...</div>
  ) : (
    <Table columns={columns} data={users} />
  );
}
