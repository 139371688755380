import React, { Fragment, useRef, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";

interface Props {
  selectOptions: any[];
  buttonTitle: string;
  handleClick?(event): void;
}

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function CustomButtonSelect(props: Props): JSX.Element {
  const [selected, setSelected] = useState("");
  const elementRef = useRef<HTMLDivElement>(null);

  const handleChange = (event) => {
    setSelected(event);
    if (props.handleClick) {
      props.handleClick(event);
    }
  };

  return (
    <Listbox value={selected} onChange={handleChange}>
      {({ open }) => (
        <>
          <div className="relative" ref={elementRef}>
            <div className="inline-flex shadow-sm rounded-md divide-x w-full">
              <Listbox.Button className="py-3 px-4 font-bold rounded-md w-full bg-primary text-white hover:shadow-xl">
                <span className="text-white">{props.buttonTitle}</span>
              </Listbox.Button>
            </div>
            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                className={`
                origin-top-right right-0
                ${
                  elementRef.current &&
                  (elementRef.current as any).getBoundingClientRect().y >=
                    506 &&
                  `transform -translate-y-full origin-bottom-left top-0`
                }
                absolute z-50 mt-2 w-full rounded-md shadow-lg overflow-hidden bg-white divide-y divide-gray-200 ring-1 ring-black ring-opacity-5 focus:outline-none
                `}
              >
                {props.selectOptions.map((option) => (
                  <Listbox.Option
                    key={option.title}
                    className={() =>
                      classNames(
                        "cursor-pointer secondaryText select-none relative p-4 text-sm z-50"
                      )
                    }
                    value={option}
                  >
                    {() => (
                      <div className="flex flex-col">
                        <div className="flex justify-between">
                          <p className="secondaryText">{option.title}</p>
                        </div>
                      </div>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
