import { setLoadingState } from "app/containers/shared";
import { toast } from "react-toastify";
import { call, put, takeLatest } from "redux-saga/effects";
import { coreRecordActions } from "."; //
import {
  ICreateCoreRecord,
  IGetCoreRecord,
  IRelationships,
  IUpdateCoreRecord,
} from "./types";

const url = process.env.REACT_APP_COGNITO_USERS_ENDPOINT;
const caseUrl = process.env.REACT_APP_COGNITO_CASE_ENDPOINT;

function* createCoreRecord({ payload }: { payload: ICreateCoreRecord }) {
  yield call(setLoadingState, true);
  const createCoreRecordURL = `${url}/coreRecord`;

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${payload.idToken.token}`,
    },
    body: JSON.stringify(payload.data),
  };

  try {
    const data = yield fetch(createCoreRecordURL, requestOptions)
      .then((response) => response.json())
      .catch((error) => {
        console.error("There was an error!", error);
        throw error;
      });

    yield put({
      type: coreRecordActions.registerCoreRecordSuccess.type,
      payload: { data },
    });
    const coreRecordData: any = payload.data;
    toast.success(
      `${coreRecordData.firstName} ${coreRecordData.familyName} was successfully added to the system`,
      {
        position: "bottom-center",
      }
    );
    if (payload.myWorkRedirectUrl) {
      payload.history.push(`/mywork/${payload.myWorkRedirectUrl}`);
    } else {
      const id = encodeURIComponent(data.id);
      payload.history.push(`/core-record/manage/${id}`);
    }
  } catch (error) {
    yield put({
      type: coreRecordActions.registerCoreRecordError.type,
      payload: { error },
    });
  }

  yield call(setLoadingState, false);
}

function* updateCoreRecord({ payload }: { payload: IUpdateCoreRecord }) {
  yield call(setLoadingState, true);
  const updateCoreRecordURL = `${url}/coreRecord`;

  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${payload.idToken.token}`,
    },
    body: JSON.stringify(payload.data),
  };

  try {
    const response = yield fetch(updateCoreRecordURL, requestOptions)
      .then((response) => response.json())
      .catch((error) => {
        console.error("There was an error!", error);
        throw error;
      });

    yield put({
      type: coreRecordActions.updateCoreRecordSuccess.type,
      payload: response,
    });
    const coreRecordData: any = payload.data;
    toast.success(
      `${coreRecordData.firstName} ${coreRecordData.familyName} was successfully updated`,
      {
        position: "bottom-center",
      }
    );
    payload.history.push(`/core-record/manage/${payload.data.id}`);
  } catch (error) {
    yield put({
      type: coreRecordActions.updateCoreRecordError.type,
      payload: { error },
    });
  }

  yield call(setLoadingState, false);
}

function* getCoreRecord({ payload }: { payload: IGetCoreRecord }) {
  yield call(setLoadingState, true);
  const getCoreRecordUrl = `${url}/coreRecord/${payload.partitionKey}`;

  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${payload.idToken?.token}`,
    },
  };
  try {
    const data = yield fetch(getCoreRecordUrl, requestOptions)
      .then((response) => response.json())
      .catch((error) => {
        console.error("There was an error!", error);
        throw error;
      });

    yield put({
      type: coreRecordActions.getCoreRecordSuccess.type,
      payload: { data },
    });

    if (data.AboutMe) {
      yield put({
        type: coreRecordActions.loadS3ImageFilesRequest.type,
        payload: { aboutMe: data.AboutMe, idToken: payload.idToken },
      });
    }
  } catch (error) {
    yield put({
      type: coreRecordActions.getCoreRecordError.type,
      payload: { error },
    });
    yield call(setLoadingState, false);
  }

  yield call(setLoadingState, false);
}

function* loadS3ImageFiles({ payload }: { payload }) {
  const getSignUrl = `${url}/getSign`;
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${payload.idToken.token}`,
    },
    body: "",
  };
  const { aboutMe } = payload;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { PK, SK, SystemRole, EntityType, ...aboutMeQuestions } = aboutMe;
  const questionsWithFile: string[] = [];
  for (const [questionKey, questionProps] of Object.entries(aboutMeQuestions)) {
    for (const [propKey] of Object.entries(
      JSON.parse(JSON.stringify(questionProps))
    )) {
      if (propKey === "files") {
        questionsWithFile.push(questionKey);
      }
    }
  }

  let signedURLs;
  let items = [];

  for (const question of questionsWithFile) {
    items = yield aboutMe[question].files.filter((file) => {
      if (file?.fileType?.startsWith("image"))
        return { fileId: file.fileId, bucketName: file.bucketName };
    });
    if (items.length > 0) {
      requestOptions.body = JSON.stringify({ items });
      signedURLs = yield fetch(getSignUrl, requestOptions)
        .then((response) => response.json())
        .catch((error) => {
          throw error;
        });

      for (const signedURL of signedURLs) {
        yield put({
          type: coreRecordActions.loadS3ImageFileSuccess.type,
          payload: {
            src: signedURL?.url,
            questionKey: question,
            fileName: signedURL.fileName,
          },
        });
      }
    }
    items = [];
  }
}

function* getCoreRecords({
  payload,
}: {
  payload: { idToken: { token: string; expiry: number } };
}) {
  yield call(setLoadingState, true);
  const getCoreRecordsUrl = `${url}/coreRecords`;

  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${payload.idToken?.token}`,
    },
  };
  try {
    const data = yield fetch(getCoreRecordsUrl, requestOptions)
      .then((response) => response.json())
      .catch((error) => {
        console.error("There was an error!", error);
        throw error;
      });

    yield put({
      type: coreRecordActions.getCoreRecordsSuccess.type,
      payload: { data },
    });
  } catch (error) {
    yield put({
      type: coreRecordActions.getCoreRecordsError.type,
      payload: { error },
    });
  }

  yield call(setLoadingState, false);
}

function* getAboutMe({ payload }: { payload }) {
  yield call(setLoadingState, true);
  const getAboutMeURL = `${url}/aboutMe/${payload.partitionKey}`;
  const urlWithParams = new URL(getAboutMeURL);

  urlWithParams.searchParams.append(
    "partitionKey",
    `uk#cr#${payload.partitionKey}`
  );

  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${payload.idToken.token}`,
    },
  };
  try {
    if (!payload.coreRecordDetails) {
      yield put({
        type: coreRecordActions.getCoreRecordRequest,
        payload: { id: payload.id, idToken: payload.idToken },
      });
    }
    const aboutMe = yield fetch(urlWithParams.toString(), requestOptions)
      .then((response) => response.json())
      .catch((error) => {
        console.error("There was an error!", error);
        throw error;
      });

    yield put({
      type: coreRecordActions.getAboutMeSuccess.type,
      payload: { aboutMe },
    });
  } catch (error) {
    yield put({
      type: coreRecordActions.getAboutMeError.type,
      payload: { error },
    });
  }

  yield call(setLoadingState, false);
}

function* uploadAboutMeFiles({ payload }: { payload }) {
  yield call(setLoadingState, true, "Uploading files.");

  const { filesToUpload, idToken, aboutMe, setFilesToUpload } = payload;
  const bucketName = "core-record-files-dev";

  try {
    // sign urls
    const items = yield filesToUpload.map((files) => {
      return files.file.map((file) => {
        return {
          bucketName,
          fileName: file.name,
          fileType: file.type,
          questionKey: files.key,
        };
      })[0];
    });
    const signedURLS = yield call(signURLS, items, idToken);

    const filesSRCs = yield filesToUpload.map((files) => {
      return files.file.map((file) => {
        return {
          key: files.key,
          src: file.src,
          type: file.type,
        };
      })[0];
    });
    const uploadedFiles = yield call(
      uploadAboutMeFilesToS3,
      signedURLS,
      filesSRCs,
      bucketName
    );
    const aboutMeWithUploadedFiles = yield call(
      mapUploadedFiles,
      aboutMe,
      uploadedFiles
    );

    yield call(setFilesToUpload, []);
    yield put({
      type: coreRecordActions.registerAboutMeRequest.type,
      payload: {
        aboutMe: aboutMeWithUploadedFiles,
        idToken,
        uploadedFiles,
      },
    });
  } catch (error) {
    console.error(error);
    yield call(setLoadingState, false);
  }
}

function* registerAboutMe({ payload }: { payload }) {
  const coreRecordAboutMeURL = `${url}/aboutMe`;
  const { aboutMe, idToken, uploadedFiles } = payload;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { PK, SK, SystemRole, EntityType, ...aboutMeQuestion } = aboutMe;

  //removed the signed urls
  for (const [questionKey, questionObject] of Object.entries(
    aboutMeQuestion
  ) as any) {
    if (questionObject.files) {
      const filesNoSRC = yield aboutMe[questionKey].files.map((file) => {
        if (!file.src) return file;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { src, ...rest } = file;
        return rest;
      });
      aboutMe[questionKey] = { ...aboutMe[questionKey], files: filesNoSRC };
    }
  }

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${idToken.token}`,
    },
    body: JSON.stringify(aboutMe),
  };

  yield call(setLoadingState, true);

  try {
    yield fetch(coreRecordAboutMeURL, requestOptions)
      .then((response) => response.json())
      .catch((error) => {
        console.error("There was an error!", error);
        throw error;
      });

    toast.success("The about me data have been successfully stored", {
      position: "bottom-center",
    });
    yield put({
      type: coreRecordActions.registerAboutMeSuccess.type,
      payload: { aboutMe: payload.aboutMe },
    });
    if (uploadedFiles) {
      yield call(signS3GetURLs, idToken, uploadedFiles);
    }
  } catch (error) {
    yield put({
      type: coreRecordActions.registerAboutMeError.type,
      payload: { error },
    });
  }

  yield call(setLoadingState, false);
}

function* downloadFile({ payload }: { payload }) {
  const { file } = payload;
  const getSignUrl = `${url}/getSign`;
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${payload.idToken.token}`,
    },
    body: JSON.stringify({
      items: [{ bucketName: file.bucketName, fileId: file.fileId }],
    }),
  };
  const signedURL = yield fetch(getSignUrl, requestOptions)
    .then((response) => response.json())
    .catch((error) => {
      throw error;
    });
  window.open(signedURL[0]?.url, "_blank");
}

function* createCase({ payload }: { payload }) {
  const { crId, data, idToken } = payload;
  yield call(setLoadingState, true);
  const createCaseURL = `${caseUrl}/coreRecord/${crId}/case`;

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${idToken.token}`,
    },
    body: JSON.stringify(data),
  };
  try {
    const data = yield fetch(createCaseURL, requestOptions)
      .then((response) => response.json())
      .catch((error) => {
        console.error("There was an error!", error);
        throw error;
      });

    yield put({
      type: coreRecordActions.createCaseSuccess.type,
      payload: { data },
    });
  } catch (error) {
    yield put({
      type: coreRecordActions.createCaseError.type,
      payload: { error },
    });
  }

  yield call(setLoadingState, false);
}

function* sendCaseForAllocation({ payload }: { payload }) {
  const { crId, data, idToken } = payload;
  yield call(setLoadingState, true);
  const sendCaseForAllocationURL = `${caseUrl}/coreRecord/${crId}/case/unallocate`;

  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${idToken.token}`,
    },
    body: JSON.stringify(data),
  };
  try {
    const data = yield fetch(sendCaseForAllocationURL, requestOptions)
      .then((response) => response.json())
      .catch((error) => {
        console.error("There was an error!", error);
        throw error;
      });

    yield put({
      type: coreRecordActions.sendCaseForAllocationSuccess.type,
      payload: { data },
    });
  } catch (error) {
    yield put({
      type: coreRecordActions.sendCaseForAllocationError.type,
      payload: { error },
    });
  }

  yield call(setLoadingState, false);
}
function* allocateCase({ payload }: { payload }) {
  const { crId, data, idToken, allocateToUser } = payload;
  yield call(setLoadingState, true);
  const sendCaseForAllocation = `${caseUrl}/coreRecord/${crId}/case/allocate`;

  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${idToken.token}`,
    },
    body: JSON.stringify({ caseId: data.id, newOwnerDetails: allocateToUser }),
  };
  try {
    const data = yield fetch(sendCaseForAllocation, requestOptions)
      .then((response) => response.json())
      .catch((error) => {
        console.error("There was an error!", error);
        throw error;
      });

    yield put({
      type: coreRecordActions.allocateCaseSuccess.type,
      payload: { data },
    });
  } catch (error) {
    yield put({
      type: coreRecordActions.allocateCaseError.type,
      payload: { error },
    });
  }

  yield call(setLoadingState, false);
}

function* closeCaseFile({ payload }: { payload }) {
  const { data, idToken, crId } = payload;
  const closeCaseFileURL = `${caseUrl}/coreRecord/${crId}/case`;

  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${idToken.token}`,
    },
    body: JSON.stringify(data),
  };

  yield call(setLoadingState, true);

  try {
    const data = yield fetch(closeCaseFileURL, requestOptions)
      .then((response) => response.json())
      .catch((error) => {
        console.error("There was an error!", error);
        throw error;
      });
    yield put({
      type: coreRecordActions.closeCaseSuccess.type,
      payload: { data },
    });
  } catch (error) {
    yield put({
      type: coreRecordActions.closeCaseError.type,
      payload: { error },
    });
  }

  yield call(setLoadingState, false);
}
function* createCaseNote({ payload }: { payload }) {
  yield call(setLoadingState, true);
  const { filesToUpload, idToken, crId, data } = payload;
  const createCaseNoteURL = `${caseUrl}/coreRecord/${crId}/casenote`;
  const bucketName = "core-record-files-dev";

  let uploadedFiles;
  if (filesToUpload.length > 0) {
    const items = filesToUpload.map((file) => {
      return {
        bucketName,
        fileName: file.name,
        fileType: file.type,
      };
    });

    const signedS3URLs = yield call(signURLS, items, idToken);

    const filesSRCs = filesToUpload.map((file) => {
      return {
        src: file.src,
        type: file.type,
      };
    });

    uploadedFiles = yield call(
      uploadFilesToS3,
      signedS3URLs,
      filesSRCs,
      bucketName
    );
  }

  const caseNotesData = uploadedFiles
    ? { ...data, files: uploadedFiles }
    : data;

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${idToken.token}`,
    },
    body: JSON.stringify(caseNotesData),
  };
  try {
    const data = yield fetch(createCaseNoteURL, requestOptions)
      .then((response) => response.json())
      .catch((error) => {
        console.error("There was an error!", error);
        throw error;
      });
    yield put({
      type: coreRecordActions.createCaseNoteSuccess.type,
      payload: { data },
    });
    yield put({
      type: coreRecordActions.setCanGoBack.type,
      payload: { goBack: true },
    });
  } catch (error) {
    yield put({
      type: coreRecordActions.createCaseNoteError.type,
      payload: { error },
    });
  }

  yield call(setLoadingState, false);
}
function* getCaseNotes({ payload }: { payload }) {
  const { id, idToken } = payload;
  const caseNotesURL = `${caseUrl}/coreRecord/${id}/casenote`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${idToken.token}`,
    },
  };

  yield call(setLoadingState, true);

  try {
    const data = yield fetch(caseNotesURL, requestOptions)
      .then((response) => response.json())
      .catch((error) => {
        console.error("There was an error!", error);
        throw error;
      });
    yield put({
      type: coreRecordActions.getCaseNotesSuccess.type,
      payload: { data },
    });
  } catch (error) {
    yield put({
      type: coreRecordActions.getCaseNotesError.type as any,
      payload: { error },
    });
  }

  yield call(setLoadingState, false);
}

function* getSingleCaseFile({ payload }: { payload }) {
  const { crId, idToken, caseFileId } = payload;
  const caseFilesURL = `${caseUrl}/coreRecord/${crId}/casefile/${caseFileId}`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${idToken.token}`,
    },
  };

  yield call(setLoadingState, true);

  try {
    const data = yield fetch(caseFilesURL, requestOptions)
      .then((response) => response.json())
      .catch((error) => {
        console.error("There was an error!", error);
        throw error;
      });
    yield put({
      type: coreRecordActions.getSingleCaseFileSuccess.type,
      payload: { data },
    });
  } catch (error) {
    yield put({
      type: coreRecordActions.getSingleCaseFileError.type as any,
      payload: { error },
    });
  }

  yield call(setLoadingState, false);
}

function* updateCaseNote({ payload }: { payload }) {
  yield call(setLoadingState, true);
  const { data, filesToUpload, idToken, files = [] } = payload;
  const updateCaseNoteURL = `${caseUrl}/casenote`;
  const bucketName = "core-record-files-dev";
  let uploadedFiles;
  if (filesToUpload.length > 0) {
    const items = filesToUpload.map((file) => {
      return {
        bucketName,
        fileName: file.name,
        fileType: file.type,
      };
    });

    const signedS3URLs = yield call(signURLS, items, idToken);

    const filesSRCs = filesToUpload.map((file) => {
      return {
        src: file.src,
        type: file.type,
      };
    });

    uploadedFiles = yield call(
      uploadFilesToS3,
      signedS3URLs,
      filesSRCs,
      bucketName
    );
  }
  const caseNoteFiles =
    uploadedFiles?.length > 0 ? uploadedFiles.concat(files) : files;

  const caseNotesData = caseNoteFiles
    ? { ...data, files: caseNoteFiles }
    : data;

  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${idToken.token}`,
    },
    body: JSON.stringify(caseNotesData),
  };
  try {
    const data = yield fetch(updateCaseNoteURL, requestOptions)
      .then((response) => response.json())
      .catch((error) => {
        console.error("There was an error!", error);
        throw error;
      });
    yield put({
      type: coreRecordActions.updateCaseNoteSuccess.type,
      payload: { data },
    });
    yield put({
      type: coreRecordActions.setCanGoBack.type,
      payload: { goBack: true },
    });
  } catch (error) {
    yield put({
      type: coreRecordActions.updateCaseNoteError.type,
      payload: { error },
    });
  }

  yield call(setLoadingState, false);
}

function* getRelationshipsData({ payload }: { payload: IRelationships }) {
  yield call(setLoadingState, true);
  const getCoreRecordUrl = `${url}/coreRecord/relationships`;

  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${payload.idToken?.token}`,
    },
  };
  try {
    const data = yield fetch(getCoreRecordUrl, requestOptions)
      .then((response) => response.json())
      .catch((error) => {
        console.error("There was an error!", error);
        throw error;
      });

    yield put({
      type: coreRecordActions.getRelationshipsDataSuccess.type,
      payload: { data },
    });
  } catch (error) {
    yield put({
      type: coreRecordActions.getRelationshipsDataError.type,
      payload: { error },
    });
    yield call(setLoadingState, false);
  }

  yield call(setLoadingState, false);
}

function* addConnection({ payload }) {
  yield call(setLoadingState, true);
  const addConnectionURL = `${url}/coreRecord/${payload.crId}/connection`;
  const { setIsBlocking } = payload;

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${payload.idToken.token}`,
    },
    body: JSON.stringify(payload.data),
  };

  try {
    const res = yield fetch(addConnectionURL, requestOptions)
      .then((response) => response.json())
      .catch((error) => {
        console.error("There was an error!", error);
        throw error;
      });

    yield put({
      type: coreRecordActions.addConnectionSuccess.type,
      payload: { data: res, IsKeyWorker: payload.data.IsKeyWorker },
    });
    const connectionData: any = payload.data;
    toast.success(
      `${connectionData.FirstName} ${connectionData.LastName} was successfully added to the system`,
      {
        position: "bottom-center",
      }
    );
    setIsBlocking(false);
    history.back();
  } catch (error) {
    yield put({
      type: coreRecordActions.addConnectionError.type,
      payload: { error },
    });
  }

  yield call(setLoadingState, false);
}

function* updateConnection({ payload }) {
  yield call(setLoadingState, true);
  const { setIsBlocking, shouldReassignKeyworker, crId, conId, idToken } =
    payload;
  let updateConnectionURL;
  if (shouldReassignKeyworker && shouldReassignKeyworker === "yes") {
    updateConnectionURL = `${url}/coreRecord/${crId}/connection/${conId}?isKeyWorker=true`;
  } else {
    updateConnectionURL = `${url}/coreRecord/${crId}/connection/${conId}`;
  }

  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${idToken.token}`,
    },
    body: JSON.stringify(payload.data),
  };

  try {
    yield fetch(updateConnectionURL, requestOptions)
      .then((response) => response.json())
      .catch((error) => {
        console.error("There was an error!", error);
        throw error;
      });

    const connectionData: any = payload.data;
    yield put({
      type: coreRecordActions.updateConnectionSuccess.type,
      payload: {
        data: connectionData,
        shouldReassignKeyworker,
      },
    });
    toast.success(
      `${connectionData.FirstName} ${connectionData.LastName} was successfully updated`,
      {
        position: "bottom-center",
      }
    );
    setIsBlocking(false);
    history.back();
  } catch (error) {
    yield put({
      type: coreRecordActions.updateConnectionError.type,
      payload: { error },
    });
  }

  yield call(setLoadingState, false);
}

function* getConnection({ payload }) {
  yield call(setLoadingState, true);
  const getConnectionURL = `${url}/coreRecord/${payload.crId}/connection/${payload.conId}`;

  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${payload.idToken.token}`,
    },
  };

  try {
    const data = yield fetch(getConnectionURL, requestOptions)
      .then((response) => response.json())
      .catch((error) => {
        console.error("There was an error!", error);
        throw error;
      });

    yield put({
      type: coreRecordActions.getConnectionSuccess.type,
      payload: { data },
    });
  } catch (error) {
    yield put({
      type: coreRecordActions.getConnectionError.type,
      payload: { error },
    });
  }

  yield call(setLoadingState, false);
}

function* deleteConnection({ payload }) {
  yield call(setLoadingState, true);
  let deleteConnectionURL;
  const { setIsBlocking, isKeyWorker, idToken } = payload;
  if (isKeyWorker === "yes") {
    deleteConnectionURL = `${url}/coreRecord/${payload.crId}/connection/${payload.conId}?isKeyWorker=true`;
  } else {
    deleteConnectionURL = `${url}/coreRecord/${payload.crId}/connection/${payload.conId}`;
  }
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${idToken.token}`,
    },
  };

  try {
    yield fetch(deleteConnectionURL, requestOptions).catch((error) => {
      console.error("There was an error!", error);
      throw error;
    });

    yield put({
      type: coreRecordActions.deleteConnectionSuccess.type,
    });
    setIsBlocking(false);
    history.back();
  } catch (error) {
    yield put({
      type: coreRecordActions.deleteConnectionError.type,
      payload: { error },
    });
  }

  yield call(setLoadingState, false);
}

function* getProfessionalConnections({ payload }) {
  yield call(setLoadingState, true);
  const connectionsURL = `${url}/coreRecord/${payload.CrId}/connection?conType=professional`;

  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${payload.idToken.token}`,
    },
  };

  try {
    const data = yield fetch(connectionsURL, requestOptions)
      .then((response) => response.json())
      .catch((error) => {
        console.error("There was an error!", error);
        throw error;
      });

    yield put({
      type: coreRecordActions.getProfessionalConnectionsSuccess.type,
      payload: { data },
    });
  } catch (error) {
    yield put({
      type: coreRecordActions.getProfessionalConnectionsError.type,
      payload: { error },
    });
  }

  yield call(setLoadingState, false);
}

function* getPersonalConnections({ payload }) {
  yield call(setLoadingState, true);
  const connectionsURL = `${url}/coreRecord/${payload.CrId}/connection?conType=personal`;

  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${payload.idToken.token}`,
    },
  };

  try {
    const data = yield fetch(connectionsURL, requestOptions)
      .then((response) => response.json())
      .catch((error) => {
        console.error("There was an error!", error);
        throw error;
      });

    yield put({
      type: coreRecordActions.getPersonalConnectionsSuccess.type,
      payload: { data },
    });
  } catch (error) {
    yield put({
      type: coreRecordActions.getPersonalConnectionsError.type,
      payload: { error },
    });
  }

  yield call(setLoadingState, false);
}

function* getCaseFiles({ payload }: { payload }) {
  const { id, idToken } = payload;
  const caseFileUrl = `${caseUrl}/coreRecord/${id}/casefile`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${idToken.token}`,
    },
  };

  yield call(setLoadingState, true);

  try {
    const data = yield fetch(caseFileUrl, requestOptions)
      .then((response) => response.json())
      .catch((error) => {
        console.error("There was an error!", error);
        throw error;
      });
    yield put({
      type: coreRecordActions.getCaseFilesSuccess.type,
      payload: { data },
    });
  } catch (error) {
    yield put({
      type: coreRecordActions.getCaseFilesError.type as any,
      payload: { error },
    });
  }

  yield call(setLoadingState, false);
}

export function* coreRecordSaga() {
  yield takeLatest(
    coreRecordActions.registerCoreRecordRequest.type as any,
    createCoreRecord
  );
  yield takeLatest(
    coreRecordActions.updateCoreRecordRequest.type as any,
    updateCoreRecord
  );
  yield takeLatest(
    coreRecordActions.getCoreRecordRequest.type as any,
    getCoreRecord
  );
  yield takeLatest(
    coreRecordActions.getCoreRecordsRequest.type as any,
    getCoreRecords
  );
  yield takeLatest(coreRecordActions.getAboutMeRequest.type as any, getAboutMe);
  yield takeLatest(
    coreRecordActions.registerAboutMeRequest.type as any,
    registerAboutMe
  );
  yield takeLatest(
    coreRecordActions.uploadAboutMeFilesRequest.type as any,
    uploadAboutMeFiles
  );
  yield takeLatest(
    coreRecordActions.loadS3ImageFilesRequest.type as any,
    loadS3ImageFiles
  );
  yield takeLatest(
    coreRecordActions.downloadFileRequest.type as any,
    downloadFile
  );
  yield takeLatest(coreRecordActions.createCaseRequest.type as any, createCase);
  yield takeLatest(
    coreRecordActions.closeCaseRequest.type as any,
    closeCaseFile
  );
  yield takeLatest(
    coreRecordActions.createCaseNoteRequest.type as any,
    createCaseNote
  );
  yield takeLatest(
    coreRecordActions.getCaseNotesRequest.type as any,
    getCaseNotes
  );
  yield takeLatest(
    coreRecordActions.getSingleCaseFileRequest.type as any,
    getSingleCaseFile
  );
  yield takeLatest(
    coreRecordActions.updateCaseNoteRequest.type as any,
    updateCaseNote
  );
  yield takeLatest(
    coreRecordActions.getRelationshipsData.type as any,
    getRelationshipsData
  );
  yield takeLatest(
    coreRecordActions.addConnectionRequest.type as any,
    addConnection
  );
  yield takeLatest(
    coreRecordActions.getProfessionalConnectionsRequest.type as any,
    getProfessionalConnections
  );
  yield takeLatest(
    coreRecordActions.updateConnectionRequest.type as any,
    updateConnection
  );
  yield takeLatest(
    coreRecordActions.getConnectionRequest.type as any,
    getConnection
  );
  yield takeLatest(
    coreRecordActions.deleteConnectionRequest.type as any,
    deleteConnection
  );
  yield takeLatest(
    coreRecordActions.getPersonalConnectionsRequest.type as any,
    getPersonalConnections
  );
  yield takeLatest(
    coreRecordActions.getCaseFilesRequest.type as any,
    getCaseFiles
  );
  yield takeLatest(
    coreRecordActions.sendCaseForAllocationRequest.type as any,
    sendCaseForAllocation
  );
  yield takeLatest(
    coreRecordActions.allocateCaseRequest.type as any,
    allocateCase
  );
}

// helper functions

function* signURLS(items, idToken) {
  const signS3URL = `${url}/uploadSign`;
  const requestOptions: any = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${idToken.token}`,
    },
    body: JSON.stringify({ items }),
  };
  return yield fetch(signS3URL, requestOptions)
    .then((response) => response.json())
    .catch((error) => {
      throw error;
    });
}

function* uploadAboutMeFilesToS3(signedS3URLs, fileSRCs, bucketName) {
  const s3RequestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    body: "",
  };
  let buffer: any;
  const uploadedFiles: any[] = [];
  for (const [index, fileObjectWithSignedURL] of signedS3URLs.entries()) {
    // load base64 as buffer, the split is to remove the initial data from base64
    // that describe the type and other meta data for the file
    const { fileId, fileName, fileType, questionKey, url } =
      fileObjectWithSignedURL;
    buffer = yield Buffer.from(fileSRCs[index].src.split(",")[1], "base64");
    s3RequestOptions.body = buffer;
    yield call(setLoadingState, true, `Uploading ${fileName} ...`);
    yield fetch(url, s3RequestOptions)
      .then((response) => {
        response;
      })
      .catch((error) => {
        throw error;
      });
    uploadedFiles.push({
      bucketName,
      fileId: fileId,
      fileName: fileName,
      fileType: fileType,
      questionKey: questionKey,
    });
  }

  return uploadedFiles;
}

function* uploadFilesToS3(signedS3URLs, fileSRCs, bucketName) {
  const s3RequestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    body: "",
  };
  let buffer: any;
  const uploadedFiles: any[] = [];
  for (const [index, fileObjectWithSignedURL] of signedS3URLs.entries()) {
    // load base64 as buffer, the split is to remove the initial data from base64
    // that describe the type and other meta data for the file
    const { fileId, fileName, fileType, url } = fileObjectWithSignedURL;
    buffer = yield Buffer.from(fileSRCs[index].src.split(",")[1], "base64");
    s3RequestOptions.body = buffer;
    yield call(setLoadingState, true, `Uploading ${fileName} ...`);
    yield fetch(url, s3RequestOptions)
      .then((response) => {
        response;
      })
      .catch((error) => {
        throw error;
      });
    uploadedFiles.push({
      bucketName,
      fileId: fileId,
      fileName: fileName,
      fileType: fileType,
    });
  }

  return uploadedFiles;
}

function* mapUploadedFiles(aboutMe, uploadedFiles) {
  const mappedAboutMe = { ...aboutMe };
  yield uploadedFiles.map((file) => {
    if (mappedAboutMe[file.questionKey].files) {
      mappedAboutMe[file.questionKey] = {
        ...mappedAboutMe[file.questionKey],
        files: [...mappedAboutMe[file.questionKey].files, file],
      };
    } else {
      mappedAboutMe[file.questionKey] = {
        ...mappedAboutMe[file.questionKey],
        files: [file],
      };
    }
  });
  return mappedAboutMe;
}

function* signS3GetURLs(idToken, uploadedFiles) {
  const getSignUrl = `${url}/getSign`;
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${idToken.token}`,
    },
    body: "",
  };

  for (const uploadedFile of uploadedFiles) {
    requestOptions.body = JSON.stringify({
      items: [
        {
          fileId: uploadedFile.fileId,
          bucketName: uploadedFile.bucketName,
          questionKey: uploadedFile.questionKey,
        },
      ],
    });
    const signedURLs = yield fetch(getSignUrl, requestOptions)
      .then((response) => response.json())
      .catch((error) => {
        throw error;
      });

    for (const signedURL of signedURLs) {
      yield put({
        type: coreRecordActions.loadS3ImageFileSuccess.type,
        payload: {
          src: signedURL?.url,
          questionKey: uploadedFile.questionKey,
          fileName: signedURL.fileName,
        },
      });
    }
  }
}
