/**
 *
 * CustomComponentWithHover
 *
 */
import React, { useState } from "react";
import { DeleteIcon, FileDownloadIcon } from "../icons";
import { LoadingSpinner } from "../UI/General/LoadingSpinner";

interface Props {
  children: JSX.Element;
  fileType?: string;
  fileSRC?: string;
  handleDelete?();
  handleFileDownload?();
}

export function CustomComponentWithHover({
  children,
  fileType,
  fileSRC,
  handleDelete,
  handleFileDownload,
}: Props) {
  const [imageSRC, setImageSRC] = useState("");

  const image = new Image();

  if (fileSRC && fileType?.startsWith("image")) {
    image.src = fileSRC;
  }

  image.onload = () => {
    if (fileSRC && fileType?.startsWith("image")) {
      setImageSRC(image.src);
    }
  };
  return (
    <div
      className="hover-over flex flex-col justify-center items-center text-center mr-2 mt-2 mb-2 "
      data-testid="hover-over"
    >
      <div
        className={` flex justify-center items-center pr-2 w-48 h-48 rounded ${
          fileType?.startsWith("image") ? "bg-cover bg-center" : "bg-white"
        }`}
        style={{
          backgroundImage: `url(${fileType?.startsWith("image") && imageSRC})`,
        }}
      >
        {fileType?.startsWith("image") ? (
          imageSRC ? (
            ""
          ) : (
            <div className="flex flex-col items-center justify-center">
              <LoadingSpinner />
              {`Loading file ${children}`}
            </div>
          )
        ) : (
          children
        )}
      </div>
      <div className="flex w-full justify-between -mt-10 px-2 show-on-hover">
        <div
          onClick={handleDelete}
          className="cursor-pointer hover:shadow"
          data-testid="delete-component"
        >
          <DeleteIcon className="w-8 h-8 p-1 bg-white" />
        </div>
        <div
          onClick={handleFileDownload}
          className="cursor-pointer hover:shadow"
          data-testid="download-component"
        >
          <FileDownloadIcon className="w-8 h-8 p-1 bg-white" />
        </div>
      </div>
    </div>
  );
}
