/**
 *
 * EFormPublish
 *
 */
import React, { useState } from "react";
import { CustomInput } from "app/components/UI/Forms/Inputs/CustomInput";
import CustomRichText from "app/components/UI/Forms/Inputs/CustomRichText";
import { Wrapper } from "app/components/UI/Layout/Wrapper";
import { CustomButton } from "app/components/UI/Forms/Buttons/CustomButton";
import dayjs from "dayjs";
import {
  CustomDropDownMenu,
  IDropDownItems,
} from "app/components/CustomDropDownMenu";
import { useDispatch, useSelector } from "react-redux";
import { selectEForm } from "../slice/selectors";
import { useEformSlice } from "../slice";
import { toast } from "react-toastify";
import { selectAuth } from "app/containers/Auth/slice/selectors";
import { CustomModal, IModalProps } from "app/components/CustomModal";
import { EformPublishingStatus } from "common";
import { useParams } from "react-router-dom";
import { compareGridElements } from "../EFormBuilder";
import { EFORM_STATUS } from "../constants";
const { PUBLISHED } = EFORM_STATUS;

interface IRouteParams {
  id: string;
}

export function EFormPublish() {
  const dispatch = useDispatch();
  const { actions: eformActions } = useEformSlice();
  const { formTemplate } = useSelector(selectEForm);
  const authState = useSelector(selectAuth);
  const [triggerModal, setTriggerModal] = useState(false);
  const [modalProps, setModalProps] = useState<IModalProps>({} as IModalProps);
  const { id } = useParams<IRouteParams>();

  const handleChanges = (value, fieldName) => {
    dispatch(
      eformActions.updateFormTemplateDetails({ propKey: fieldName, value })
    );
  };

  const handleSave = (status: EformPublishingStatus) => {
    if (
      !formTemplate ||
      !formTemplate.TemplateName ||
      !formTemplate.TemplateDescription
    ) {
      toast.info("Fill in the form details", { position: "bottom-center" });
      return;
    }

    const IsPublishedForm = status === PUBLISHED && "1";
    const formTemplateRequestData = {
      ...formTemplate,
      Template: [...formTemplate.Template].sort(compareGridElements),
      PublishedStatus: status,
      IsPublishedForm,
    };

    if (id) {
      dispatch(
        eformActions.updateFormTemplateRequest({
          data: formTemplateRequestData,
          idToken: authState.session?.idToken,
          currentUser: authState.currentUser,
        })
      );
      return;
    }

    dispatch(
      eformActions.registerFormTemplateRequest({
        data: formTemplateRequestData,
        idToken: authState.session?.idToken,
        currentUser: authState.currentUser,
      })
    );
  };

  const deleteFormTemplate = () => {
    dispatch(
      eformActions.deleteFormTemplateRequest({
        id,
        idToken: authState.session?.idToken,
      })
    );
  };

  const handleDeleteFormTemplate = () => {
    if (!id) return;
    if (formTemplate.PublishedStatus === PUBLISHED) {
      toast.info("Cannot delete published templates", {
        position: "bottom-center",
      });
      return;
    }

    setTriggerModal(true);
    setModalProps({
      title: "Are you sure you want to delete this template?",
      positiveButtonText: "YES, DELETE IT",
      handlePositiveClick: deleteFormTemplate,
    });
  };

  const dropDownMenuItems: IDropDownItems[] = [
    { text: "Delete Form Template", handleClick: handleDeleteFormTemplate },
  ];

  return (
    <>
      <Wrapper>
        <div className="flex flex-col w-3/4 mt-10">
          <div className="w-full flex justify-between items-center">
            <h1 className="md:col-span-2 my-6 text-xl md:text-3xl font-semibold tracking-wider w-full">
              Form template overview
            </h1>
            {id && <CustomDropDownMenu menuItems={dropDownMenuItems} />}
          </div>
          {formTemplate?.CreatedBy && (
            <div className="w-full my-6">
              <div className="flex py-2 justify-start items-center">
                <span className="text-secondaryText text-sm w-40  ">
                  Created By:
                </span>
                <span className="font-bold">
                  {formTemplate.CreatedBy?.FirstName}{" "}
                  {formTemplate.CreatedBy?.LastName}
                </span>
              </div>
              <div className="flex py-2 justify-start items-center">
                <span className="text-secondaryText text-sm w-40  ">
                  Created:
                </span>
                <span className="font-bold">
                  {dayjs(formTemplate.CreatedDate).format("DD.MM.YY HH:mm")}
                </span>
              </div>
              {formTemplate?.LastUpdated && (
                <>
                  <div className="flex py-2 justify-start items-center">
                    <span className="text-secondaryText text-sm w-40 ">
                      Last Updated:
                    </span>
                    <span className="font-bold">
                      {dayjs(formTemplate?.LastUpdated).format(
                        "DD.MM.YY HH:mm"
                      )}
                    </span>
                  </div>
                  <div className="flex py-2 justify-start items-center">
                    <span className="text-secondaryText text-sm w-40 ">
                      Updated By:
                    </span>
                    <span className="font-bold">
                      {formTemplate.UpdatedBy?.FirstName}{" "}
                      {formTemplate.UpdatedBy?.LastName}
                    </span>
                  </div>
                </>
              )}
            </div>
          )}
          <div className="w-full">
            <CustomInput
              inputName="formTitle"
              inputLabel="Form template title"
              inputType="text"
              inputId="formTitle"
              onChange={(event) => {
                handleChanges(event, "TemplateName");
              }}
              inputValue={formTemplate.TemplateName}
            />
          </div>
          <div className="w-full mt-6">
            <CustomRichText
              value={formTemplate.TemplateDescription}
              setValue={(value, name) => {
                handleChanges(value, name);
              }}
              name="TemplateDescription"
              testId="template-description"
            />
          </div>
          <div className="w-1/2 my-4 mt-10">
            <CustomButton
              label="SAVE AS DRAFT"
              isLight={true}
              type="button"
              handleClick={() => handleSave("drafted")}
            />
          </div>
          <div className="w-1/2 my-2">
            <CustomButton
              label="PUBLISH FORM"
              type="button"
              handleClick={() => handleSave("published")}
            />
          </div>
        </div>
      </Wrapper>
      <CustomModal
        open={triggerModal}
        setOpen={setTriggerModal}
        {...modalProps}
      />
    </>
  );
}
