import { setLoadingState } from "app/containers/shared";
import { toast } from "react-toastify";
import { call, put, takeLatest } from "redux-saga/effects";
import { workflowActions as actions } from ".";

const url = process.env.REACT_APP_COGNITO_USERS_ENDPOINT;

const workflowTemplatesURL = () => {
  return `${url}/workflow`;
};

const workflowTemplateURL = (id) => {
  return `${workflowTemplatesURL()}/${id}`;
};

function* registerWorkkflowTemplate({ payload }: { payload }) {
  const { data, idToken, currentUser } = payload;
  const WorkkflowTemplate = {
    ...data,
    SK: currentUser.PK,
    CreatedBy: {
      FirstName: currentUser.FirstName,
      LastName: currentUser.LastName,
    },
  };
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${idToken.token}`,
    },
    body: JSON.stringify(WorkkflowTemplate),
  };

  yield call(setLoadingState, true);

  try {
    yield fetch(workflowTemplatesURL(), requestOptions)
      .then((response) => response.json())
      .catch((error) => {
        console.error("There was an error!", error);
        throw error;
      });
    yield put({ type: actions.registerWorkflowTemplateSuccess });
    toast.success("Successfully added workflow template", {
      position: "bottom-center",
    });
    history.back();
  } catch (error) {
    yield put({
      type: actions.registerWorkflowTemplateError,
      payload: { error },
    });
  }

  yield call(setLoadingState, false);
}

function* updateWorkkflowTemplate({ payload }: { payload }) {
  const { data, idToken, currentUser } = payload;
  const WorkkflowTemplate = {
    ...data,
    UpdatedBy: {
      PK: currentUser.PK,
      FirstName: currentUser.FirstName,
      LastName: currentUser.LastName,
    },
    LastUpdated: Date.now(),
  };

  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${idToken.token}`,
    },
    body: JSON.stringify(WorkkflowTemplate),
  };

  yield call(setLoadingState, true);

  try {
    yield fetch(workflowTemplatesURL(), requestOptions)
      .then((response) => response.json())
      .catch((error) => {
        console.error("There was an error!", error);
        throw error;
      });
    yield put({ type: actions.updateWorkflowTemplateSuccess });
    toast.success("Successfully updated workflow template", {
      position: "bottom-center",
    });
    history.back();
  } catch (error) {
    yield put({
      type: actions.updateWorkflowTemplateError,
      payload: { error },
    });
  }

  yield call(setLoadingState, false);
}

function* getWorkflowTemplates({ payload }: { payload }) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${payload.idToken.token}`,
    },
  };

  yield call(setLoadingState, true);

  try {
    const data = yield fetch(workflowTemplatesURL(), requestOptions)
      .then((response) => response.json())
      .catch((error) => {
        console.error("There was an error!", error);
        throw error;
      });
    yield put({
      type: actions.getWorkflowTemplatesSuccess.type,
      payload: { data },
    });
    toast.success("Successfully loaded workflow templates", {
      position: "bottom-center",
    });
  } catch (error) {
    yield put({
      type: actions.getWorkflowTemplatesError.type as any,
      payload: { error },
    });
  }

  yield call(setLoadingState, false);
}

function* getWorkflowTemplate({ payload }: { payload }) {
  const { id, idToken } = payload;

  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${idToken.token}`,
    },
  };

  yield call(setLoadingState, true);

  try {
    const data = yield fetch(workflowTemplateURL(id), requestOptions).then(
      (response) => response.json()
    );

    yield put({
      type: actions.getWorkflowTemplateSuccess.type,
      payload: { data },
    });
  } catch (error) {
    yield put({
      type: actions.getWorkflowTemplateError.type as any,
      payload: { error },
    });
  }

  yield call(setLoadingState, false);
}

function* deleteWorkflowTemplate({ payload }) {
  const { id, idToken } = payload;
  const { deleteWorkflowTemplateSuccess, deleteWorkflowTemplateError } =
    actions;

  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${idToken.token}`,
    },
  };

  try {
    const data = yield fetch(workflowTemplateURL(id), requestOptions).then(
      (response) => response.json()
    );
    yield put({
      type: deleteWorkflowTemplateSuccess.type,
      payload: { data },
    });
    toast.success("Successfully deleted workflow template", {
      position: "bottom-center",
    });
    history.back();
  } catch (error) {
    yield put({
      type: deleteWorkflowTemplateError.type as any,
      payload: { error },
    });
  }
}

export function* workflowSaga() {
  yield takeLatest(
    actions.registerWorkflowTemplateRequest.type as any,
    registerWorkkflowTemplate
  );
  yield takeLatest(
    actions.getWorkflowTemplatesRequest.type as any,
    getWorkflowTemplates
  );
  yield takeLatest(
    actions.getWorkflowTemplateRequest.type as any,
    getWorkflowTemplate
  );
  yield takeLatest(
    actions.updateWorkflowTemplateRequest.type as any,
    updateWorkkflowTemplate
  );
  yield takeLatest(
    actions.deleteWorkflowTemplateRequest.type as any,
    deleteWorkflowTemplate
  );
}
