/**
 *
 * MyWorkTable
 *
 */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectAuth } from "app/containers/Auth/slice/selectors";
import { Link } from "react-router-dom";
import { ArrowForwardIcon } from "app/components/icons";
import { get as getIn } from "lodash";
import { Table } from "app/components/Table";
import dayjs from "dayjs";
import { useMyWorkSlice } from "../slice";
import { selectMyWork } from "../slice/selectors";
import { MYWORK_STATUS_LABEL } from "../constants";

export function MyWorkTable(): JSX.Element {
  const { actions: myWorkActions } = useMyWorkSlice();
  const authState = useSelector(selectAuth);
  const myWorkState = useSelector(selectMyWork);
  const myWork = getIn(myWorkState, "myWork", []);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      myWorkActions.getAllMyWorkRequest({
        idToken: authState.session?.idToken,
      })
    );
  }, []);
  const columns = React.useMemo(
    () => [
      {
        Header: "Person",
        Cell: ({ row }: any) => {
          const person = getIn(row, "original.CoreRecordName", "Unassigned");
          return (
            <span className="text-black text-sm font-light">{person}</span>
          );
        },
      },
      {
        Header: "Current case work",
        Cell: ({ row }: any) => {
          const currentCaseWork = getIn(row, "original.WorkflowTemplateTitle");
          return (
            <span className="text-black text-sm font-light">
              {currentCaseWork}
            </span>
          );
        },
      },
      {
        Header: "Last Updated",
        Cell: ({ row }: any) => {
          const lastUpdated = getIn(row, "original.LastUpdated");
          return (
            <span className="text-black text-sm font-light">
              {lastUpdated && dayjs(lastUpdated).format("DD.MM.YYYY HH:mm")}
            </span>
          );
        },
      },
      {
        Header: "Status",
        Cell: ({ row }: any) => {
          const myWorkStatus = getIn(row, "original.Status", "ONGOING");
          return (
            <span className="text-black text-sm font-light">
              {MYWORK_STATUS_LABEL[myWorkStatus]}
            </span>
          );
        },
      },
      {
        Header: () => null,
        id: "arrowLink",
        Cell: ({ row }: any) => {
          const rowId = getIn(row, "original.id");
          return (
            <Link key={rowId} to={`mywork/${rowId}`}>
              <ArrowForwardIcon className="w-6 h-6 md:w-7 md:h-7" />
            </Link>
          );
        },
      },
    ],
    []
  );

  return <Table columns={columns} data={myWork} />;
}
