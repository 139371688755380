/**
 *
 * WorkflowTable
 *
 */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectAuth } from "app/containers/Auth/slice/selectors";
import { Link } from "react-router-dom";
import { ArrowForwardIcon } from "app/components/icons";
import { get as getIn } from "lodash";
import { Table } from "app/components/Table";
import dayjs from "dayjs";
import { sanitize } from "dompurify";
import { useWorkflowSlice } from "../slice";
import { selectWorkflow } from "../slice/selectors";
import { WORKFLOW_TYPE_LABELS } from "../constants";

export function WorkflowTable() {
  const { actions: workflowActions } = useWorkflowSlice();
  const authState = useSelector(selectAuth);
  const workflowsState = useSelector(selectWorkflow);
  const workflowTemplates = getIn(workflowsState, "workflowTemplates", []);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      workflowActions.getWorkflowTemplatesRequest({
        idToken: authState.session?.idToken,
      })
    );
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: "Event Type",
        accessor: "EventType",
        Cell: ({ row }: any) => {
          const workflowType = getIn(row, "original.WorkflowType");
          const title = WORKFLOW_TYPE_LABELS[workflowType];
          return (
            <p className="flex justify-center items-center text-primary bg-primaryLight rounded-full py-1">
              <span className="text-primary text-sm font-light px-2">
                {title}
              </span>
            </p>
          );
        },
      },
      {
        Header: "Workflow Name",
        accessor: "WorkflowTitle",
        Cell: ({ row }: any) => {
          const title = getIn(row, "original.WorkflowTitle");
          const rowId = getIn(row, "original.id");
          return (
            <Link key={rowId} to={`workflow/${rowId}`}>
              <span
                className="text-black text-sm font-light"
                style={{
                  width: "30ch",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
              >
                {title}
              </span>
            </Link>
          );
        },
      },
      {
        Header: "Description",
        Cell: ({ row }: any) => {
          const templateDescription = getIn(
            row,
            "original.WorkflowDescription"
          );
          return (
            <div
              className="text-sm text-black"
              dangerouslySetInnerHTML={{
                __html: sanitize(templateDescription),
              }}
              style={{
                width: "30ch",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            />
          );
        },
      },
      {
        Header: "Last Updated",
        Cell: ({ row }: any) => {
          const lastUpdated = getIn(row, "original.LastUpdated");
          const createdDate = getIn(row, "original.CreatedDate");
          return (
            <span className="text-black text-sm font-light">
              {lastUpdated
                ? dayjs(lastUpdated).format("DD.MM.YYYY HH:mm")
                : dayjs(createdDate).format("DD.MM.YYYY HH:mm")}
            </span>
          );
        },
      },
      {
        Header: "Status",
        Cell: ({ row }: any) => {
          const workflowStatus = getIn(row, "original.PublishedStatus");
          return (
            <span className="text-black text-sm font-light">
              {workflowStatus}
            </span>
          );
        },
      },
      {
        Header: () => null,
        id: "arrowLink",
        Cell: ({ row }: any) => {
          const rowId = getIn(row, "original.id");
          return (
            <Link key={rowId} to={`workflow/${rowId}`}>
              <ArrowForwardIcon className="w-6 h-6 md:w-7 md:h-7" />
            </Link>
          );
        },
      },
    ],
    []
  );

  return !workflowTemplates.length ? (
    <div>Loading...</div>
  ) : (
    <Table columns={columns} data={workflowTemplates} />
  );
}
