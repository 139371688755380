/**
 *
 * ManageUsers
 *
 */
import React from "react";
import { CustomButton } from "../../../components/UI/Forms/Buttons/CustomButton";
import UsersTable from "./UsersTable";
import { Link } from "react-router-dom";

//BACKEND return data
// AccessExpiryDate:
// EmailAddress:
// EmployeeIdentificationNumber:
// EntityType:
// FirstName:
// LastName:
// PK:
// PhoneNumber:
// Role:
// SK:
// SystemRole:
// Team:

export function ManageUsers() {
  return (
    <div className="max-w-screen-xl pt-10 px-10 xl:px-0">
      <div className="flex justify-between flex-col lg:flex-row">
        <h1 className=" text-3xl tracking-widest pb-5 lg:pb-0">
          User Management
        </h1>
        <div className="pb-5 lg:pb-0">
          <Link to="/admin/register">
            <CustomButton type="button" label="Add New Users" />
          </Link>
        </div>
      </div>
      <div className="flex flex-col items-center max-w-screen-xl">
        <div className=" w-full overflow-none ">
          <div className="py-2 align-middle inline-block min-w-full">
            <UsersTable />
          </div>
        </div>
      </div>
    </div>
  );
}
