import { createSlice } from "utils/@reduxjs/toolkit";
import { useInjectReducer, useInjectSaga } from "utils/redux-injectors";
import { IFormTemplate } from "../EFormBuilder";
import { eformSaga } from "./saga";
import { IEformState } from "./types";

export const initialState: IEformState = {
  isLoading: false,
  formTemplates: [],
  formTemplate: {
    TemplateName: "",
    TemplateDescription: "",
    PublishedStatus: "drafted",
    Template: [],
  },
};

const initialFormTemplate: IFormTemplate = {
  TemplateName: "",
  TemplateDescription: "",
  PublishedStatus: "drafted",
  Template: [],
};

const slice = createSlice({
  name: "eform",
  initialState,
  reducers: {
    registerFormTemplateRequest(state, { payload }) {
      state.isLoading = true;
      payload;
    },
    registerFormTemplateSuccess(state, { payload }) {
      state.isLoading = false;
      payload;
    },
    registerFormTemplateError(state, { payload }) {
      state.isLoading = false;
      payload;
    },
    updateFormTemplateRequest(state, { payload }) {
      state.isLoading = true;
      payload;
    },
    updateFormTemplateSuccess(state, { payload }) {
      state.isLoading = false;
      payload;
    },
    updateFormTemplateError(state, { payload }) {
      state.isLoading = false;
      payload;
    },
    getFormTemplatesRequest(state, { payload }) {
      state.isLoading = true;
      payload;
    },
    getFormTemplatesSuccess(state, { payload }) {
      state.formTemplates = payload.data;
      state.isLoading = false;
    },
    getFormTemplatesError(state, { payload }) {
      state.isLoading = false;
      payload;
    },
    getPublishedFormTemplatesRequest(state, { payload }) {
      state.isLoading = true;
      payload;
    },
    getPublishedFormTemplatesSuccess(state, { payload }) {
      state.publishedFormTemplates = payload.data.map((formTemplate) => {
        return {
          ...formTemplate,
          label: formTemplate.TemplateName,
          value: formTemplate.id,
        };
      });
      state.isLoading = false;
    },
    getPublishedFormTemplatesError(state, { payload }) {
      state.isLoading = false;
      payload;
    },
    getFormTemplateRequest(state, { payload }) {
      state.isLoading = true;
      payload;
    },
    getFormTemplateSuccess(state, { payload }) {
      payload;
      state.isLoading = false;
      state.formTemplate = payload.data;
    },
    getFormTemplateError(state, { payload }) {
      state.isLoading = false;
      payload;
    },
    deleteFormTemplateRequest(state, { payload }) {
      state.isLoading = true;
      payload;
    },
    deleteFormTemplateSuccess(state, { payload }) {
      payload;
      state.isLoading = false;
      state.formTemplate = { ...initialFormTemplate };
    },
    deleteFormTemplateError(state, { payload }) {
      state.isLoading = false;
      payload;
    },
    initializeFormTemplate(state) {
      state.formTemplate = { ...initialFormTemplate };
    },
    addElementOnFormTemplate(state, { payload }) {
      state.formTemplate.Template.push(payload.newItem);
    },
    removeElementOnFormTemplate(state, { payload }) {
      const { deletedId } = payload;

      state.formTemplate.Template = state.formTemplate.Template.filter(
        (element) => element.gridData.i !== deletedId
      );
    },
    updateElementGridDataOnFormTemplate(state, { payload }) {
      const { layout } = payload;
      state.formTemplate.Template = state.formTemplate.Template.map(
        (element) => {
          element.gridData = layout.find((el) => el.i === element.gridData.i);
          return element;
        }
      );
    },
    updateElementDataOnFormTemplate(state, { payload }) {
      const { elementId, newData } = payload;
      state.formTemplate.Template = state.formTemplate.Template.map(
        (element) => {
          if (element.gridData.i === elementId) {
            element.field = newData;
            state.selectedField.field = newData;
          }

          return element;
        }
      );
    },
    updateFormTemplateDetails(state, { payload }) {
      const { value, propKey, key } = payload;
      if (key) {
        state.formTemplate = {
          ...state.formTemplate,
          [key]: { ...state.formTemplate[key], [propKey]: value },
        };
      } else {
        state.formTemplate = {
          ...state.formTemplate,
          [propKey]: value,
        };
      }
    },
    setSelectedNode(state, { payload }) {
      const { selectedId } = payload;
      state.selectedField = state.formTemplate.Template.find(
        (element) => element.gridData.i === selectedId
      );
    },
    unsetSelectedNode(state) {
      state.selectedField = undefined;
    },
  },
});

export const { actions: eformActions } = slice;

export const useEformSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: eformSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useEformSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
