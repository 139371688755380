/**
 *
 * MyWorkPage
 *
 */
import { Wrapper } from "app/components/UI/Layout/Wrapper";
import { MyWork } from "app/containers/MyWork/Loadable";
import React from "react";
import { MyWorkTable } from "../../containers/MyWork/MyWorkTable";

export function MyWorkPage(): JSX.Element {
  return (
    <Wrapper>
      <MyWork />
      <MyWorkTable />
    </Wrapper>
  );
}
