import { Position } from "react-flow-renderer";
import { createSlice } from "utils/@reduxjs/toolkit";
import { useInjectReducer, useInjectSaga } from "utils/redux-injectors";
import { workflowSaga } from "./saga";
import { IWorkflowState } from "./types";

const initialElements = [
  {
    id: "1",
    type: "customNode",
    data: {
      label: "input node",
      step: 1,
      handlePosition: Position.Bottom,
    },
    position: { x: 200, y: 5 },
  },
  {
    id: "2",
    type: "customNode",
    data: {
      label: "output node",
      step: 2,
      handlePosition: Position.Top,
    },
    position: { x: 200, y: 305 },
  },
  {
    id: "edge-1-2",
    source: "1",
    target: "2",
    type: "buttonedge",
  },
];

export const initialState: IWorkflowState = {
  hasSelectedNode: false,
  workflowTemplates: [],
  workflowTemplate: {
    WorkflowDescription: "",
    WorkflowTitle: "",
    Template: initialElements,
  },
};

const slice = createSlice({
  name: "workflow",
  initialState,
  reducers: {
    registerWorkflowTemplateRequest(state, { payload }) {
      state;
      payload;
    },
    registerWorkflowTemplateSuccess(state, { payload }) {
      state;
      payload;
    },
    registerWorkflowTemplateError(state, { payload }) {
      state;
      payload;
    },
    getWorkflowTemplatesRequest(state, { payload }) {
      payload;
    },
    getWorkflowTemplatesSuccess(state, { payload }) {
      state.workflowTemplates = payload.data;
    },
    getWorkflowTemplatesError(state, { payload }) {
      payload;
    },
    getWorkflowTemplateRequest(state, { payload }) {
      state;
      payload;
    },
    getWorkflowTemplateSuccess(state, { payload }) {
      state.workflowTemplate = payload.data;
      state;
      payload;
    },
    getWorkflowTemplateError(state, { payload }) {
      state;
      payload;
    },
    updateWorkflowTemplateRequest(state, { payload }) {
      state;
      payload;
    },
    updateWorkflowTemplateSuccess(state, { payload }) {
      state;
      payload;
    },
    updateWorkflowTemplateError(state, { payload }) {
      state;
      payload;
    },
    deleteWorkflowTemplateRequest(state, { payload }) {
      state;
      payload;
    },
    deleteWorkflowTemplateSuccess(state, { payload }) {
      state;
      payload;
    },
    deleteWorkflowTemplateError(state, { payload }) {
      state;
      payload;
    },
    initializeWorkflowTemplate(state) {
      state.workflowTemplate = {
        Template: initialElements,
        WorkflowTitle: "",
        WorkflowDescription: "",
      };
    },
    addElementOnWorkflowTemplate(state, { payload }) {
      state.workflowTemplate.Template.push(payload.element);
    },
    removeElementOnWorkflowTemplate(state, { payload }) {
      const { elementsToRemove } = payload;
      for (const elementToRemove of elementsToRemove) {
        state.workflowTemplate.Template =
          state.workflowTemplate.Template.filter(
            (el) => el.id !== elementToRemove.id
          );
      }
    },
    updateElementOnWorkflowTemplate(state, { payload }) {
      const { elementId, newPosition, newData } = payload;
      state.workflowTemplate.Template = state.workflowTemplate.Template.map(
        (el) => {
          if (el.id === elementId) {
            el.position = newPosition;
            el.data = newData;
          }

          return el;
        }
      );
    },
    updateElementDataOnWorkflowTemplate(state, { payload }) {
      const { elementId, newData } = payload;
      state.workflowTemplate.Template = state.workflowTemplate.Template.map(
        (el) => {
          if (el.id === elementId) {
            el.data = newData;
          }

          return el;
        }
      );
      if (state.selectedNode) {
        state.selectedNode.data = newData;
      }
    },
    updateWorkflowTemplate(state, { payload }) {
      state.workflowTemplate = {
        ...state.workflowTemplate,
        Template: payload.newTemplate,
      };
    },
    updateWorkflowTemplateDetails(state, { payload }) {
      const { value, propKey, key } = payload;
      if (key) {
        state.workflowTemplate = {
          ...state.workflowTemplate,
          [key]: { ...state.workflowTemplate[key], [propKey]: value },
        };
      } else {
        state.workflowTemplate = {
          ...state.workflowTemplate,
          [propKey]: value,
        };
      }
    },
    setSelectedNode(state, { payload }) {
      const { selectedId } = payload;
      state.selectedNode = state.workflowTemplate.Template.find(
        (el) => el.id === selectedId
      );
      state.hasSelectedNode = true;
    },
    unsetSelectedNode(state) {
      state.selectedNode = undefined;
      state.hasSelectedNode = false;
    },
  },
});

export const { actions: workflowActions } = slice;

export const useWorkflowSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: workflowSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useWorkflowSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
