import { createSlice } from "utils/@reduxjs/toolkit";
import { useInjectReducer, useInjectSaga } from "utils/redux-injectors";
import authSaga from "./saga";
import { IAuthState, ISession } from "./types";

export const initialState: IAuthState = {
  session: {} as ISession,
  isLogged: false,
  isForgotPassword: false,
  isTempPassword: false,
  loading: true,
  error: null,
  cognitoGroups: ["User"],
};

const slice = createSlice({
  name: "authState",
  initialState,
  reducers: {
    loginUserRequest(state, actions) {
      state.loading = actions.payload.loading;
    },
    loginUserError(state, action) {
      state.error = action.payload;
      state.loading = false;
    },
    loginUserSuccess(state, { payload }) {
      state.session = payload.session;
      state.email = payload.email;
      state.cognitoGroups = payload.cognitoGroups;
      state.isLogged = true;
      state.loading = false;
      state.error = undefined;
    },
    getCurrentUserRequest(state, { payload }) {
      state.loading = true;
      payload;
    },
    getCurrentUserError(state, { payload }) {
      state.error = payload;
      state.loading = false;
    },
    getCurrentUserSuccess(state, { payload }) {
      state.currentUser = payload.currentUser;
      state.loading = false;
      state.error = undefined;
    },
    logoutUserRequest(state, action) {
      state.loading = action.payload.loading;
    },
    logoutUserSuccess(state) {
      state.session = {} as ISession;
      state.isLogged = false;
      state.loading = false;
      state.error = undefined;
    },
    getSession(state) {
      state.loading = true;
    },
    openForgotPassword(state, action) {
      state.isForgotPassword = action.payload.isForgotPassword;
      state.error = undefined;
    },
    openTempPasswordReset(state, actions) {
      state.attributes = actions.payload.attributes;
      state.isTempPassword = true;
      state.error = undefined;
      state.email = actions.payload.email;
    },
    forgotPasswordRequest(state, actions) {
      state.loading = actions.payload.loading;
    },
    forgotPasswordCodeSent(state, actions) {
      state.loading = false;
      state.forgotPasswordStep = actions.payload.forgotPasswordStep;
      state.error = undefined;
    },
    forgotPasswordError(state, actions) {
      state.error = actions.payload.error;
    },
    newPasswordRequest(state, actions) {
      state.attributes = actions.payload.attributes;
      state.loading = true;
      state.email = actions.payload.email;
      state.isTempPassword = true;
    },
    newPasswordSuccess(state) {
      state.loading = false;
      state.email = "";
      state.isTempPassword = false;
      state.error = undefined;
    },

    resetPasswordRequest(state, actions) {
      state.loading = true;
      state.forgotPasswordStep = actions.payload.forgotPasswordStep;
    },
    resetPasswordSuccess(state, actions) {
      state.forgotPasswordStep = actions.payload.forgotPasswordStep;
      state.isForgotPassword = false;
      state.loading = false;
      state.error = undefined;
    },
    resetPasswordError(state, actions) {
      state.forgotPasswordStep = actions.payload.forgotPasswordStep || null;
      state.error = actions.payload.error;
    },
    resetNotificationCount(state) {
      if (state.currentUser) {
        state.currentUser.NotificationCount = 0;
      }
    },
  },
});

export const { actions: authActions, reducer: authReducers } = slice;

export const useAuthSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({
    key: slice.name,
    saga: authSaga,
  });

  return { actions: slice.actions };
};
