/**
 *
 * Wrapper
 *
 */
import React from "react";
import PropTypes from "prop-types";
interface Props {
  children: PropTypes.ReactNodeLike;
}

export function Wrapper(props: Props) {
  return (
    <div className="max-w-screen-xl mb-20 px-10 xl:px-0">{props.children}</div>
  );
}
