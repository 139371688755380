import * as React from "react";

function SvgCaseAllocation(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 26 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.5 5.5V3h-5v2.5h5zM3 9.25V20.5c0 .688.563 1.25 1.25 1.25h17.5c.688 0 1.25-.563 1.25-1.25V9.25C23 8.562 22.437 8 21.75 8H4.25C3.562 8 3 8.563 3 9.25zM23 5.5c1.387 0 2.5 1.112 2.5 2.5v13.75c0 1.387-1.113 2.5-2.5 2.5H3a2.491 2.491 0 01-2.5-2.5L.512 8c0-1.388 1.1-2.5 2.488-2.5h5V3C8 1.613 9.113.5 10.5.5h5C16.887.5 18 1.613 18 3v2.5h5z"
        fill="#133092"
      />
    </svg>
  );
}

export default SvgCaseAllocation;
