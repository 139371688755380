/**
 *
 * ShortcutNotes
 *
 */
import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { VisitCaseNote } from "../VisitNote";
import { CarePlanningCaseNote } from "../CarePlanningNote";
import { CommunicationCaseNote } from "../CommunicationNote";
import { GeneralCaseNote } from "../GeneralNote";
import { OnlineMeetingCaseNote } from "../OnlineMeetingNote";
import { SafeguardingCaseNote } from "../SafeguardingNote";
import { ShortcutNotesProps } from "../types";

const noteTypeRenderer = (noteType, handleCancel, id) => {
  switch (noteType) {
    case "care-planning":
      return (
        <CarePlanningCaseNote
          shortcutNote={true}
          handleCancel={handleCancel}
          id={id}
        />
      );

    case "communication":
      return (
        <CommunicationCaseNote
          shortcutNote={true}
          handleCancel={handleCancel}
          id={id}
        />
      );

    case "general":
      return (
        <GeneralCaseNote
          shortcutNote={true}
          handleCancel={handleCancel}
          id={id}
        />
      );

    case "online-meeting":
      return (
        <OnlineMeetingCaseNote
          shortcutNote={true}
          handleCancel={handleCancel}
          id={id}
        />
      );

    case "safeguarding":
      return (
        <SafeguardingCaseNote
          shortcutNote={true}
          handleCancel={handleCancel}
          id={id}
        />
      );

    case "visit":
      return (
        <VisitCaseNote
          shortcutNote={true}
          handleCancel={handleCancel}
          id={id}
        />
      );
    default:
      return <></>;
  }
};

export const ShortcutNotes = (props: ShortcutNotesProps): JSX.Element => {
  const { open, setOpen, noteType } = props;
  const handleCancel = (event) => {
    setOpen(event);
  };
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block align-bottom bg-white rounded-lg px-10 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full sm:p-6">
              <div className="absolute top-11 right-16">
                <button type="button" onClick={() => setOpen(false)}>
                  <span className="sr-only">Close</span>
                  <i className="material-icons-round text-3xl">close</i>
                </button>
              </div>
              <div className="px-10">
                {noteTypeRenderer(noteType, handleCancel, props.id)}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
