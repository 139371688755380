import React from "react";
import { Redirect, Route, useLocation } from "react-router-dom";
import { ReactComponentLike } from "prop-types";
import { selectAuth } from "../Auth/slice/selectors";
import { useSelector } from "react-redux";
import { get as getIn } from "lodash";
import { toast } from "react-toastify";

interface Props {
  component: ReactComponentLike;
  rest?: any;
  exact?: any;
  path?: any;
  location?: any;
  accessRoles: string[];
}

const checkRoleAccess = (accessRoles, userRoles) => {
  let res = false;
  for (const role of userRoles) {
    res = accessRoles.includes(role);
    if (res) break;
  }
  return res;
};

const GuardedRoute = ({
  component: Component,
  accessRoles,
  ...rest
}: Props) => {
  const authState = useSelector(selectAuth);
  const cognitoGroups = getIn(authState, "cognitoGroups", ["User"]);

  const location = useLocation();

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!authState.loading) {
          if (
            authState.session &&
            authState.isLogged &&
            checkRoleAccess(accessRoles, cognitoGroups)
          ) {
            return <Component {...props} />;
          } else {
            toast.info(
              "You don't have the right permissions for accessing this page."
            );
            return (
              <Redirect
                to={{
                  pathname: "/",
                  state: { from: location },
                }}
              />
            );
          }
        }
      }}
    />
  );
};

export default GuardedRoute;
