/**
 *
 * CustomTextarea
 *
 */

import React from "react";

interface Props {
  inputId: string;
  inputLabel: string;
  inputName: string;
  inputType?: string;
  inputValue?: string;
  placeholder?: string;
  isRequired?: boolean;
  inputError?: string;
  minDate?: string;
  onChange?(event): void;
  disableAutoComplete?: boolean;
  inputTestId?: string;
}

export const CustomTextarea = (props: Props) => {
  return (
    <div>
      <div className="flex outline relative rounded-md border-2 border-gray-200 focus-within:border-2 focus-within:border-blue-500">
        <textarea
          rows={8}
          cols={50}
          className={`block pl-4 pt-4 w-full text-base text-black border-none rounded-md focus:outline-none leading-8 pb-0`}
          id={props.inputId}
          name={props.inputName}
          required={props.isRequired}
          placeholder=" "
          onChange={(event) =>
            props.onChange ? props.onChange(event.target.value) : undefined
          }
          value={props.inputValue}
          data-testid={props.inputTestId}
        />
        <label
          htmlFor={props.inputId}
          className="absolute pl-4 top-0 bg-white w-full rounded-md text-base text-secondaryText duration-300"
        >
          {/* Include the required mark  */}
          {props.isRequired ? `${props.inputLabel}*` : props.inputLabel}
        </label>
      </div>
      {props.inputError && <span>{props.inputError}</span>}
    </div>
  );
};
