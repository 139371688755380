import { WORKFLOW_TYPE_LABELS } from "app/containers/Workflow/constants";

export const CASE_NOTES_TYPES = [
  { title: "Care Planning Note", value: "care-planning" },
  { title: "Communication Note", value: "communication" },
  { title: "General Note", value: "general" },
  { title: "Online Meeting Note", value: "online-meeting" },
  { title: "Safeguarding Note", value: "safeguarding" },
  { title: "Visit Note", value: "visit" },
];
export const CASE_NOTES_TYPES_MAP = {
  CarePlanning: { title: "Care planning", value: "care-planning" },
  Communication: { title: "Communication", value: "communication" },
  General: { title: "General", value: "general" },
  OnlineMeeting: { title: "Online meeting", value: "online-meeting" },
  Safeguarding: { title: "Safeguarding", value: "safeguarding" },
  Visit: { title: "Visit", value: "visit" },
};

export const CARE_PLANING = "CarePlanning";
export const COMMUNICATION = "Communication";
export const GENERAL = "General";
export const ONLINE_MEETING = "OnlineMeeting";
export const SAFE_GUARDING = "Safeguarding";
export const VISIT = "Visit";

export const CASE_FILE_TYPE_LABELS = {
  CarePlanning: "Care planning",
  Communication: "Communication",
  General: "General",
  OnlineMeeting: "Online meeting",
  Safeguarding: "Safeguarding",
  Visit: "Visit",
  ...WORKFLOW_TYPE_LABELS,
};
