/**
 *
 * LoadingSpinner
 *
 */
import React from "react";

export function LoadingSpinner() {
  return (
    <div className="lds-ring">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}
