import React from "react";
import ReactDOM from "react-dom";
import { CustomModal } from "app/components/CustomModal";

const RouterPrompt = (message, callback, confirmOpen, setConfirmOpen) => {
  const container = document.createElement("div");

  container.setAttribute("custom-confirm-view", "");

  const handleConfirm = () => {
    ReactDOM.unmountComponentAtNode(container);
    callback(true);
    setConfirmOpen(false);
  };

  const handleCancel = () => {
    ReactDOM.unmountComponentAtNode(container);
    callback(false);
    setConfirmOpen(false);
  };

  document.body.appendChild(container);

  ReactDOM.render(
    <CustomModal
      title="You have some unsaved changes on this page"
      message="You have made some changes on this page that will be lost. Are you sure you want to go back?"
      open={confirmOpen}
      setOpen={setConfirmOpen}
      positiveButtonText={"STAY ON THIS PAGE"}
      handlePositiveClick={handleCancel}
      cancelButtonText={"YES, GO BACK"}
      handleCancelClick={handleConfirm}
      handleCrossClick={handleCancel}
    />,

    container
  );
};

export default RouterPrompt;
