import * as React from "react";

function CircledCheckMark(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      {...props}
    >
      <path
        d="M5.75645 10.6436L5.75706 10.6442L8.64706 13.5242L9.00061 13.8765L9.35355 13.5236L16.2336 6.64355C16.4283 6.44882 16.7417 6.44882 16.9364 6.64355C17.1312 6.83829 17.1312 7.15171 16.9364 7.34645L9.34645 14.9364L9.34642 14.9364L9.34188 14.9411C9.16065 15.1271 8.84087 15.1338 8.64355 14.9364L5.05355 11.3464C4.85882 11.1517 4.85882 10.8383 5.05355 10.6436C5.24829 10.4488 5.56171 10.4488 5.75645 10.6436ZM11 0.5C5.20386 0.5 0.5 5.20386 0.5 11C0.5 16.7961 5.20386 21.5 11 21.5C16.7961 21.5 21.5 16.7961 21.5 11C21.5 5.20386 16.7961 0.5 11 0.5Z"
        fill="white"
        stroke="black"
      />
    </svg>
  );
}

export default CircledCheckMark;
