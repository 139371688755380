import * as React from "react";

function SvgEditPenNoFill(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.5 17.5h1.1L13.675 6.425l-1.1-1.1L1.5 16.4v1.1zM16.85 5.35l-3.2-3.2L14.7 1.1a1.426 1.426 0 011.05-.425c.417 0 .767.141 1.05.425l1.1 1.1c.283.283.425.633.425 1.05 0 .416-.142.766-.425 1.05l-1.05 1.05zM15.8 6.4L3.2 19H0v-3.2L12.6 3.2l3.2 3.2zm-2.675-.525l-.55-.55 1.1 1.1-.55-.55z"
        fill="#000"
      />
    </svg>
  );
}

export default SvgEditPenNoFill;
