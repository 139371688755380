/**
 *
 * AboutCoreRecord
 *
 */
import React, { Fragment, useState, useEffect } from "react";
import CustomRichText from "app/components/UI/Forms/Inputs/CustomRichText";
import { Wrapper } from "app/components/UI/Layout/Wrapper";
import { Disclosure, Transition } from "@headlessui/react";
import { ExpandLessIcon, ExpandMoreIcon } from "app/components/icons/";
import { useCoreRecordSlice } from "../../slice";
import { useDispatch, useSelector } from "react-redux";
import { selectCoreRecord } from "../../slice/selectors";
import { CustomButton } from "app/components/UI/Forms/Buttons/CustomButton";
import { selectAuth } from "app/containers/Auth/slice/selectors";
import { useParams } from "react-router-dom";
import { CustomFileUpload } from "app/components/CustomFileUpload";
import { CustomComponentWithHover } from "app/components/CustomComponentWithHover";
import dayjs from "dayjs";
import { CustomModal, IModalProps } from "../../../../components/CustomModal";
import { CoreRecoridInfoDetails } from "app/components/CoreRecoridInfoDetails";

interface IRouteParams {
  id: string;
}

export function AboutCoreRecord() {
  const { actions: coreRecordActions } = useCoreRecordSlice();
  const coreRecordState = useSelector(selectCoreRecord);
  const [filesToUpload, setFilesToUpload] = useState([] as any);
  const [modalProps, setModalProps] = useState({} as IModalProps);
  const [triggerModal, setTriggerModal] = useState(false);
  const authState = useSelector(selectAuth);
  const dispatch = useDispatch();
  const { id } = useParams<IRouteParams>();

  useEffect(() => {
    dispatch(
      coreRecordActions.getCoreRecordRequest({
        partitionKey: id,
        idToken: authState.session?.idToken,
      })
    );
    return () => {
      dispatch(coreRecordActions.resetCoreRecordState());
    };
  }, []);

  const handleUpdateAboutMe = (value, keyLabel) => {
    dispatch(coreRecordActions.updateAboutMe({ value, keyLabel }));
  };

  const handleAboutMeSave = (event) => {
    event.preventDefault();
    if (filesToUpload.length > 0) {
      dispatch(
        coreRecordActions.uploadAboutMeFilesRequest({
          idToken: authState.session?.idToken,
          filesToUpload,
          aboutMe: {
            ...coreRecordState.coreRecordDetails?.AboutMe,
            id: coreRecordState.coreRecordDetails?.id,
          },
          setFilesToUpload,
        })
      );
      return;
    }
    dispatch(
      coreRecordActions.registerAboutMeRequest({
        idToken: authState.session?.idToken,
        aboutMe: {
          ...coreRecordState.coreRecordDetails?.AboutMe,
          id: coreRecordState.coreRecordDetails?.id,
        },
      })
    );
  };
  const saveAboutMeModal = (event) => {
    setModalProps({
      title: `Are you sure you want to save the about me – this will overwrite existing data `,
      message: "",
      positiveButtonText: "SAVE CHANGES",
      handlePositiveClick: () => handleAboutMeSave(event),
    });
    setTriggerModal(true);
  };

  const handleRemoveUploadedFile = (questionKey, fileId) => {
    dispatch(coreRecordActions.removeAboutMeFile({ questionKey, fileId }));
  };

  const handleDownloadUploadedFile = (file) => {
    dispatch(
      coreRecordActions.downloadFileRequest({
        file,
        idToken: authState.session?.idToken,
      })
    );
  };

  const handleRemoveFileToUpload = (questionKey, fileName) => {
    const newFilesToUpload = filesToUpload.filter((fileToUpload) => {
      if (fileToUpload.key !== questionKey) return fileToUpload;
      if (fileToUpload.file[0].name !== fileName) return fileToUpload;
    });
    setFilesToUpload(newFilesToUpload);
  };

  const handleSetFilesToUpload = (file, questionKey) => {
    setFilesToUpload((prevArr) => [...prevArr, { key: questionKey, file }]);
    dispatch(coreRecordActions.updateAboutMeLastUpdatedTime({ questionKey }));
  };
  return (
    coreRecordState && (
      <Wrapper>
        <CoreRecoridInfoDetails {...coreRecordState.coreRecordDetails} />
        <div className="flex flex-col items-start w-full mt-10">
          <h1 className="tracking-wider pb-4 md:pb-8 border-b-2 border-primary w-full md:border-0 text-xl md:text-2xl font-semibold">
            About Me
          </h1>
          {questionsList.map((question) => {
            return (
              <div
                key={question.questionLabel}
                className="w-full py-4 border-b-2 border-primary md:border-0"
              >
                <Disclosure defaultOpen={true}>
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="flex flex-wrap text-left justify-between items-center w-full py-2">
                        <h1 className="flex items-center text-lg md:text-xl font-semibold leading-tight">
                          {question.question}
                          {open ? (
                            <ExpandLessIcon
                              className="w-12 h-12 md:w-8 md:h-8"
                              data-testid="expand-less-icon"
                            />
                          ) : (
                            <ExpandMoreIcon
                              className="w-12 h-12 md:w-8 md:h-8"
                              data-testid="expand-more-icon"
                            />
                          )}
                        </h1>
                        <div>
                          {coreRecordState.coreRecordDetails?.AboutMe[
                            question.questionLabel
                          ]?.lastUpdatedTime && (
                            <>
                              <span className="text-secondaryText text-sm">
                                Updated:
                              </span>{" "}
                              <span className="font-bold text-sm">
                                {dayjs(
                                  coreRecordState.coreRecordDetails?.AboutMe[
                                    question.questionLabel
                                  ]?.lastUpdatedTime
                                ).format("DD.MM.YYYY HH:mm") || ""}
                              </span>{" "}
                            </>
                          )}
                        </div>
                      </Disclosure.Button>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                      >
                        <Disclosure.Panel className="w-full mb-10">
                          <p className="text-black text-sm mb-4">
                            {question.questionDescription}
                          </p>
                          <CustomRichText
                            value={
                              coreRecordState.coreRecordDetails?.AboutMe[
                                question.questionLabel
                              ]?.value || ""
                            }
                            setValue={handleUpdateAboutMe}
                            name={question.questionLabel}
                            testId={`${question.questionLabel}-rich-text`}
                          />
                          <div className="flex flex-row overflow-x-auto mt-6">
                            {coreRecordState.coreRecordDetails?.AboutMe[
                              question.questionLabel
                            ]?.files?.map((file) => {
                              return (
                                <CustomComponentWithHover
                                  key={file.fileId}
                                  fileSRC={file.src}
                                  fileType={file.fileType}
                                  handleDelete={() =>
                                    handleRemoveUploadedFile(
                                      question.questionLabel,
                                      file.fileId
                                    )
                                  }
                                  handleFileDownload={() =>
                                    handleDownloadUploadedFile(file)
                                  }
                                >
                                  {file.fileName}
                                </CustomComponentWithHover>
                              );
                            })}
                            {filesToUpload.map((fileToUpload) => {
                              if (fileToUpload.key !== question.questionLabel)
                                return;
                              return (
                                <CustomComponentWithHover
                                  key={fileToUpload.file[0].name}
                                  fileSRC={fileToUpload.file[0].src}
                                  fileType={fileToUpload.file[0].type}
                                  handleDelete={() =>
                                    handleRemoveFileToUpload(
                                      question.questionLabel,
                                      fileToUpload.file[0].name
                                    )
                                  }
                                >
                                  {fileToUpload.file[0].name}
                                </CustomComponentWithHover>
                              );
                            })}
                          </div>
                          <div className="mt-6 w-1/3">
                            <CustomFileUpload
                              inputName={question.questionLabel}
                              filesToUpload={filesToUpload}
                              setFiles={(file) => {
                                handleSetFilesToUpload(
                                  file,
                                  question.questionLabel
                                );
                              }}
                            />
                          </div>
                        </Disclosure.Panel>
                      </Transition>
                    </>
                  )}
                </Disclosure>
              </div>
            );
          })}
          <div className="mt-10 sticky bottom-0 left-0 right-0 flex p-4 pl-0 w-full bg-primaryBG border-t-2 border-BG">
            <div className={"w-full md:w-1/3"}>
              <CustomButton
                label="SAVE CHANGES"
                type="button"
                handleClick={saveAboutMeModal}
              />
            </div>
          </div>
        </div>
        <CustomModal
          open={triggerModal}
          setOpen={setTriggerModal}
          {...modalProps}
        />
      </Wrapper>
    )
  );
}

const questionsList = [
  {
    question: "What do people like about me?",
    questionDescription:
      "Short description of what I think people like about me, what I’m proud of about myself, what my strengths are, and what I like about myself.",
    questionLabel: "likeAbout",
  },
  {
    question: "What is most important to me?",
    questionDescription:
      "A description of what is most important to you. This could include values, spirituality/religion, ethnicity, culture, pets, goals and aspirations, meaningful activities including leisure activities, visiting places, sport and exercise, listening to music, employment, education, volunteering.",
    questionLabel: "mostImportantAbout",
  },
  {
    question: "What's important to know about me?",
    questionDescription:
      "A summary of important information about the care user's needs and situation from the perspectives of their families, friends, support workers, and others who care about and for them.",
    questionLabel: "importantToKnowAbout",
  },
  {
    question: "People who are important to me",
    questionDescription:
      "Details of who is important to you and why. This could be family members, carers, friends, members of staff, etc. Include how you want the people important to you to be engaged and involved in your care and support in both emergency and normal situations, Include how you stay connected to the people important to you. Who should not be contacted or consulted about your care and support and why, if you wish to say.",
    questionLabel: "importantToMe",
  },
  {
    question: "Emergency information",
    questionDescription:
      "Include any essential information that any professional in health and social care should know about you in any situation, including an emergency.",
    questionLabel: "emergencyInfo",
  },
  {
    question: "My communication passport",
    questionDescription:
      "A description of how you communicate normally, including any communication aids you use, for example, a hearing aid. Include your preferred language of communication, if your first language is not English. Include how you would communicate when you are in pain or distress. Include how you communicate choices. Include how you give feedback or raise a concern. Include how you would like to receive information. Describe how you would like others to engage and communicate with you, including how you would like to be addressed.",
    questionLabel: "communicationPassport",
  },
  {
    question: "My wellness",
    questionDescription:
      "A description covering what you are able to do, how you engage with others, and how you feel on a typical day through to on a day when you are unwell or really unwell. Include any causes that might result in you becoming unwell and strategies for avoiding or addressing the causes. For example, not drinking enough water could cause constipation. Include any signs that indicate you might be becoming unwell. On a bad day describe what is different about what you are able to do, how you engage with others, and how you feel. Include how your everyday life is affected by any medical conditions e.g. dementia and any symptoms e.g. itchiness, cough, pain, and how you manage those conditions. Include past health issues or experiences that need to be considered Include your well-being and lifestyle goals and aspirations.",
    questionLabel: "myWellness",
  },
  {
    question: "Please do and please don’t",
    questionDescription:
      "A description of things you want someone supporting you to do or not to do. For example, this might include: talk to me and not to my carer, remind me to take my medication, encourage me to wash my hands regularly, explain to me what is happening and why, respond to my communication. A description of things you do not want someone supporting for you to do. For example, this might include: discussing or asking questions about certain topics, making assumptions about something, providing support when it is not wanted, talking to you in a certain way.",
    questionLabel: "doAndDont",
  },
  {
    question: "How and when to support me",
    questionDescription:
      "A description of how and when you want someone caring for you to support you. This could include support needs in an emergency situation (for example taking blood). This could include the support you need to maintain important routines or to carry out particular activities, for example: - Personal care routines, Eating and drinking, Bedtime routines, Taking medications, Moving, and transitioning. This could also include support needed with:- wearing glasses/hearing aids/false teeth etc, making informed choices or understanding dangers and risks, managing your emotions, moods, and behaviors, memory or confusion. Include how your support needs change in different environments. Include any triggers that might result in you needing further support and strategies for avoiding or addressing the triggers. Include how you want the support to be provided.",
    questionLabel: "howToSupportMe",
  },
  {
    question: "Also worth knowing about me",
    questionDescription:
      "A description of what is also worth knowing about you for people caring or supporting you. This could include a short history of your life (where you have worked, where you lived, important events in your life, important people in your past life). This could include a short profile of your current life:- your work / study, your aspirations, your skills, your networks, things you like e.g. particular foods, places, a football team and things you like to talk about, things you dislike. This could also include any care and support preferences that have not been included elsewhere.",
    questionLabel: "worthKnowing",
  },
  {
    question: "My care history",
    questionDescription:
      "A description of what is also worth knowing about you for people caring or supporting you. This could include a short history of your life (where you have worked, where you lived, important events in your life, important people in your past life). This could include a short profile of your current life:- your work / study, your aspirations, your skills, your networks, things you like e.g. particular foods, places, a football team and things you like to talk about, things you dislike. This could also include any care and support preferences that have not been included elsewhere.",
    questionLabel: "myCareHistory",
  },
];
