import React from "react";
import { Route, Switch } from "react-router-dom";
import { ManageUsers } from "app/containers/Admin/ManageUsers";
import { NotFoundPage } from "./pages/NotFoundPage/Loadable";
import { UserRegistration } from "./containers/Admin/UserRegistration";
import GuardedRoute from "./containers/GuardedRoutes";
import { AdminDashboard } from "./pages/AdminDashboard/Loadable";
import { AuthPage } from "./pages/AuthPage/Loadable";
import { CoreRecordsPage } from "./pages/CoreRecordsPage";
import { RegisterCoreRecord } from "app/containers/CoreRecords/CoreRecord/RegisterCoreRecord";
import { ManageCoreRecords } from "app/containers/CoreRecords/CoreRecord/ManageCoreRecords";
import { UserEdit } from "./containers/Admin/UserEdit";
import { ViewCoreRecord } from "./containers/CoreRecords/CoreRecord/ViewCoreRecord";
import { FormBuilderPage } from "./pages/FormBuilder";
import { EFormBuilder } from "./containers/EForm/EFormBuilder";
import { AboutCoreRecord } from "./containers/CoreRecords/CoreRecord/AboutCoreRecord";
import { CoreRecordConnections } from "./containers/CoreRecords/MyConnections/CoreRecordConnections";
import { PersonalConnections } from "./containers/CoreRecords/MyConnections/PersonalConnections";
import { EFormDesignerPage } from "./pages/EFormDesignerPage";
import { WorkflowPage } from "./pages/WorkflowPage";
import { WorkflowWrapper } from "./containers/Workflow/WorkflowWrapper/Loadable";
import { MyWorkPage } from "./pages/MyWorkPage";
import { StartWorkflow } from "./containers/MyWork/StartWorkflow";
import { MyWorkStepper } from "./containers/MyWork/MyWorkStepper/Loadable";
import { CaseFiles } from "./containers/CoreRecords/Case";
import { AddProfessionalConnection } from "./containers/CoreRecords/MyConnections/AddProfessionalConnection";
import { CaseNotes } from "./containers/CoreRecords/CaseNotes";
import { GeneralCaseNote } from "./containers/CoreRecords/CaseNotes/GeneralNote";
import { VisitCaseNote } from "./containers/CoreRecords/CaseNotes/VisitNote";
import { OnlineMeetingCaseNote } from "./containers/CoreRecords/CaseNotes/OnlineMeetingNote";
import { SafeguardingCaseNote } from "./containers/CoreRecords/CaseNotes/SafeguardingNote";
import { CommunicationCaseNote } from "./containers/CoreRecords/CaseNotes/CommunicationNote";
import { CarePlanningCaseNote } from "./containers/CoreRecords/CaseNotes/CarePlanningNote";
import { EditProfessionalConnection } from "./containers/CoreRecords/MyConnections/EditProfessionalConnection";
import { EditPersonalConnection } from "./containers/CoreRecords/MyConnections/EditPersonalConnections";
import { EditCarePlanningCaseNote } from "./containers/CoreRecords/CaseNotes/EditCarePlanningNote";
import { EditCommunicationCaseNote } from "./containers/CoreRecords/CaseNotes/EditCommunicationNote";
import { EditGeneralCaseNote } from "./containers/CoreRecords/CaseNotes/EditGeneralNote";
import { EditOnlineMeetingCaseNote } from "./containers/CoreRecords/CaseNotes/EditOnlineMeetingNote";
import { EditSafeguardingCaseNote } from "./containers/CoreRecords/CaseNotes/EditSafeguardingNote";
import { EditVisitCaseNote } from "./containers/CoreRecords/CaseNotes/EditVisitNote";
import { CasePermission } from "./containers/CoreRecords/CasePermission/Loadable";
import { CaseAllocation } from "./containers/CoreRecords/Case/CaseAllocation";

/**
 * Routes component containing routes for the whole application
 * @returns {JSX}
 */
const Routes = () => (
  <Switch>
    <Route exact path="/" component={AuthPage} />
    <GuardedRoute
      exact
      path="/admin"
      component={AdminDashboard}
      accessRoles={["Administrator", "Manager"]}
    />
    <GuardedRoute
      exact
      path={`/admin/register`}
      component={UserRegistration}
      accessRoles={["Administrator", "Manager"]}
    />
    <GuardedRoute
      exact
      path={`/admin/users/:id`}
      component={UserEdit}
      accessRoles={["Administrator", "Manager"]}
    />
    <GuardedRoute
      exact
      path={`/admin/users`}
      component={ManageUsers}
      accessRoles={["Administrator", "Manager"]}
    />
    <GuardedRoute
      exact
      path={`/eform`}
      component={EFormDesignerPage}
      accessRoles={["Administrator"]}
    />
    <GuardedRoute
      exact
      path={`/eform/form`}
      component={FormBuilderPage}
      accessRoles={["Administrator"]}
    />
    <GuardedRoute
      exact
      path={`/eform/form/builder`}
      component={EFormBuilder}
      accessRoles={["Administrator"]}
    />
    <GuardedRoute
      exact
      path={`/eform/form/:id`}
      component={EFormBuilder}
      accessRoles={["Administrator"]}
    />
    <GuardedRoute
      exact
      path={`/eform/workflow`}
      component={WorkflowPage}
      accessRoles={["Administrator"]}
    />
    <GuardedRoute
      exact
      path={`/eform/workflow/builder`}
      component={WorkflowWrapper}
      accessRoles={["Administrator"]}
    />
    <GuardedRoute
      exact
      path={`/eform/workflow/:id`}
      component={WorkflowWrapper}
      accessRoles={["Administrator"]}
    />
    <GuardedRoute
      exact
      path={`/core-record`}
      component={CoreRecordsPage}
      accessRoles={["Administrator", "Manager", "User"]}
    />
    <GuardedRoute
      exact
      path={`/core-record/register`}
      component={RegisterCoreRecord}
      accessRoles={["Administrator", "Manager", "User"]}
    />
    <GuardedRoute
      exact
      path={`/core-record/manage`}
      component={ManageCoreRecords}
      accessRoles={["Administrator", "Manager", "User"]}
    />
    <GuardedRoute
      exact
      path={`/core-record/manage/:id`}
      component={ViewCoreRecord}
      accessRoles={["Administrator", "Manager", "User"]}
    />
    <GuardedRoute
      exact
      path={`/core-record/manage/:id/edit`}
      component={RegisterCoreRecord}
      accessRoles={["Administrator", "Manager", "User"]}
    />
    <GuardedRoute
      exact
      path={`/core-record/manage/:id/about`}
      component={AboutCoreRecord}
      accessRoles={["Administrator", "Manager", "User"]}
    />
    <GuardedRoute
      exact
      path={`/core-record/manage/:id/case-files`}
      component={CaseFiles}
      accessRoles={["Administrator", "Manager", "User"]}
    />
    <GuardedRoute
      exact
      path={`/core-record/manage/:id/permission`}
      component={CasePermission}
      accessRoles={["Administrator", "Manager", "User"]}
    />
    <GuardedRoute
      exact
      path={`/core-record/manage/:id/case-notes`}
      component={CaseNotes}
      accessRoles={["Administrator", "Manager", "User"]}
    />
    <GuardedRoute
      exact
      path={`/core-record/manage/:id/case-notes/general`}
      component={GeneralCaseNote}
      accessRoles={["Administrator", "Manager", "User"]}
    />
    <GuardedRoute
      exact
      path={`/core-record/manage/:id/case-notes/general/:caseNoteId`}
      component={EditGeneralCaseNote}
      accessRoles={["Administrator", "Manager", "User"]}
    />
    <GuardedRoute
      exact
      path={`/core-record/manage/:id/case-notes/visit`}
      component={VisitCaseNote}
      accessRoles={["Administrator", "Manager", "User"]}
    />
    <GuardedRoute
      exact
      path={`/core-record/manage/:id/case-notes/visit/:caseNoteId`}
      component={EditVisitCaseNote}
      accessRoles={["Administrator", "Manager", "User"]}
    />
    <GuardedRoute
      exact
      path={`/core-record/manage/:id/case-notes/safeguarding`}
      component={SafeguardingCaseNote}
      accessRoles={["Administrator", "Manager", "User"]}
    />
    <GuardedRoute
      exact
      path={`/core-record/manage/:id/case-notes/safeguarding/:caseNoteId`}
      component={EditSafeguardingCaseNote}
      accessRoles={["Administrator", "Manager", "User"]}
    />
    <GuardedRoute
      exact
      path={`/core-record/manage/:id/case-notes/communication`}
      component={CommunicationCaseNote}
      accessRoles={["Administrator", "Manager", "User"]}
    />
    <GuardedRoute
      exact
      path={`/core-record/manage/:id/case-notes/communication/:caseNoteId`}
      component={EditCommunicationCaseNote}
      accessRoles={["Administrator", "Manager", "User"]}
    />
    <GuardedRoute
      exact
      path={`/core-record/manage/:id/case-notes/online-meeting`}
      component={OnlineMeetingCaseNote}
      accessRoles={["Administrator", "Manager", "User"]}
    />
    <GuardedRoute
      exact
      path={`/core-record/manage/:id/case-notes/online-meeting/:caseNoteId`}
      component={EditOnlineMeetingCaseNote}
      accessRoles={["Administrator", "Manager", "User"]}
    />
    <GuardedRoute
      exact
      path={`/core-record/manage/:id/case-notes/care-planning`}
      component={CarePlanningCaseNote}
      accessRoles={["Administrator", "Manager", "User"]}
    />
    <GuardedRoute
      exact
      path={`/core-record/manage/:id/case-notes/care-planning/:caseNoteId`}
      component={EditCarePlanningCaseNote}
      accessRoles={["Administrator", "Manager", "User"]}
    />
    <GuardedRoute
      exact
      path={`/core-record/manage/:id/connections`}
      component={CoreRecordConnections}
      accessRoles={["Administrator", "Manager", "User"]}
    />
    <GuardedRoute
      exact
      path={`/core-record/manage/:id/connections/personal`}
      component={PersonalConnections}
      accessRoles={["Administrator", "Manager", "User"]}
    />
    <GuardedRoute
      exact
      path={`/core-record/manage/:id/connections/professional`}
      component={AddProfessionalConnection}
      accessRoles={["Administrator", "Manager", "User"]}
    />
    <GuardedRoute
      exact
      path={`/core-record/manage/:id/connections/personal/:conId`}
      component={EditPersonalConnection}
      accessRoles={["Administrator", "Manager", "User"]}
    />
    <GuardedRoute
      exact
      path={`/core-record/manage/:id/connections/professional/:conId`}
      component={EditProfessionalConnection}
      accessRoles={["Administrator", "Manager", "User"]}
    />
    <GuardedRoute
      exact
      path={`/mywork`}
      component={MyWorkPage}
      accessRoles={["Administrator", "Manager", "User"]}
    />
    <GuardedRoute
      exact
      path={`/mywork/new`}
      component={StartWorkflow}
      accessRoles={["Administrator", "Manager", "User"]}
    />
    <GuardedRoute
      exact
      path={`/mywork/:id`}
      component={MyWorkStepper}
      accessRoles={["Administrator", "Manager", "User"]}
    />
    <GuardedRoute
      exact
      path={`/case-allocation`}
      component={CaseAllocation}
      accessRoles={["Administrator", "Manager"]}
    />
    <Route component={NotFoundPage} />
  </Switch>
);

export default Routes;
