/**
 *
 * StartWorkflow
 *
 */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { PublishedWorkflowsTable } from "../PublishedWorkflowsTable";
import { useMyWorkSlice } from "../slice";
import { selectMyWork } from "../slice/selectors";

export function StartWorkflow() {
  const { actions: myWorkActions } = useMyWorkSlice();
  const { myWorkDetails } = useSelector(selectMyWork);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(myWorkActions.unsetMyWorkDetails());
    };
  }, []);

  useEffect(() => {
    if (myWorkDetails) {
      history.replace(`${myWorkDetails.id}`);
    }
  }, [myWorkDetails]);

  return <PublishedWorkflowsTable />;
}
