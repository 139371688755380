import * as React from "react";

function SvgPhoneDisabled(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="1em" width="1em" {...props}>
      <path d="M17.05 14.3l-1.4-1.4q.275-.3.75-1.037.475-.738.6-.963l-2.45-2.5q-.2-.2-.275-.438-.075-.237-.025-.462l.7-3.7q.075-.35.338-.575Q15.55 3 15.9 3H20q.45 0 .725.3.275.3.275.75 0 2.75-1.05 5.362-1.05 2.613-2.9 4.888zM3.95 21q-.6 0-.775-.275Q3 20.45 3 20v-4.1q0-.35.225-.613.225-.262.575-.337l3.65-.75q.275-.05.513.025.237.075.437.275l2.5 2.5q.5-.3.975-.613.475-.312.925-.687L1.4 4.3l1.4-1.4 18.4 18.4-1.4 1.4-5.55-5.55q-2.225 1.8-4.887 2.825Q6.7 21 3.95 21z" />
    </svg>
  );
}

export default SvgPhoneDisabled;
