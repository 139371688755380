/**
 *
 * CoreRecordConnections
 *
 */
import React, { useEffect, memo } from "react";
import { Tab } from "@headlessui/react";
import { CoreRecoridInfoDetails } from "app/components/CoreRecoridInfoDetails/Loadable";
import { CustomButton } from "app/components/UI/Forms/Buttons/CustomButton/Loadable";
import { Wrapper } from "app/components/UI/Layout/Wrapper";
import { selectAuth } from "app/containers/Auth/slice/selectors";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams, useRouteMatch } from "react-router-dom";
import { useCoreRecordSlice } from "../../slice";
import { selectCoreRecord } from "../../slice/selectors";
import { ProfessionalConnectionsTable } from "../ProfessionalConnectionsTable/Loadable";
import { PersonalConnectionsTable } from "../PersonalConnectionsTable/Loadable";

interface IRouteParams {
  id: string;
}

export const CoreRecordConnections = memo(() => {
  const match = useRouteMatch();
  const { actions: coreRecordActions } = useCoreRecordSlice();
  const coreRecordState = useSelector(selectCoreRecord);
  const authState = useSelector(selectAuth);
  const dispatch = useDispatch();
  const { id } = useParams<IRouteParams>();

  const { coreRecordDetails } = coreRecordState;
  const professionalConnections = coreRecordState.professionalConnections || [];
  const personalConnections = coreRecordState.personalConnections || [];

  useEffect(() => {
    dispatch(
      coreRecordActions.getCoreRecordRequest({
        partitionKey: id,
        idToken: authState.session?.idToken,
      })
    );
    dispatch(
      coreRecordActions.getProfessionalConnectionsRequest({
        idToken: authState.session?.idToken,
        CrId: id,
      })
    );
    dispatch(
      coreRecordActions.getPersonalConnectionsRequest({
        idToken: authState.session?.idToken,
        CrId: id,
      })
    );
  }, []);

  return (
    <Wrapper>
      <CoreRecoridInfoDetails {...coreRecordDetails} />
      <div className="hidden lg:flex flex-col items-start w-full mt-10">
        <h1 className="tracking-wider pb-4 md:pb-8 border-b-2 border-primary w-full md:border-0 text-xl md:text-2xl font-semibold">
          My Connections
        </h1>
        <div className="text-l md:text-xl flex flex-col justify-between items-center w-full py-4 border-b-2 border-primary md:border-0">
          <div className="flex justify-between w-full">
            <p className={"font-semibold "}>Personal connections</p>
            <div className={"w-1/3"}>
              <Link to={`${match.url + "/personal"}`}>
                <CustomButton
                  type="button"
                  label="Add a personal connection"
                  testId="add-a-personal-connection-button"
                />
              </Link>
            </div>
          </div>
          <div className="w-full">
            <PersonalConnectionsTable connections={personalConnections} />
          </div>
        </div>
        <div className="text-l md:text-xl flex justify-between items-center w-full py-4 border-b-2 border-primary md:border-0">
          <div className="flex justify-between w-full">
            <p className={"font-semibold "}>Professional connections</p>
            <div className={"w-1/3"}>
              <Link to={`${match.url + "/professional"}`}>
                <CustomButton
                  type="button"
                  label="Add a professional connection"
                  testId="add-a-professinal-connection-button"
                />
              </Link>
            </div>
          </div>
        </div>
        <div className="w-full">
          <ProfessionalConnectionsTable connections={professionalConnections} />
        </div>
      </div>
      <div className="flex lg:hidden flex-col items-start w-full mt-10">
        <Tab.Group>
          <Tab.List className="flex gap-4 whitespace-nowrap items-center border-b border-primaryLight overflow-x-auto w-full">
            <Tab
              className={({ selected }) =>
                `w-full py-5 font-semibold tracking-wider ${
                  selected && "border-b-2 border-primary"
                }`
              }
            >
              Personal Connections
            </Tab>
            <Tab
              className={({ selected }) =>
                `w-full py-5 font-semibold tracking-wider ${
                  selected && "border-b-2 border-primary"
                }`
              }
            >
              Professional Connections
            </Tab>
          </Tab.List>
          <div className="overflow-y-auto w-full h-full">
            <Tab.Panels>
              <Tab.Panel>
                <div className="flex flex-col w-full overflow-hidden gap-4">
                  <PersonalConnectionsTable connections={personalConnections} />
                  <Link to={`${match.url + "/personal"}`}>
                    <CustomButton
                      type="button"
                      label="Add a personal connection"
                      testId="add-a-personal-connection-button"
                    />
                  </Link>
                </div>
              </Tab.Panel>
              <Tab.Panel>
                <div className="flex flex-col w-full overflow-hidden gap-4">
                  <ProfessionalConnectionsTable
                    connections={professionalConnections}
                  />
                  <Link to={`${match.url + "/professional"}`}>
                    <CustomButton
                      type="button"
                      label="Add a professional connection"
                      testId="add-connection-button"
                    />
                  </Link>
                </div>
              </Tab.Panel>
            </Tab.Panels>
          </div>
        </Tab.Group>
      </div>
    </Wrapper>
  );
});
