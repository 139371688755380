/**
 *
 * PersonalConnections
 *
 */
import React, { useEffect, useState } from "react";
import { get as getIn } from "lodash";
import { RadioGroup } from "@headlessui/react";
import { useForm, Controller } from "react-hook-form";
import nhsValidator from "nhs-number-validator";
import { Wrapper } from "app/components/UI/Layout/Wrapper";
import { selectAuth } from "app/containers/Auth/slice/selectors";
import { useSelector, useDispatch } from "react-redux";
import { Prompt, useParams } from "react-router-dom";
import { useCoreRecordSlice } from "../../slice";
import { selectCoreRecord } from "../../slice/selectors";
import { ArrowBackIcon } from "app/components/icons";
import { CoreRecoridInfoDetails } from "app/components/CoreRecoridInfoDetails/Loadable";
import { CustomButton } from "app/components/UI/Forms/Buttons/CustomButton/Loadable";
import { CustomInput } from "app/components/UI/Forms/Inputs/CustomInput/Loadable";
import { CustomSelect } from "app/components/UI/Forms/Inputs/CustomSelect/Loadable";
import { CustomCheckbox } from "app/components/UI/Forms/Inputs/CustomCheckbox/Loadable";
import { CustomRichText } from "app/components/UI/Forms/Inputs/CustomRichText/Loadable";
import { ControlledPhoneInput } from "app/components/UI/Forms/Inputs/ControlledPhoneInput/Loadable";

interface IRouteParams {
  id: string;
}

interface IErrors {
  nhsNumber?: string;
}

const isContactedOptions = [
  { id: "Yes", title: "Yes" },
  { id: "No", title: "No" },
  { id: "Not sure", title: "Not sure" },
];

const IsContactedOptions = isContactedOptions.map(({ id, title }) => (
  <RadioGroup.Option key={id} value={title} className={"mr-6"}>
    {() => (
      <>
        <input
          id={id}
          name="notification-method"
          type="radio"
          required
          className={"h-6 w-6 focus:ring-indigo-500  text-indigo-600"}
        />
        <label htmlFor={id} className="ml-3 font-semibold">
          <span className="text-base">{title}</span>
        </label>
      </>
    )}
  </RadioGroup.Option>
));

export function PersonalConnections() {
  const { actions: coreRecordActions } = useCoreRecordSlice();
  const coreRecordState = useSelector(selectCoreRecord);
  const authState = useSelector(selectAuth);
  const dispatch = useDispatch();
  const { id } = useParams<IRouteParams>();
  const [isBlocking, setIsBlocking] = useState(false);
  const [isContacted, setIsContacted] = useState("");
  const [hasParentalResponsibility, setHasParentalResponsibility] =
    useState(false);
  const [isHouseholdMember, setIsHouseholdMember] = useState(false);
  const [stateWhy, setStateWhy] = useState("");
  const [additionalComment, setAdditionalComment] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [nhsNumber, setNHSNumber] = useState("");
  const [firstName, setFirstName] = useState("");
  const [cmsCaseReferenceNumber, setCMSCaseReferenceNumber] = useState("");
  const [errors, setErrors] = useState({} as IErrors);

  const { handleSubmit, control } = useForm();

  const validateNHS = (nhsNumber): boolean => {
    if (nhsNumber.length === 10) {
      if (!nhsValidator.validate(nhsNumber)) {
        setErrors({ ...errors, nhsNumber: "Please insert a valid NHS Number" });
        return false;
      } else {
        setErrors({
          ...errors,
          nhsNumber: "",
        });
        return true;
      }
    } else {
      setErrors({
        ...errors,
        nhsNumber: "NHS Number should be 10 digits long",
      });
      return false;
    }
  };

  useEffect(() => {
    dispatch(
      coreRecordActions.getCoreRecordRequest({
        partitionKey: id,
        idToken: authState.session?.idToken,
      })
    );
    dispatch(
      coreRecordActions.getRelationshipsData({
        idToken: authState.session?.idToken,
      })
    );
    return () => {
      dispatch(coreRecordActions.resetCoreRecordState());
    };
  }, []);

  const { coreRecordDetails, relationshipData } = coreRecordState;

  const submitHandler = (event) => {
    if (!validateNHS(nhsNumber)) {
      return;
    }

    const Relationship = getIn(event, "relationship.value");
    const RelationshipType = getIn(event, "relationshipType.value");
    const PhoneNumber = event.phoneInput;

    const personalConnection = {
      ConnectionType: "personal",
      FirstName: firstName,
      LastName: lastName,
      Relationship,
      RelationshipType,
      ParentalResponsibility: hasParentalResponsibility,
      HouseholdMember: isHouseholdMember,
      PhoneNumber,
      Email: email,
      IsContacted: isContacted,
      StateWhyText: stateWhy,
      NHSNumber: nhsNumber,
      CMSCaseReferenceNumber: cmsCaseReferenceNumber,
      AdditionalComment: additionalComment,
    };

    dispatch(
      coreRecordActions.addConnectionRequest({
        idToken: authState.session?.idToken,
        crId: id,
        data: personalConnection,
        setIsBlocking,
      })
    );
  };

  const relationships = getIn(relationshipData, "relationships", []);
  const relationshipTypes = getIn(relationshipData, "relationshipTypes", []);

  const relationshipOptions = relationships.map((relationship) => ({
    value: relationship,
    label: relationship.value,
  }));

  const relationshipTypeOptions = relationshipTypes.map((relationship) => ({
    value: relationship,
    label: relationship,
  }));

  return (
    <Wrapper>
      <div className="hidden w-full lg:block">
        <CoreRecoridInfoDetails {...coreRecordDetails} />
      </div>
      <div className="flex flex-col items-start w-full mt-12">
        <h1 className="flex items-center tracking-wider pb-4 md:pb-8 w-full text-xl md:text-2xl font-semibold md:mb-3">
          <ArrowBackIcon
            className="w-10 h-10 cursor-pointer mr-10"
            onClick={() => history.back()}
            data-testid="draft-back-button"
          />
          Add a Personal Connection
        </h1>
        <form
          className={`w-full grid grid-cols-1 grid-flow-row gap-4 mb-10 mr-10 2xl:mx-0 `}
          onSubmit={handleSubmit(submitHandler)}
          onChange={() => setIsBlocking(true)}
          data-testid="form"
        >
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
            <h1 className="mb-3 md:col-span-full text-l md:text-xl font-semibold tracking-wide">
              Basic information
            </h1>
            <CustomInput
              inputId="firstName"
              inputName="firstName"
              inputLabel="First Name"
              inputType="text"
              isRequired={true}
              onChange={(value) => setFirstName(value)}
              inputValue={firstName}
            />
            <CustomInput
              inputId="lastName"
              inputName="lastName"
              inputLabel="Last Name"
              inputType="text"
              isRequired={true}
              onChange={(value) => setLastName(value)}
              inputValue={lastName}
            />
            <Controller
              control={control}
              name={"relationship"}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, value, name, ref } }) => (
                <CustomSelect
                  inputRef={ref}
                  inputID="relationship"
                  name={name}
                  label="Relationship*"
                  setSelectValue={onChange}
                  selectValue={value}
                  options={relationshipOptions}
                />
              )}
            />
            <Controller
              control={control}
              name={"relationshipType"}
              render={({ field: { onChange, value, name, ref } }) => (
                <CustomSelect
                  inputRef={ref}
                  inputID="relationshipType"
                  name={name}
                  label="Relationship type"
                  setSelectValue={onChange}
                  selectValue={value}
                  options={relationshipTypeOptions}
                />
              )}
            />
            <div className={"flex flex-col items-start gap-6 md:contents"}>
              <CustomCheckbox
                inputLabel="This person has a parental responsibility"
                inputId="hasParentalResponsibility"
                inputName="hasParentalResponsibility"
                isChecked={hasParentalResponsibility}
                onChange={(event) => {
                  setHasParentalResponsibility(event.target.checked);
                }}
              />
              <CustomCheckbox
                inputLabel="This person is a household member"
                inputId="isHouseholdMember"
                inputName="isHouseholdMember"
                isChecked={isHouseholdMember}
                onChange={(event) => {
                  setIsHouseholdMember(event.target.checked);
                }}
              />
            </div>
          </div>
          <div className={"mb-3"}>
            <h1 className="mb-6 text-l md:text-xl font-semibold tracking-wider">
              Contact details
            </h1>
            <div
              className={
                " grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3"
              }
            >
              <ControlledPhoneInput
                name={"phoneInput"}
                control={control}
                rules={{ required: true }}
                inputLabel={"Phone Number"}
                phoneValue={phoneNumber}
                setPhoneValue={setPhoneNumber}
                inputRequired={true}
                testId="phone-number"
              />
              <CustomInput
                inputId="email"
                inputName="email"
                inputLabel="Email"
                inputType="email"
                isRequired={true}
                onChange={(value) => setEmail(value)}
                inputValue={email}
              />
            </div>
          </div>
          <div className={"md:col-span-full mb-3"}>
            <h1 className="text-l md:text-xl font-semibold tracking-wider mb-6">
              This person should be contacted about my care and support*
            </h1>
            <RadioGroup
              value={isContacted}
              onChange={(value) => setIsContacted(value)}
              className={"flex flex-col gap-y-5 md:flex-row md:items-center"}
            >
              {IsContactedOptions}
            </RadioGroup>
          </div>
          <div className="mb-6">
            <h1 className="mb-6 text-l md:text-xl font-semibold tracking-wider">
              If not state why?
            </h1>
            <CustomRichText
              value={stateWhy}
              setValue={setStateWhy}
              name={"stateWhy"}
              testId={`stateWhy-rich-text`}
            />
          </div>
          <div className="mb-6">
            <h1 className="mb-6 text-l md:text-xl font-semibold tracking-wider">
              Additional details
            </h1>
            <div
              className={"grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3"}
            >
              <CustomInput
                inputId="nhsNumber"
                inputName="nhsNumber"
                inputLabel="NHS Number"
                inputType="number"
                isRequired={true}
                onChange={(value) => {
                  validateNHS(value);
                  setNHSNumber(value);
                }}
                inputValue={nhsNumber}
                inputError={errors.nhsNumber}
                errorComponent={
                  errors.nhsNumber && (
                    <div className={"text-redHighlight text-sm"}>
                      {errors.nhsNumber}
                    </div>
                  )
                }
              />
              <CustomInput
                inputId="cmsCaseReferenceNumber"
                inputName="cmsCaseReferenceNumber"
                inputLabel="CMS Case Reference Number"
                inputType="text"
                onChange={(value) => setCMSCaseReferenceNumber(value)}
                inputValue={cmsCaseReferenceNumber}
              />
            </div>
          </div>
          <div className="mb-6">
            <h1 className="mb-6 text-l md:text-xl font-semibold tracking-wider">
              Additional comment
            </h1>
            <CustomRichText
              value={additionalComment}
              setValue={setAdditionalComment}
              name={"additionalComment"}
              testId={`additionalComment-rich-text`}
            />
          </div>
          <div
            className={
              "flex gap-4 flex-col-reverse md:flex-row md:items-center md:w-2/3"
            }
          >
            <CustomButton
              type="button"
              label={"CANCEL"}
              handleClick={() => history.back()}
              isLight={true}
              testId="modal-cancel-button"
            />
            <CustomButton type="submit" label={"ADD CONNECTION"} />
          </div>
        </form>
      </div>
      <Prompt when={isBlocking} message={""} />
    </Wrapper>
  );
}
