/**
 *
 * CoreRecoridInfoDetails
 *
 */
import { ICoreRecordDetails } from "app/containers/CoreRecords/slice/types";
import React, { memo } from "react";
import { Link } from "react-router-dom";

export const CoreRecoridInfoDetails = memo((props: ICoreRecordDetails) => {
  return (
    <div className="flex items-center justify-center md:justify-between md:flex-row mt-10 md:border-b-2 md:border-primary md:pb-6">
      <Link to={`/core-record/manage/${props?.id}`}>
        <div className="flex flex-col items-center md:flex-row md:mr-6">
          <div
            className={`flex bg-cover bg-center justify-center items-center border-4 border-primary text-white md:mr-6 w-24 h-24 rounded-full mb-4`}
            style={{
              backgroundImage: `url(${props?.SignedImageData?.url})`,
            }}
          ></div>
          <div className="text-center md:text-left flex-1">
            <h1 className="text-2xl md:text-3xl tracking-wider mb-0 font-semibold">
              {props?.FirstName} {props?.FamilyName}
            </h1>
          </div>
        </div>
      </Link>

      <div className="hidden md:flex flex-1 justify-end">
        <table className="hidden lg:block text-sm">
          <tbody>
            <tr>
              <th className="py-2 text-left text-secondaryText font-normal">
                Prefered Name:{" "}
              </th>
              <td className="pl-2 text-left font-bold">
                {props?.PreferredName}
              </td>
            </tr>
            <tr>
              <th className="py-2 text-left text-secondaryText font-normal">
                NHS Number:
              </th>
              <td className="pl-2 text-left font-bold">{props?.Nhs}</td>
            </tr>
            <tr>
              <th className="py-2 text-left text-secondaryText font-normal">
                System ID:
              </th>
              <td className="pl-2 text-left font-bold">{props?.id}</td>
            </tr>
          </tbody>
        </table>
        <table className={"ml-20 text-sm"}>
          <tbody>
            <tr>
              <th className="py-2 text-left text-secondaryText font-normal">
                Birth Date:{" "}
              </th>
              <td className="pl-2 text-left font-bold">{props?.DateOfBirth}</td>
            </tr>
            <tr>
              <th className="py-2 text-left text-secondaryText font-normal">
                Phone Number:{" "}
              </th>
              <td className="pl-2 text-left font-bold">{props?.PhoneNumber}</td>
            </tr>
            <tr>
              <th className="py-2 text-left text-secondaryText font-normal">
                Email:
              </th>
              <td className="pl-2 text-left font-bold">{props?.Email}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
});
