/**
 *
 * FormFactory
 *
 */

import { CustomButton } from "app/components/UI/Forms/Buttons/CustomButton";
import React from "react";

export interface IFormData {
  headTitle: string;
  formHandler(event): any;
  submitButtonLabel: string;
  formSections: IFormSections[];
  profilePicUpload?: any;
}

export interface IFormSections {
  sectionTitle: string;
  sectionInputs: JSX.Element[];
}

export function FormFactory(props: IFormData) {
  const {
    submitButtonLabel,
    formHandler,
    formSections,
    headTitle,
    profilePicUpload,
  } = props;
  return (
    <form
      className="w-full grid grid-cols-1 grid-flow-row gap-4 py-10 px-10 2xl:px-0 "
      onSubmit={formHandler}
    >
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
        {profilePicUpload && (
          <div className="col-span-full pb-6 border-b border-primary">
            {profilePicUpload}
          </div>
        )}
        <h1 className="md:col-span-full text-xl md:text-2xl font-semibold tracking-wider py-6">
          {headTitle}
        </h1>
      </div>
      {formSections.map((fs) => {
        return (
          <div
            key={fs.sectionTitle}
            className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3"
          >
            <h2 className="col-span-full text-lg md:text-xl font-semibold leading-tight md:mt-4 mt-2">
              {fs.sectionTitle}
            </h2>
            {fs.sectionInputs.map((si) => {
              return si;
            })}
          </div>
        );
      })}

      <div className="grid grid-cols-1 gap-4 mt-3 md:grid-cols-2 lg:grid-cols-3">
        <CustomButton type="submit" label={submitButtonLabel} />
      </div>
    </form>
  );
}
