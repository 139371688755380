/**
 *
 * CaseNotes
 *
 */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectCoreRecord } from "../slice/selectors";
import { useCoreRecordSlice } from "../slice";
import { selectAuth } from "../../Auth/slice/selectors";
import { get as getIn } from "lodash";
import { useParams, useHistory, useRouteMatch, Link } from "react-router-dom";
import { Wrapper } from "../../../components/UI/Layout/Wrapper";
import { CoreRecoridInfoDetails } from "../../../components/CoreRecoridInfoDetails/Loadable";
import { CustomButtonSelect } from "../../../components/CustomButtonSelect";
import { ArrowBackIcon, ArrowForwardIcon } from "../../../components/icons";
import { Table } from "../../../components/Table/Loadable";
import dayjs from "dayjs";
import { isMobile } from "react-device-detect";
import MobileRowCard from "../../../components/Table/shared/MobileRowCard";
import { RAG_COLORS_MAP } from "../../../components/RAGRating/constants";
import { CASE_NOTES_TYPES } from "./constants";
import { CASE_NOTES_TYPES_MAP } from "./constants";

interface IRouteParams {
  id: string;
}

export function CaseNotes() {
  const coreRecordState = useSelector(selectCoreRecord);
  const { actions: coreRecordActions } = useCoreRecordSlice();
  const dispatch = useDispatch();
  const authState = useSelector(selectAuth);
  const coreRecordDetails = getIn(coreRecordState, "coreRecordDetails", []);
  const caseNotes = getIn(coreRecordState, "caseNotes", []);
  const { id } = useParams<IRouteParams>();
  let selectedNote = "";
  let columns;
  const history = useHistory();
  const match = useRouteMatch();

  useEffect(() => {
    dispatch(
      coreRecordActions.getCoreRecordRequest({
        partitionKey: id,
        idToken: authState.session?.idToken,
      })
    );
    dispatch(
      coreRecordActions.getCaseNotesRequest({
        id,
        idToken: authState.session?.idToken,
      })
    );
    return () => {
      dispatch(coreRecordActions.resetCoreRecordState());
    };
  }, []);

  const selectedOption = (event) => {
    selectedNote = event.value;
    history.push(`${match.url}/${selectedNote.toLowerCase()}`);
  };
  if (isMobile) {
    columns = React.useMemo(
      () => [
        {
          Header: "",
          accessor: "RagRating",
          disableSortBy: true,
          style: {
            width: "10px",
            padding: 0,
          },
          Cell: ({ row }: any) => {
            const backgroundCol = getIn(row, "original.RagRating");
            return (
              <div
                className={`${RAG_COLORS_MAP[backgroundCol]} rounded-l-lg w-2 h-full`}
              />
            );
          },
        },
        {
          Header: () => null,
          id: "MobileDetails",
          Cell: ({ row }: any) => {
            const caseNoteId = getIn(row, "original.id");
            const caseNoteType = getIn(row, "original.DocumentType");
            const Headline = getIn(row, "original.HeadLine");
            const CreatedDate = getIn(row, "original.CreatedDate");
            return (
              <MobileRowCard
                title={Headline}
                linkTo={`case-notes/${CASE_NOTES_TYPES_MAP[caseNoteType]?.value}/${caseNoteId}`}
              >
                <div className="grid grid-cols-2 gap-1">
                  <span className="text-sm text-gray-400">{caseNoteType}</span>
                  <span className="text-sm text-gray-400">
                    {dayjs(CreatedDate).format("DD.MM.YYYY HH:MM")}
                  </span>
                </div>
              </MobileRowCard>
            );
          },
        },
      ],
      []
    );
  } else {
    columns = React.useMemo(
      () => [
        {
          Header: "",
          accessor: "RagRating",
          disableSortBy: true,
          style: {
            width: "10px",
            padding: 0,
          },
          Cell: ({ row }: any) => {
            const backgroundCol = getIn(row, "original.RagRating");
            return (
              <div
                className={`${RAG_COLORS_MAP[backgroundCol]} w-2 h-full table`}
              />
            );
          },
        },
        {
          Header: "Note Title",
          accessor: "HeadLine",
          Cell: ({ row }: any) => {
            const headLine = getIn(row, "original.HeadLine");
            return (
              <div
                className="text-sm text-black"
                style={{
                  width: "20ch",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
              >
                {headLine}
              </div>
            );
          },
        },
        {
          Header: "Note Type",
          accessor: "DocumentType",
          Cell: ({ row }: any) => {
            const noteType = getIn(row, "original.DocumentType");
            return (
              <div className="rounded-3xl bg-primaryLight table-cell px-2 text-center">
                <span className="text-sm">
                  {CASE_NOTES_TYPES_MAP[noteType]?.title}
                </span>
              </div>
            );
          },
        },
        {
          Header: "Created",
          accessor: "CreatedDate",
          Cell: ({ row }: any) => {
            const createdDate = getIn(row, "original.CreatedDate");
            return (
              <span className="text-black text-sm font-light">
                {dayjs(createdDate).format("DD.MM.YYYY HH:mm")}
              </span>
            );
          },
        },
        {
          Header: "Last Update",
          accessor: "LastUpdated",
          Cell: ({ row }: any) => {
            const lastUpdatedDate = getIn(row, "original.LastUpdated");
            return (
              <span className="text-black text-sm font-light">
                {dayjs(lastUpdatedDate).format("DD.MM.YYYY HH:mm")}
              </span>
            );
          },
        },
        {
          Header: () => null,
          id: "arrowLink",
          disableSortBy: true,
          accessor: "id",
          Cell: ({ row }: any) => {
            const caseNoteId = getIn(row, "original.id");
            const caseNoteType = getIn(row, "original.DocumentType");
            return (
              <Link
                to={`case-notes/${CASE_NOTES_TYPES_MAP[caseNoteType]?.value}/${caseNoteId}`}
              >
                <div data-testid="table-arrow-forward">
                  <ArrowForwardIcon className="w-6 h-6 md:w-7 md:h-7" />
                </div>
              </Link>
            );
          },
        },
      ],
      []
    );
  }

  return (
    coreRecordState && (
      <Wrapper>
        {isMobile ? (
          <div
            className="flex pt-10 items-center gap-4"
            onClick={() => history.goBack()}
          >
            <ArrowBackIcon className="h-8 w-8 text-l" />
            <h1 className="text-l font-semibold">BACK</h1>
          </div>
        ) : (
          <div>
            <CoreRecoridInfoDetails {...coreRecordDetails} />
          </div>
        )}
        <div className="md:mt-5 xl:px-0 sm:flex sm:flex-row-reverse w-full items-center grid grid-rows-2 grid-cols-1 ">
          <span className="md:w-96 mt-5 md:mt-0">
            <CustomButtonSelect
              buttonTitle={"Add a case note"}
              selectOptions={CASE_NOTES_TYPES}
              handleClick={selectedOption}
            />
          </span>
          <span className="w-full sm:flex-1 row-start-1">
            <h1 className="text-2xl font-semibold mt-8 sm:mt-0 w-full">
              My Case Notes
            </h1>
          </span>
        </div>
        <Table columns={columns} data={caseNotes} />
      </Wrapper>
    )
  );
}
