export const ETHNICITIES = [
  "Asian or Asian British - Any other Asian background",
  "Asian or Asian British - Bangladeshi",
  "Asian or Asian British - Indian",
  "Asian or Asian British - Pakistani",
  "Black or Black British - African",
  "Black or Black British - Any other Black background",
  "Black or Black British - Caribbean",
  "Mixed - Any other mixed background",
  "Mixed - White and Asian",
  "Mixed - White and Black African",
  "Mixed - White and Black Caribbean",
  "Other Ethnic Groups - Any other ethnic group",
  "Other Ethnic Groups - Chinese",
  "White - Any other White background",
  "White - British",
  "White - Irish",
  "Not stated",
];

export const GENDERS = [
  "Female",
  "Male",
  "Transgender Female",
  "Transgender Male",
  "Gender Neutral/Nonbinary",
  "Other",
  "Prefer Not to Say",
];

export const ADDRESS_TYPES = [
  "Main Permanent Residence (Home)",
  "Other Permanent Residence",
  "Temporary Residence",
  "Correspondence (Non-Residence)",
  "Main Business Premises",
  "Invoice",
  "Other Business Premises",
  "Safe Haven Address",
];

export const PRONOUNS = ["He/Him", "She/Her", "They/Them"];

export const PHONE_TYPES = ["Home", "Mobile", "Old", "Temp", "Work"];
export const RELIGION = [
  {
    label: "Baptist",
    code_uk_data: "BA",
    value: "BA",
    nhs_show_med_code: "160557009",
  },
  {
    label: "Buddhist",
    code_uk_data: "BU",
    value: "BU",
    nhs_show_med_code: "309687009",
  },
  {
    label: "Church of England",
    code_uk_data: "CE",
    value: "CE",
    nhs_show_med_code: "344151000000101",
  },
  {
    label: "Christian",
    code_uk_data: "CH",
    value: "CH",
    nhs_show_med_code: "160549006",
  },
  {
    label: "Congregational",
    code_uk_data: "CO",
    value: "CO",
    nhs_show_med_code: "427874000",
  },
  {
    label: "Free Church",
    code_uk_data: "FR",
    value: "FR",
    nhs_show_med_code: "344221000000102",
  },
  {
    label: "Greek Orthodox",
    code_uk_data: "GR",
    value: "GR",
    nhs_show_med_code: "429158002",
  },
  {
    label: "Hindu",
    code_uk_data: "HI",
    value: "HI",
    nhs_show_med_code: "160545000",
  },
  {
    label: "Jewish",
    code_uk_data: "JE",
    value: "JE",
    nhs_show_med_code: "160543007",
  },
  {
    label: `Jehovah's Witness`,
    code_uk_data: "JW",
    value: "JW",
    nhs_show_med_code: "80587008",
  },
  {
    label: "Methodist",
    code_uk_data: "ME",
    value: "ME",
    nhs_show_med_code: "160558004",
  },
  {
    label: "Muslim",
    code_uk_data: "MU",
    value: "MU",
    nhs_show_med_code: "309884000",
  },
  {
    label: "Quaker",
    code_uk_data: "QU",
    value: "QU",
    nhs_show_med_code: "309886003",
  },
  {
    label: "Roman Catholic",
    code_uk_data: "RC",
    value: "RC",
    nhs_show_med_code: "160540005",
  },
  {
    label: "Russian Orthodox",
    code_uk_data: "RU",
    value: "RU",
    nhs_show_med_code: "429544005",
  },
  {
    label: "Salvation Army",
    code_uk_data: "SA",
    value: "SA",
    nhs_show_med_code: "160234004",
  },
  {
    label: "Seventh Day Adventist",
    code_uk_data: "SD",
    value: "SD",
    nhs_show_med_code: "428821008",
  },
  {
    label: "Sikh",
    code_uk_data: "SI",
    value: "SI",
    nhs_show_med_code: "366740002",
  },
  {
    label: "Other Faith",
    code_uk_data: "OF",
    value: "OF",
    nhs_show_med_code: null,
  },
  {
    label: "Not Religious",
    code_uk_data: "NR",
    value: "NR",
    nhs_show_med_code: "160552003",
  },
];
export const EMAIL_TYPES = ["Home", "Work"];

export const SPECIALITY_TYPES = [
  {
    CODE: 100,
    value: 100,
    label: "General Surgery Service",
  },
  {
    CODE: 101,
    value: 101,
    label: "Urology Service",
  },
  {
    CODE: 102,
    value: 102,
    label: "Transplant Surgery Service",
  },
  {
    CODE: 103,
    value: 103,
    label: "Breast Surgery Service",
  },
  {
    CODE: 104,
    value: 104,
    label: "Colorectal Surgery Service",
  },
  {
    CODE: 105,
    value: 105,
    label: "Hepatobiliary and Pancreatic Surgery Service",
  },
  {
    CODE: 106,
    value: 106,
    label: "Upper Gastrointestinal Surgery Service",
  },
  {
    CODE: 107,
    value: 107,
    label: "Vascular Surgery Service",
  },
  {
    CODE: 108,
    value: 108,
    label: "Spinal Surgery Service",
  },
  {
    CODE: 109,
    value: 109,
    label: "Bariatric Surgery Service",
  },
  {
    CODE: 110,
    value: 110,
    label: "Trauma and Orthopaedic Service",
  },
  {
    CODE: 111,
    value: 111,
    label: "Orthopaedic Service",
  },
  {
    CODE: 113,
    value: 113,
    label: "Endocrine Surgery Service",
  },
  {
    CODE: 115,
    value: 115,
    label: "Trauma Surgery Service",
  },
  {
    CODE: 120,
    value: 120,
    label: "Ear Nose and Throat Service",
  },
  {
    CODE: 130,
    value: 130,
    label: "Ophthalmology Service",
  },
  {
    CODE: 140,
    value: 140,
    label: "Oral Surgery Service",
  },
  {
    CODE: 141,
    value: 141,
    label: "Restorative Dentistry Service",
  },
  {
    CODE: 143,
    value: 143,
    label: "Orthodontic Service",
  },
  {
    CODE: 144,
    value: 144,
    label: "Maxillofacial Surgery Service",
  },
  {
    CODE: 145,
    value: 145,
    label: "Oral and Maxillofacial Surgery Service",
  },
  {
    CODE: 150,
    value: 150,
    label: "Neurosurgical Service",
  },
  {
    CODE: 160,
    value: 160,
    label: "Plastic Surgery Service",
  },
  {
    CODE: 161,
    value: 161,
    label: "Burns Care Service",
  },
  {
    CODE: 170,
    value: 170,
    label: "Cardiothoracic Surgery Service",
  },
  {
    CODE: 172,
    value: 172,
    label: "Cardiac Surgery Service",
  },
  {
    CODE: 173,
    value: 173,
    label: "Thoracic Surgery Service",
  },
  {
    CODE: 174,
    value: 174,
    label: "Cardiothoracic Transplantation Service",
  },
  {
    CODE: 191,
    value: 191,
    label: "Pain Management Service",
  },
  {
    CODE: 142,
    value: 142,
    label: "Paediatric Dentistry Service",
  },
  {
    CODE: 171,
    value: 171,
    label: "Paediatric Surgery Service",
  },
  {
    CODE: 211,
    value: 211,
    label: "Paediatric Urology Service",
  },
  {
    CODE: 212,
    value: 212,
    label: "Paediatric Transplantation Surgery Service",
  },
  {
    CODE: 213,
    value: 213,
    label: "Paediatric Gastrointestinal Surgery Service",
  },
  {
    CODE: 214,
    value: 214,
    label: "Paediatric Trauma and Orthopaedic Service",
  },
  {
    CODE: 215,
    value: 215,
    label: "Paediatric Ear Nose and Throat Service",
  },
  {
    CODE: 216,
    value: 216,
    label: "Paediatric Ophthalmology Service",
  },
  {
    CODE: 217,
    value: 217,
    label: "Paediatric Oral and Maxillofacial Surgery Service",
  },
  {
    CODE: 218,
    value: 218,
    label: "Paediatric Neurosurgery Service",
  },
  {
    CODE: 219,
    value: 219,
    label: "Paediatric Plastic Surgery Service",
  },
  {
    CODE: 220,
    value: 220,
    label: "Paediatric Burns Care Service",
  },
  {
    CODE: 221,
    value: 221,
    label: "Paediatric Cardiac Surgery Service",
  },
  {
    CODE: 222,
    value: 222,
    label: "Paediatric Thoracic Surgery Service",
  },
  {
    CODE: 223,
    value: 223,
    label: "Paediatric Epilepsy Service",
  },
  {
    CODE: 230,
    value: 230,
    label: "Paediatric Clinical Pharmacology Service",
  },
  {
    CODE: 240,
    value: 240,
    label: "Paediatric Palliative Medicine Service",
  },
  {
    CODE: 241,
    value: 241,
    label: "Paediatric Pain Management Service",
  },
  {
    CODE: 242,
    value: 242,
    label: "Paediatric Intensive Care Service",
  },
  {
    CODE: 250,
    value: 250,
    label: "Paediatric Hepatology Service",
  },
  {
    CODE: 251,
    value: 251,
    label: "Paediatric Gastroenterology Service",
  },
  {
    CODE: 252,
    value: 252,
    label: "Paediatric Endocrinology Service",
  },
  {
    CODE: 253,
    value: 253,
    label: "Paediatric Clinical Haematology Service",
  },
  {
    CODE: 254,
    value: 254,
    label: "Paediatric Audio Vestibular Medicine Service",
  },
  {
    CODE: 255,
    value: 255,
    label: "Paediatric Clinical Immunology and Allergy Service",
  },
  {
    CODE: 256,
    value: 256,
    label: "Paediatric Infectious Diseases Service",
  },
  {
    CODE: 257,
    value: 257,
    label: "Paediatric Dermatology Service",
  },
  {
    CODE: 258,
    value: 258,
    label: "Paediatric Respiratory Medicine Service",
  },
  {
    CODE: 259,
    value: 259,
    label: "Paediatric Nephrology Service",
  },
  {
    CODE: 260,
    value: 260,
    label: "Paediatric Medical Oncology Service",
  },
  {
    CODE: 261,
    value: 261,
    label: "Paediatric Inherited Metabolic Medicine Service",
  },
  {
    CODE: 262,
    value: 262,
    label: "Paediatric Rheumatology Service",
  },
  {
    CODE: 263,
    value: 263,
    label: "Paediatric Diabetes Service",
  },
  {
    CODE: 264,
    value: 264,
    label: "Paediatric Cystic Fibrosis Service",
  },
  {
    CODE: 270,
    value: 270,
    label: "Paediatric Emergency Medicine Service",
  },
  {
    CODE: 280,
    value: 280,
    label: "Paediatric Interventional Radiology Service",
  },
  {
    CODE: 290,
    value: 290,
    label: "Community Paediatric Service",
  },
  {
    CODE: 291,
    value: 291,
    label: "Paediatric Neurodisability Service",
  },
  {
    CODE: 321,
    value: 321,
    label: "Paediatric Cardiology Service",
  },
  {
    CODE: 421,
    value: 421,
    label: "Paediatric Neurology Service",
  },
  {
    CODE: 180,
    value: 180,
    label: "Emergency Medicine Service",
  },
  {
    CODE: 190,
    value: 190,
    label: "Anaesthetic Service",
  },
  {
    CODE: 192,
    value: 192,
    label: "Intensive Care Medicine Service",
  },
  {
    CODE: 200,
    value: 200,
    label: "Aviation and Space Medicine Service",
  },
  {
    CODE: 300,
    value: 300,
    label: "General Internal Medicine Service",
  },
  {
    CODE: 301,
    value: 301,
    label: "Gastroenterology Service",
  },
  {
    CODE: 302,
    value: 302,
    label: "Endocrinology Service",
  },
  {
    CODE: 303,
    value: 303,
    label: "Clinical Haematology Service",
  },
  {
    CODE: 304,
    value: 304,
    label: "Clinical Physiology Service",
  },
  {
    CODE: 305,
    value: 305,
    label: "Clinical Pharmacology Service",
  },
  {
    CODE: 306,
    value: 306,
    label: "Hepatology Service",
  },
  {
    CODE: 307,
    value: 307,
    label: "Diabetes Service",
  },
  {
    CODE: 308,
    value: 308,
    label: "Blood and Marrow Transplantation Service",
  },
  {
    CODE: 309,
    value: 309,
    label: "Haemophilia Service",
  },
  {
    CODE: 310,
    value: 310,
    label: "Audio Vestibular Medicine Service",
  },
  {
    CODE: 311,
    value: 311,
    label: "Clinical Genetics Service",
  },
  {
    CODE: 313,
    value: 313,
    label: "Clinical Immunology and Allergy Service",
  },
  {
    CODE: 314,
    value: 314,
    label: "Rehabilitation Medicine Service",
  },
  {
    CODE: 315,
    value: 315,
    label: "Palliative Medicine Service",
  },
  {
    CODE: 316,
    value: 316,
    label: "Clinical Immunology Service",
  },
  {
    CODE: 317,
    value: 317,
    label: "Allergy Service",
  },
  {
    CODE: 318,
    value: 318,
    label: "Intermediate Care Service",
  },
  {
    CODE: 319,
    value: 319,
    label: "Respite Care Service",
  },
  {
    CODE: 320,
    value: 320,
    label: "Cardiology Service",
  },
  {
    CODE: 322,
    value: 322,
    label: "Clinical Microbiology Service",
  },
  {
    CODE: 323,
    value: 323,
    label: "Spinal Injuries Service",
  },
  {
    CODE: 324,
    value: 324,
    label: "Anticoagulant Service",
  },
  {
    CODE: 325,
    value: 325,
    label: "Sport and Exercise Medicine Service",
  },
  {
    CODE: 326,
    value: 326,
    label: "Acute Internal Medicine Service",
  },
  {
    CODE: 327,
    value: 327,
    label: "Cardiac Rehabilitation Service",
  },
  {
    CODE: 328,
    value: 328,
    label: "Stroke Medicine Service",
  },
  {
    CODE: 329,
    value: 329,
    label: "Transient Ischaemic Attack Service",
  },
  {
    CODE: 330,
    value: 330,
    label: "Dermatology Service",
  },
  {
    CODE: 331,
    value: 331,
    label: "Congenital Heart Disease Service",
  },
  {
    CODE: 333,
    value: 333,
    label: "Rare Disease Service",
  },
  {
    CODE: 335,
    value: 335,
    label: "Inherited Metabolic Medicine Service",
  },
  {
    CODE: 340,
    value: 340,
    label: "Respiratory Medicine Service",
  },
  {
    CODE: 341,
    value: 341,
    label: "Respiratory Physiology Service",
  },
  {
    CODE: 342,
    value: 342,
    label: "Pulmonary Rehabilitation Service",
  },
  {
    CODE: 343,
    value: 343,
    label: "Adult Cystic Fibrosis Service",
  },
  {
    CODE: 344,
    value: 344,
    label: "Complex Specialised Rehabilitation Service",
  },
  {
    CODE: 345,
    value: 345,
    label: "Specialist Rehabilitation Service",
  },
  {
    CODE: 346,
    value: 346,
    label: "Local Specialist Rehabilitation Service",
  },
  {
    CODE: 347,
    value: 347,
    label: "Sleep Medicine Service",
  },
  {
    CODE: 348,
    value: 348,
    label: "Post-COVID-19 Syndrome Service",
  },
  {
    CODE: 350,
    value: 350,
    label: "Infectious Diseases Service",
  },
  {
    CODE: 352,
    value: 352,
    label: "Tropical Medicine Service",
  },
  {
    CODE: 360,
    value: 360,
    label: "Genitourinary Medicine Service",
  },
  {
    CODE: 361,
    value: 361,
    label: "Renal Medicine Service",
  },
  {
    CODE: 370,
    value: 370,
    label: "Medical Oncology Service",
  },
  {
    CODE: 371,
    value: 371,
    label: "Nuclear Medicine Service",
  },
  {
    CODE: 400,
    value: 400,
    label: "Neurology Service",
  },
  {
    CODE: 401,
    value: 401,
    label: "Clinical Neurophysiology Service",
  },
  {
    CODE: 410,
    value: 410,
    label: "Rheumatology Service",
  },
  {
    CODE: 420,
    value: 420,
    label: "Paediatric Service",
  },
  {
    CODE: 422,
    value: 422,
    label: "Neonatal Critical Care Service",
  },
  {
    CODE: 424,
    value: 424,
    label: "Well Baby Service",
  },
  {
    CODE: 430,
    value: 430,
    label: "Elderly Medicine Service",
  },
  {
    CODE: 431,
    value: 431,
    label: "Orthogeriatric Medicine Service",
  },
  {
    CODE: 450,
    value: 450,
    label: "Dental Medicine Service",
  },
  {
    CODE: 451,
    value: 451,
    label: "Special Care Dentistry Service",
  },
  {
    CODE: 460,
    value: 460,
    label: "Medical Ophthalmology Service",
  },
  {
    CODE: 461,
    value: 461,
    label: "Ophthalmic and Vision Science Service",
  },
  {
    CODE: 501,
    value: 501,
    label: "Obstetrics Service",
  },
  {
    CODE: 502,
    value: 502,
    label: "Gynaecology Service",
  },
  {
    CODE: 503,
    value: 503,
    label: "Gynaecological Oncology Service",
  },
  {
    CODE: 504,
    value: 504,
    label: "Community Sexual and Reproductive Health Service",
  },
  {
    CODE: 505,
    value: 505,
    label: "Fetal Medicine Service",
  },
  {
    CODE: 510,
    value: 510,
    label: "Retired but retained for historical purposes",
  },
  {
    CODE: 520,
    value: 520,
    label: "Retired but retained for historical purposes",
  },
  {
    CODE: 560,
    value: 560,
    label: "Midwifery Service",
  },
  {
    CODE: 610,
    value: 610,
    label: "Retired but retained for historical purposes",
  },
  {
    CODE: 620,
    value: 620,
    label: "Retired but retained for historical purposes",
  },
  {
    CODE: 656,
    value: 656,
    label: "Clinical Psychology Service",
  },
  {
    CODE: 700,
    value: 700,
    label: "Learning Disability Service",
  },
  {
    CODE: 710,
    value: 710,
    label: "Adult Mental Health Service",
  },
  {
    CODE: 711,
    value: 711,
    label: "Child and Adolescent Psychiatry Service",
  },
  {
    CODE: 712,
    value: 712,
    label: "Forensic Psychiatry Service",
  },
  {
    CODE: 713,
    value: 713,
    label: "Medical Psychotherapy Service",
  },
  {
    CODE: 715,
    value: 715,
    label: "Old Age Psychiatry Service",
  },
  {
    CODE: 720,
    value: 720,
    label: "Eating Disorders Service",
  },
  {
    CODE: 721,
    value: 721,
    label: "Addiction Service",
  },
  {
    CODE: 722,
    value: 722,
    label: "Liaison Psychiatry Service",
  },
  {
    CODE: 723,
    value: 723,
    label: "Psychiatric Intensive Care Service",
  },
  {
    CODE: 724,
    value: 724,
    label: "Perinatal Mental Health Service",
  },
  {
    CODE: 725,
    value: 725,
    label: "Mental Health Recovery and Rehabilitation Service",
  },
  {
    CODE: 726,
    value: 726,
    label: "Mental Health Dual Diagnosis Service",
  },
  {
    CODE: 727,
    value: 727,
    label: "Dementia Assessment Service",
  },
  {
    CODE: 730,
    value: 730,
    label: "Neuropsychiatry Service",
  },
  {
    CODE: 800,
    value: 800,
    label: "Clinical Oncology Service",
  },
  {
    CODE: 811,
    value: 811,
    label: "Interventional Radiology Service",
  },
  {
    CODE: 812,
    value: 812,
    label: "Diagnostic Imaging Service",
  },
  {
    CODE: 822,
    value: 822,
    label: "Chemical Pathology Service",
  },
  {
    CODE: 832,
    value: 832,
    label: "Retired but retained for historical purposes",
  },
  {
    CODE: 834,
    value: 834,
    label: "Medical Virology Service",
  },
  {
    CODE: 650,
    value: 650,
    label: "Physiotherapy Service",
  },
  {
    CODE: 651,
    value: 651,
    label: "Occupational Therapy Service",
  },
  {
    CODE: 652,
    value: 652,
    label: "Speech and Language Therapy Service",
  },
  {
    CODE: 653,
    value: 653,
    label: "Podiatry Service",
  },
  {
    CODE: 654,
    value: 654,
    label: "Dietetics Service",
  },
  {
    CODE: 655,
    value: 655,
    label: "Orthoptics Service",
  },
  {
    CODE: 657,
    value: 657,
    label: "Prosthetics Service",
  },
  {
    CODE: 658,
    value: 658,
    label: "Orthotics Service",
  },
  {
    CODE: 659,
    value: 659,
    label: "Dramatherapy Service",
  },
  {
    CODE: 660,
    value: 660,
    label: "Art Therapy Service",
  },
  {
    CODE: 661,
    value: 661,
    label: "Music Therapy Service",
  },
  {
    CODE: 662,
    value: 662,
    label: "Optometry Service",
  },
  {
    CODE: 663,
    value: 663,
    label: "Podiatric Surgery Service",
  },
  {
    CODE: 670,
    value: 670,
    label: "Urological Physiology Service",
  },
  {
    CODE: 673,
    value: 673,
    label: "Vascular Physiology Service",
  },
  {
    CODE: 675,
    value: 675,
    label: "Cardiac Physiology Service",
  },
  {
    CODE: 677,
    value: 677,
    label: "Gastrointestinal Physiology Service",
  },
  {
    CODE: 840,
    value: 840,
    label: "Audiology Service",
  },
  {
    CODE: 920,
    value: 920,
    label: "Diabetic Education Service",
  },
  {
    CODE: 990,
    value: 990,
    label: "Retired but retained for historical purposes",
  },
];
